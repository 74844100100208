import { useState, useEffect } from "react";
import { db, auth } from "../firebase/firebase";
import { doc, getDoc } from "firebase/firestore";
import ToastSuccess from "./ToastSuccess";

function SendSMS({ onClose, activeTab }) {
  const [recipientNumber, setRecipientNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [storeOwner, setStoreOwner] = useState("");
  const [salesPerson, setSalesPerson] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminNumber, setAdminNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [showToast, setShowToast] = useState(false);

  const zapierWebhookURL =
    "https://hooks.zapier.com/hooks/catch/15819377/3rngmwm/";

  useEffect(() => {
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    if (userId) {
      // Fetching company name and sales person name
      const configDocRef = doc(db, "users", userId, "custom_config", "config");

      getDoc(configDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();

            setCompanyName(data.companyName || "");
            setSalesPerson(data.salesPersonName || "");
          } else {
            console.log("No such document in custom_config!");
          }
        })
        .catch((error) => {
          console.log("Error getting document from custom_config:", error);
        });

      const userDocRef = doc(db, "users", userId);

      getDoc(userDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data();

            setStoreOwner(userData.name || "");
          } else {
            console.log("No such user document!");
          }
        })
        .catch((error) => {
          console.log("Error getting user document:", error);
        });

      const emailDocRef = doc(db, "users", userId, "details", "summary_email");

      getDoc(emailDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const emailData = docSnap.data();

            setAdminEmail(emailData.sumEmail || "");
          } else {
            console.log("No such document for summary_email!");
          }
        })
        .catch((error) => {
          console.log("Error getting summary_email document:", error);
        });

      const numberDocRef = doc(
        db,
        "users",
        userId,
        "details",
        "contact_number"
      );

      getDoc(numberDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const numberData = docSnap.data();

            setAdminNumber(numberData.number || "");
          } else {
            console.log("No such document for contact_number!");
          }
        })
        .catch((error) => {
          console.log("Error getting contact_number document:", error);
        });
    }
  }, []);

  const handleSubmit = async () => {
    try {
      const userId = auth.currentUser.uid;
      const chatUrl = import.meta.env.VITE_APP_DASHBOARD_URL;

      let ZapierChatUrl;
      if (activeTab === "AI Salesman") {
        ZapierChatUrl = `${chatUrl}/QRchat/${userId}`;
      } else if (activeTab === "Lead Generation") {
        ZapierChatUrl = `${chatUrl}/QRchat2/${userId}`;
      }

      const payload = {
        "Customer Phone Number": recipientNumber,
        "Store Name": companyName,
        "Store Owner Name": storeOwner,
        "Sales Person": salesPerson,
        Email: adminEmail,
        "Zapier Chat URL": ZapierChatUrl,
        "Admin Phone Number": adminNumber,
      };

      await fetch(zapierWebhookURL, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      console.log("Email sent successfully!");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        onClose();
      }, 2000);
    } catch (error) {
      console.error(
        "There was an error sending the SMS:",
        error.message,
        error.stack
      );
    }
  };

  function formatToE164(phone) {
    const cleaned = ("" + phone).replace(/\D/g, "");
    const match = cleaned.match(/^(1?)(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "+1" + match[2] + match[3] + match[4];
    }
    return null;
  }

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
      {showToast && (
        <ToastSuccess message='SMS sent successfully!' className='mt-24 mr-8' />
      )}
      <div className='bg-white dark:bg-zinc-800 p-8 rounded-xl text-zinc-800 dark:text-white'>
        <div className='flex justify-between items-center mb-8'>
          <h2 className='text-xl font-semibold'>Share Chat Via SMS</h2>
          <button
            onClick={onClose}
            className='font-semibold dark:text-zinc-200 text-zinc-800 rounded-xl shadow-xl'>
            <svg
              className='w-7 h-7'
              fill='none'
              stroke='currentColor'
              strokeWidth='1.5'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6 18L18 6M6 6l12 12'></path>
            </svg>
          </button>
        </div>
        <div className=' flex flex-col gap-6'>
          <div>
            <p>Please enter a phone number to share a chat link.</p>
            <input
              type='tel'
              value={recipientNumber}
              onChange={(e) => {
                setRecipientNumber(e.target.value);
                setPhoneNumberError("");
              }}
              onBlur={(e) => {
                const formattedNumber = formatToE164(e.target.value);
                if (formattedNumber) {
                  setRecipientNumber(formattedNumber);
                  setPhoneNumberError("");
                } else {
                  setPhoneNumberError(
                    "Invalid phone number. Please enter a valid US phone number."
                  );
                }
              }}
              placeholder='Enter recipient phone number'
              className='mt-2 p-2 w-full bg-zinc-100 dark:bg-zinc-700 rounded-xl'
            />
            {phoneNumberError && (
              <p className='text-red-500 mt-2'>{phoneNumberError}</p>
            )}
          </div>
        </div>
        <div className='mt-8 flex gap-4'>
          <button
            disabled={!!phoneNumberError || !recipientNumber}
            onClick={handleSubmit}
            className='font-semibold bg-zinc-800 dark:bg-gradient-to-r dark:from-blue-500 dark:to-green-400 text-white rounded-xl px-4 py-2 shadow-xl'>
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

export default SendSMS;
