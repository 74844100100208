import { app, db } from "../firebase/firebase";
import { onSnapshot, doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { create } from "zustand";

const initialState = {
  isSubscribed: false,
  currentSubscriptionId: null,
  currentTier: "",
  showMembershipModal: false,
  showUpgradeModal: false,
  trialInfo: {
    isTrialActive: false,
    TimeLeftOnTrial: 0,
  },
};

const fetchTrialInfo = (set) => {
  const auth = getAuth(app);
  const user = auth.currentUser;

  if (!user) {
    console.error("User is not authenticated");
    return;
  }

  const userDocRef = doc(db, 'users', user.uid);

  const unsubscribe = onSnapshot(userDocRef, (doc) => {
    if (doc.exists()) {
      const userData = doc.data();
      set({
        trialInfo: {
          isTrialActive: userData.isTrialActive || false,
          TimeLeftOnTrial: userData.TimeLeftOnTrial || 0,
        },
        hasUsedTrial: userData.hasUsedTrial || false,
      });
    }
  });

  return unsubscribe;
};

const fetchSubscriptionStatus = (set) => {
  const auth = getAuth(app);
  const user = auth.currentUser;

  if (!user) {
    console.error("User is not authenticated");
    return;
  }

  const userDocRef = doc(db, "users", user.uid);

  const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      const isSubscribed =
        userData.subscriptionPlan && userData.subscriptionPlan !== "No Plan";

      set({
        isSubscribed: isSubscribed,
        currentTier: isSubscribed ? userData.subscriptionPlan : "No Plan",
        currentSubscriptionId: isSubscribed ? userData.currentSubscriptionId : null,
        subscriptionEndsAt: userData.subscriptionEndsAt || null,
        isCanceledButStillActive: userData.isCanceledButStillActive || false,
        showMembershipModal: !isSubscribed,
      });
    } else {
      set({
        isSubscribed: false,
        currentTier: "No Plan",
        currentSubscriptionId: null,
        showMembershipModal: true,
        subscriptionEndsAt: null,
        isCanceledButStillActive: false,
      });
    }
  });

  return unsubscribe;
};

const useSubscriptionStore = create((set) => ({
  ...initialState,

  // Setters
  setIsSubscribed: (value) => set({ isSubscribed: value }),
  setCurrentSubscriptionId: (value) => set({ currentSubscriptionId: value }),
  setCurrentTier: (value) => set({ currentTier: value }),
  setShowMembershipModal: (value) => set({ showMembershipModal: value }),
  showUpgradeModal: initialState.showUpgradeModal,
  setTrialInfo: (trialData) => set({ trialInfo: trialData }),
  hasUsedTrial: initialState.hasUsedTrial,

  // Reset state to initial state
  reset: () => set(initialState),

  // Fetch subscription status
  fetchSubscriptionStatus: () => fetchSubscriptionStatus(set),

  openUpgradeModal: () => set({ showUpgradeModal: true }),
  closeUpgradeModal: () => set({ showUpgradeModal: false }),
  fetchTrialInfo: () => fetchTrialInfo(set),
  setHasUsedTrial: (value) => set({ hasUsedTrial: value }),
}));

export default useSubscriptionStore;
