import meetingsData from "../data/Meetings.json";

const MeetingsGrid = () => {

  const handleScheduleClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <div className='2xl:px-52 2xl:pt-4 p-4 sm:ml-64 h-full'>
        <div className='min-h-screen mt-16 p-6 h-full w-full'>
          <div className='flex justify-start items-start h-full pb-6'>
            <h1 className='text-4xl font-bold text-zinc-700 dark:text-white'>
              Book a Live Session
            </h1>
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 w-full h-full bg-zinc-800 p-4 rounded-xl'>
            {meetingsData.map((meeting) => (
              <div key={meeting.Title} className='bg-zinc-700 p-2 flex flex-col justify-between rounded-lg'>
                <h1 className='text-white font-semibold py-2 text-lg px-2'>
                  {meeting.Title}
                </h1>
                <div className='p-2 bg-zinc-800 rounded-lg'>
                  <img
                    src={meeting.Thumbnail}
                    alt={meeting.Title}
                    className='w-full rounded-md'
                  />
                </div>
                <div>
                  <button
                    className='mt-4 w-full font-semibold bg-gradient-to-r hover:ring hover:ring-white flex flex-row justify-center items-center gap-2 from-zinc-600 to-zinc-600 dark:bg-zinc-800 dark:shadow-2xl py-1 px-3 text-white text-xl rounded-xl'
                    onClick={() => handleScheduleClick(meeting.CalendlyUrl)}>
                    Schedule
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetingsGrid;
