const CarouselSlide5 = () => {
  return (
    <div className='bg-zinc-100 dark:bg-zinc-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
      <div className='flex justify-center items-center mb-8'>
        <div className='gradient-animation rounded-xl dark:shadow-green shadow-2xl'>
          <img
            src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png'
            className='w-12 h-12 bg-zinc-700 rounded-xl m-1 shadow-2xl'
          />
        </div>
      </div>
      <div className='flex flex-row items-center gap-2 lg:gap-0 w-full'>
        <div className='w-1/2 h-full'>
          <img src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720299283/sharing_features_p7yuxi.png' />
        </div>
        <div className='w-1/2 mt-3 sm:ml-4 sm:mt-0 text-left'>
          <div className='flex flex-col'>
            <div className='hidden lg:flex justify-start items-center mb-8'>
              <div className='bg-zinc-200 dark:bg-zinc-700 rounded-full'>
                <svg
                  className='w-12 h-12 p-2 dark:text-white'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                </svg>
              </div>
            </div>

            <h1 className='text-3xl font-semibold leading-6 dark:text-zinc-100'>
              Get Started
            </h1>
            <div className='mt-2'>
              <p className='text-sm lg:text-xl dark:text-zinc-500 lg:mb-4'>
                Navigate to the{" "}
                <span className='dark:text-white font-bold'>
                Membership Page
                </span>
                , using the &apos;Upgrade&apos; Button below, sign up to your desired plan and begin testing MattressAI&apos;s features.
              </p>
            </div>
            <div>
              <p className='text-sm lg:text-xl dark:text-zinc-500'>
                If in doubt about how to use MattressAI, head over to the Help tab to book a {" "}
                <span className='dark:text-white font-bold'>Live Session</span> with our
                founders or consult the MattressAI Tutorials page within the Help Tab.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselSlide5;
