import '../styles/loader.css';

const LoadingComponent = () => {
  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-zinc-200 dark:bg-black bg-opacity-70'>
      <div className='bg-white dark:bg-zinc-800 p-8 rounded-xl shadow-2xl text-zinc-800 dark:text-white'>
        <div className=' flex flex-col gap-6'>
          <div className='flex flex-col justify-center items-center gap-6'>
            <h2 className='text-xl font-semibold'>
              Initialising...
            </h2>
            <span className="loader2"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingComponent;
