import { Link } from "react-router-dom";

const DocsCard = () => {
  return (
    <div className='bg-zinc-100 dark:bg-zinc-800 rounded-xl h-[30vh] w-full relative overflow-hidden'>
      <div className='flex flex-col justify-start items-start p-4'>
        <h1 className='text-xl font-semibold text-zinc-800 dark:text-zinc-300 h-full'>
          Need help?
        </h1>
        <h1 className='text-lg text-zinc-800 dark:text-zinc-300 h-full'>
          Check out our Tutorials Page.
        </h1>
        <Link
          to={"/docs"}
          className='font-semibold bg-gradient-to-r hover:border hover:border-white shadow-2xl from-zinc-600 to-zinc-600 dark:from-blue-500 dark:to-green-400 dark:shadow-green px-3 py-1 my-4 text-white text-xl rounded-xl hover:scale-105  transition-all ease-in-out duration-200'>
          <div className='flex flex-row justify-center items-center gap-2'>
            <h1 className='text-base lg:text-xl'>Tutorials</h1>
            <svg
              className='w-5 h-5'
              fill='none'
              stroke='currentColor'
              strokeWidth='1.5'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'>
              <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
            </svg>
          </div>
        </Link>
      </div>
      <Link
        to={"/docs"}
        className='absolute right-3 lg:right-8 -bottom-20 lg:-bottom-6 bg-white dark:bg-zinc-700 shadow-2xl rounded-t-2xl w-[60%] h-[90%]'>
        <h1 className='p-4 text-2xl dark:text-zinc-300'>Tutorials</h1>
        <div className='p-4'>
          <div role='status' className='space-y-2.5 animate-pulse max-w-lg'>
            <div className='flex items-center w-full space-x-2'>
              <div className='h-2.5 bg-zinc-200 rounded-full dark:bg-zinc-500 w-32'></div>
              <div className='h-2.5 bg-zinc-300 rounded-full dark:bg-zinc-600 w-24'></div>
              <div className='h-2.5 bg-zinc-300 rounded-full dark:bg-zinc-600 w-full'></div>
            </div>
            <div className='flex items-center w-full space-x-2 max-w-[480px]'>
              <div className='h-2.5 bg-zinc-200 rounded-full dark:bg-zinc-500 w-full'></div>
              <div className='h-2.5 bg-zinc-300 rounded-full dark:bg-zinc-600 w-full'></div>
              <div className='h-2.5 bg-zinc-300 rounded-full dark:bg-zinc-600 w-24'></div>
            </div>
            <div className='flex items-center w-full space-x-2 max-w-[400px]'>
              <div className='h-2.5 bg-zinc-300 rounded-full dark:bg-zinc-600 w-full'></div>
              <div className='h-2.5 bg-zinc-200 rounded-full dark:bg-zinc-500 w-80'></div>
              <div className='h-2.5 bg-zinc-300 rounded-full dark:bg-zinc-600 w-full'></div>
            </div>
            <div className='flex items-center w-full space-x-2 max-w-[480px]'>
              <div className='h-2.5 bg-zinc-200 rounded-full dark:bg-zinc-500 w-full'></div>
              <div className='h-2.5 bg-zinc-300 rounded-full dark:bg-zinc-600 w-full'></div>
              <div className='h-2.5 bg-zinc-300 rounded-full dark:bg-zinc-600 w-24'></div>
            </div>
            <div className='flex items-center w-full space-x-2 max-w-[440px]'>
              <div className='h-2.5 bg-zinc-300 rounded-full dark:bg-zinc-600 w-32'></div>
              <div className='h-2.5 bg-zinc-300 rounded-full dark:bg-zinc-600 w-24'></div>
              <div className='h-2.5 bg-zinc-200 rounded-full dark:bg-zinc-500 w-full'></div>
            </div>
            <div className='flex items-center w-full space-x-2 max-w-[360px]'>
              <div className='h-2.5 bg-zinc-300 rounded-full dark:bg-zinc-600 w-full'></div>
              <div className='h-2.5 bg-zinc-200 rounded-full dark:bg-zinc-500 w-80'></div>
              <div className='h-2.5 bg-zinc-300 rounded-full dark:bg-zinc-600 w-full'></div>
            </div>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default DocsCard;
