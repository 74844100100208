import SessionList from "../components/SessionList";

const Sessions = () => {
  return (
    <>
      <div className='2xl:px-52 2xl:pt-4 p-4 sm:ml-64'>
        <div className='min-h-screen mt-20 p-6'>
          <h1 className='text-4xl font-bold flex justify-start items-start text-zinc-800 dark:text-white mb-4'>
            Sessions
          </h1>
          <div className='bg-zinc-800 text-white my-4 p-4 rounded-xl flex flex-row items-center gap-3'>
            <svg
              fill='none'
              stroke='currentColor'
              className='w-5 h-5'
              strokeWidth='1.5'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'></path>
            </svg>
            <p>
              Sessions shows all of your MattressAI Assistants completed chats allowing you to review leads and review conversations. 
            </p>
          </div>
          <div>
            <SessionList />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sessions;
