const CarouselSlide4 = () => {
  return (
    <div className='bg-zinc-100 dark:bg-zinc-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
      <div className='flex justify-center items-center mb-8'>
        <div className='gradient-animation rounded-xl dark:shadow-green shadow-2xl'>
          <img
            src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png'
            className='w-12 h-12 bg-zinc-700 rounded-xl m-1 shadow-2xl'
          />
        </div>
      </div>
      <div className='flex flex-row items-center gap-2 lg:gap-0 w-full lg:py-10'>
        <div className='w-full mt-3 sm:ml-4 sm:mt-0 text-left'>
          <div className='flex flex-col'>
            <div className='hidden lg:flex justify-start items-center mb-8'>
              <div className='bg-zinc-200 dark:bg-zinc-700 rounded-full'>
                <svg
                  className='w-12 h-12 p-2 dark:text-white'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75'
                  />
                </svg>
              </div>
            </div>

            <div className='flex flex-col gap-4 lg:gap-12'>
              <div>
                <h1 className='text-3xl font-semibold leading-6 dark:text-zinc-100'>
                  Configure in 3 Easy Steps
                </h1>
              </div>
              <div className='bg-zinc-200 dark:bg-zinc-700 rounded-xl'>
                <ol className='items-center sm:flex p-4'>
                  <li className='relative mb-6 sm:mb-0'>
                    <div className='flex items-center'>
                      <div className='z-10 flex items-center justify-center p-0.5 bg-zinc-100 rounded-full dark:bg-zinc-500'>
                        <svg
                          className='w-6 h-6 text-green-800 dark:text-white'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth={1.5}
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                          aria-hidden='true'>
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z'
                          />
                        </svg>
                      </div>
                      <div className='hidden sm:flex w-full bg-zinc-400 h-0.5 dark:bg-zinc-600'></div>
                    </div>
                    <div className='mt-3 lg:mt-5 sm:pr-8'>
                      <h3 className='text-lg font-semibold text-zinc-900 dark:text-white'>
                        Pick a Plan
                      </h3>
                      <p className='text-base min-w-md max-w-md font-normal text-zinc-500'>
                        Get started with one of our two plans, pick the one that
                        best suits your business needs.
                      </p>
                    </div>
                  </li>
                  <li className='relative mb-6 sm:mb-0'>
                    <div className='flex items-center'>
                      <div className='z-10 flex items-center justify-center p-0.5 bg-zinc-100 rounded-full dark:bg-zinc-500'>
                        <svg
                          className='w-6 h-6 p-0.5 text-green-800 dark:text-white'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth={1.5}
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                          aria-hidden='true'>
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M21.75 6.75a4.5 4.5 0 01-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 11-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 016.336-4.486l-3.276 3.276a3.004 3.004 0 002.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852z'
                          />
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M4.867 19.125h.008v.008h-.008v-.008z'
                          />
                        </svg>
                      </div>
                      <div className='hidden sm:flex w-full bg-zinc-400 h-0.5 dark:bg-zinc-600'></div>
                    </div>
                    <div className='mt-3 lg:mt-5 sm:pr-8'>
                      <h3 className='text-lg font-semibold text-zinc-900 dark:text-white'>
                        Configure Your Settings
                      </h3>
                      <p className='text-base min-w-md max-w-md font-normal text-zinc-500'>
                        Add your Company Information, Configure your Assistant
                        and its questions.
                      </p>
                    </div>
                  </li>
                  <li className='relative mb-6 sm:mb-0'>
                    <div className='flex items-center'>
                      <div className='z-10 flex items-center justify-center p-0.5 bg-zinc-100 rounded-full dark:bg-zinc-500'>
                        <svg
                          className='w-6 h-6 p-0.5 text-green-800 dark:text-white'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth={1.5}
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                          aria-hidden='true'>
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z'
                          />
                        </svg>
                      </div>
                      <div className='hidden sm:flex w-full bg-zinc-400 h-0.5 dark:bg-zinc-600'></div>
                      <div className='z-10 hidden sm:flex items-center justify-center p-1 bg-zinc-800 rounded-full dark:bg-gradient-to-r dark:from-blue-500 dark:to-green-400 dark:shadow-green'>
                        <svg
                          className='w-8 h-8 p-1 text-white'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth={1.5}
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                          aria-hidden='true'>
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'
                          />
                        </svg>
                      </div>
                    </div>
                    <div className='mt-3 lg:mt-5 sm:pr-8'>
                      <h3 className='text-lg font-semibold text-zinc-900 dark:text-white'>
                        Choose Your Brands
                      </h3>
                      <p className='text-base min-w-md max-w-md font-normal text-zinc-500'>
                        Depending on your Plan, select the brands you want your assitant to be
                        synced with.
                      </p>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselSlide4;
