import { Link } from "react-router-dom";
import axios from "axios";
import { db, auth } from "../firebase/firebase";
import ChartComponent from "../components/UsersGraph";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import MiniSessionList from "../components/MiniSessionList";
import ToastError from "../components/ToastError";
import useSubscriptionStore from "../stores/subscriptionStore";
import useSettingsStore from "../stores/settingsStore";
import TabSwitcher from "../components/TabSwitcher";

const Home = () => {
  const [selectedBrandsArray, setSelectedBrandsArray] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const { currentTier } = useSubscriptionStore();
  const { canLaunchChat } = useSettingsStore();
  const isPremiumUser =
    currentTier === "Premium Plan" ||
    currentTier === "Unlimited Plan" ||
    currentTier === "Launchpad Plan";
  const [activeTab, setActiveTab] = useState("Lead Generation");

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  const handleChatLeadLaunch = async (e) => {
    if (e.target.disabled) {
      return;
    }

    try {
      const userId = auth.currentUser.uid;

      const functionsUrl = import.meta.env.VITE_APP_FUNCTIONS_URL;
      const tokenResponse = await axios.post(`${functionsUrl}/generateJWT`, {
        userId: userId,
      });
      const token = tokenResponse.data.token;

      const serverUrl = import.meta.env.VITE_APP_SERVER_URL;
      const chatUrl = import.meta.env.VITE_APP_CHAT_URL;

      const response = await axios.get(`${serverUrl}/start-chat-lead`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;

      const chatRedirectUrl = `${chatUrl}/lead?token=${data.token}&userID=${userId}`;
      window.location.href = chatRedirectUrl;
    } catch (error) {
      console.error("Error in handleChatLaunch:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    }
  };

  const handleChatSalesLaunch = async (e) => {
    if (selectedBrandsArray.length === 0) {
      setToastMessage("Please select brands before starting the chat.");
      setShowToast(true);
      return;
    }

    if (e.target.disabled) {
      return;
    }

    try {
      const userId = auth.currentUser.uid;

      const functionsUrl = import.meta.env.VITE_APP_FUNCTIONS_URL;
      const tokenResponse = await axios.post(`${functionsUrl}/generateJWT`, {
        userId: userId,
      });
      const token = tokenResponse.data.token;

      const serverUrl = import.meta.env.VITE_APP_SERVER_URL;
      const chatUrl = import.meta.env.VITE_APP_CHAT_URL;

      const response = await axios.get(`${serverUrl}/start-chat-sale`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;

      const chatRedirectUrl = `${chatUrl}/sales?token=${data.token}&userID=${userId}`;
      window.location.href = chatRedirectUrl;
    } catch (error) {
      console.error("Error in handleChatLaunch:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    }
  };

  useEffect(() => {
    if (showToast) {
      const timeout = setTimeout(() => {
        setShowToast(false);
      }, 5000); // hides after 5 seconds

      return () => clearTimeout(timeout);
    }
  }, [showToast]);

  useEffect(() => {
    const fetchUserSelectedBrands = async () => {
      const brandsConfigRef = doc(
        db,
        `/users/${auth.currentUser.uid}/brands_config/brands`
      );
      const userBrandsDoc = await getDoc(brandsConfigRef);

      if (userBrandsDoc.exists()) {
        const userBrands = userBrandsDoc.data().brands;
        setSelectedBrandsArray(userBrands);
      }
    };

    fetchUserSelectedBrands();
  }, []);

  return (
    <>
      <div className='2xl:px-52 2xl:pt-4 p-4 sm:ml-64 sm:h-auto lg:h-screen sm:overflow-auto lg:overflow-hidden text-zinc-700 dark:text-zinc-500'>
        <div className='flex justify-center items-start mt-20 h-full lg:h-[90%]'>
          <div className='grid grid-cols-1 lg:grid-cols-3 lg:grid-rows-2 gap-y-8 lg:gap-4 w-full mx-2 lg:mx-5 h-full'>
            <div className='w-full h-full p-4 rounded-xl flex flex-row gap-2 justify-center items-center bg-zinc-200 dark:bg-zinc-800 shadow-xl'>
              <div className='h-full w-full col-span-full bg-white dark:bg-zinc-700 rounded-lg shadow-xl overflow-hidden'>
                <div className='p-8 lg:p-4 flex flex-col justify-center items-center lg:col-span-1 gap-12 h-full'>
                  <div className='gradient-animation rounded-xl dark:shadow-green shadow-2xl'>
                    {showToast && <ToastError message={toastMessage} />}
                    <img
                      src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png'
                      className='w-32 h-32 md:w-20 md:h-20 lg:w-24 lg:h-24 xl:w-32 xl:h-32 bg-zinc-700 rounded-xl m-2 shadow-2xl'
                    />
                  </div>
                  <div className='w-full flex flex-col gap-4 bg-zinc-200 dark:bg-zinc-600 p-2 rounded-2xl'>
                    <button
                      onClick={handleChatLeadLaunch}
                      disabled={!canLaunchChat}
                      className={`py-3 font-semibold w-full bg-zinc-600 dark:bg-zinc-800 hover:border hover:border-white shadow-2xl px-3 text-white text-xl rounded-xl transition-all ease-in-out duration-200 ${
                        !canLaunchChat
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:scale-105"
                      }`}>
                      <div className='flex flex-row justify-center items-center gap-2 shadow-2xl'>
                        <svg
                          className='w-5 h-5'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                          aria-hidden='true'>
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z'
                          />
                        </svg>
                        <h1 className='text-base'>Lead Lite</h1>
                      </div>
                    </button>
                    {(currentTier === "Premium Plan" ||
                      currentTier === "Unlimited Plan" ||
                      currentTier === "Launchpad Plan") && (
                      <button
                        onClick={handleChatSalesLaunch}
                        disabled={!canLaunchChat}
                        className={`py-3 font-semibold w-full bg-zinc-600 dark:bg-zinc-800 hover:border hover:border-white shadow-2xl px-3 text-white text-xl rounded-xl transition-all ease-in-out duration-200 ${
                          !canLaunchChat
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:scale-105"
                        }`}>
                        <div className='flex flex-row justify-center items-center gap-2 shadow-2xl'>
                          <svg
                            className='w-5 h-5'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            viewBox='0 0 24 24'
                            xmlns='http://www.w3.org/2000/svg'
                            aria-hidden='true'>
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z'></path>
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z'></path>
                          </svg>
                          <h1 className='text-base'>Lead Plus</h1>
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full h-full grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-4 p-4 rounded-xl md:col-span-2 bg-zinc-200 dark:bg-zinc-800 shadow-xl'>
              <div className='lg:p-2 p-4 rounded-lg shadow-xl bg-white dark:bg-zinc-700 overflow-hidden h-full'>
                <TabSwitcher
                  activeTab={activeTab}
                  onTabChange={handleTabChange}
                />
              </div>
              <div className='col-span-2 p-4 shadow-xl bg-white dark:bg-zinc-700 w-full h-full rounded-lg'>
                <div className='h-full w-full flex flex-col justify-between items-center'>
                  <div className="w-full flex">
                    <h1 className='text-xl text-zinc-800 dark:text-zinc-200'>
                      Assistant Chat Traffic
                    </h1>
                  </div>
                  <div className='h-full w-full'>
                    <ChartComponent />
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full h-full hidden rounded-xl lg:flex justify-center items-center overflow-hidden bg-zinc-200 dark:bg-zinc-800 md:col-span-2 shadow-xl'>
              <div className='w-full h-full p-5'>
                <div className='h-full w-full'>
                  <div className='p-4 rounded-lg h-full overflow-hidden shadow-xl bg-white dark:bg-zinc-700 col-span-2 lg:col-span-2 flex flex-col justify-center items-center gap-12'>
                    <MiniSessionList />
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full h-full rounded-xl md:col-span-1 bg-zinc-200 dark:bg-zinc-800 p-4 shadow-xl flex flex-col justify-center items-center gap-4'>
              <div className='relative flex flex-col gap-4 justify-center items-center p-4 rounded-lg shadow-xl bg-white dark:bg-zinc-700 w-full h-full'>
                <h1 className='dark:text-white text-zinc-900 text-xl w-full text-left pl-2'>
                  Current Brands
                </h1>
                <div className='flex flex-col gap-2 justify-center items-center h-full w-full p-2 bg-zinc-100 dark:bg-zinc-800 rounded-xl'>
                  {selectedBrandsArray.length ? (
                    <>
                      <div className='grid grid-cols-2 justify-center items-center'>
                        {selectedBrandsArray.map((brand, index) => (
                          <span
                            key={index}
                            className='m-1 px-2 text-sm lg:text-lg dark:text-white text-zinc-900 bg-zinc-200 dark:bg-zinc-700 p-1 rounded-md flex justify-center items-center'>
                            {brand}
                            {index !== selectedBrandsArray.length - 1 ? "" : ""}
                          </span>
                        ))}
                      </div>
                    </>
                  ) : (
                    <h1 className='dark:text-white text-zinc-900 w-full text-center'>
                      No Brands Chosen, Please Sync.
                    </h1>
                  )}
                  {!isPremiumUser && (
                    <div className='absolute rounded-lg inset-0 backdrop-blur-xl hover:backdrop-blur-md transition-all ease-in-out duration-200 z-10'>
                      <div className='h-full w-full flex flex-col gap-4 justify-center items-center dark:text-white'>
                        <svg
                          className='w-14 h-14'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                          aria-hidden='true'>
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z'></path>
                        </svg>
                        <h1>Premium Plan Feature</h1>
                        <button className='font-semibold bg-gradient-to-r hover:border hover:border-white from-zinc-600 to-zinc-600 shadow-2xl dark:from-blue-500 dark:to-green-400 dark:shadow-green px-3 py-1 text-white text-xl rounded-xl transition-all ease-in-out duration-200'>
                          Upgrade
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <Link
                  to={"/brands"}
                  className='font-semibold bg-gradient-to-r hover:border hover:border-white from-zinc-600 to-zinc-600 dark:from-blue-500 dark:to-green-400 dark:shadow-green px-3 py-1 my-4 text-white text-xl rounded-xl hover:scale-105  transition-all ease-in-out duration-200'>
                  <div className='flex flex-row justify-center items-center gap-2'>
                    <h1 className='text-base lg:text-xl'>Merchant Brands</h1>
                    <svg
                      className='w-5 h-5'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m6.75 12-3-3m0 0-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
