import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

const HiddenLeadChatLaunch = () => {
  const { userId } = useParams();

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChatLaunch();
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleChatLaunch = async () => {
    try {
      // Request JWT from the Firebase Function
      const functionsUrl = import.meta.env.VITE_APP_FUNCTIONS_URL;
      const tokenResponse = await axios.post(`${functionsUrl}/generateJWT`, {
        userId: userId,
      });
      const token = tokenResponse.data.token;

      const serverUrl = import.meta.env.VITE_APP_SERVER_URL;
      const chatUrl = import.meta.env.VITE_APP_CHAT_URL;

      // Start the chat using the JWT in the Authorization header
      const response = await axios.get(`${serverUrl}/start-chat-lead`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;

      const chatRedirectUrl = `${chatUrl}/lead?token=${data.token}&userID=${userId}`;
      window.location.href = chatRedirectUrl;
    } catch (error) {
      console.error("Error in handleChatLaunch:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    }
  };

  return (
    <div className='h-screen'>
      <div className='h-[100%] relative bg-[url("https://res.cloudinary.com/dkbn21xdu/image/upload/v1720287826/blob-scene-haikei_hsifce_jjyoyu.svg")] bg-cover bg-right-bottom bg-no-repeat flex justify-center items-center w-full'>
        <div className='absolute w-screen bg-zinc-900/20 h-screen z-0' />
        <div className='bg-zinc-800/50 h-min p-6 shadow-2xl rounded-xl border border-zinc-700 min-w-[70vw] mx-6 md:mx-0 md:min-w-[25vw] flex flex-col justify-center items-center gap-4 text-zinc-700 dark:text-white z-50'>
          <div className='flex flex-col justify-between items-center'>
            <div className='gradient-animation rounded-xl shadow-green'>
              <img
                src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png'
                className='w-12 h-12 lg:w-14 lg:h-14 bg-zinc-700 rounded-lg m-1'
              />
            </div>
            <div className='w-1/2 mt-6'>
              <div className='mt-4 bg-zinc-800 rounded-xl flex flex-col gap-2 items-center justify-center'>
                <span className='loader3 pb-1 w-full'></span>
              </div>
            </div>
            <div className='p-4 flex flex-col gap-4 justify-between items-center'>
              <p className='text-xs max-w-xs px-8 text-center font-thin text-zinc-600'>
                You will be redirected to start a conversation with me, if the
                redirect fails, please click{" "}
                <a
                  onClick={handleChatLaunch}
                  className='font-semibold hover:cursor-pointer hover:text-zinc-200'>
                  here.
                </a>
              </p>
            </div>
          </div>
          <p className='text-xs font-thin text-zinc-500/90'>
            Powered By <span className='font-bold'>MattressAI</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HiddenLeadChatLaunch;
