import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

export default function TutorialModal({ tutorial, onClose, isOpen }) {
  const cancelButtonRef = useRef(null);

  const convertToEmbedUrl = (url) => {
    const videoId = url.split("v=")[1];
    return `https://www.youtube.com/embed/${videoId}`;
  };

  return (
    <Transition.Root as={Fragment} show={isOpen}>
      <Dialog
        as='div'
        open={isOpen}
        onClose={onClose}
        className='relative z-50'
        initialFocus={cancelButtonRef}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-zinc-900 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl'>
                <div className='bg-zinc-100 dark:bg-zinc-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
                  <div className='mt-3 text-center sm:mt-0 sm:text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-2xl font-semibold leading-6 dark:text-zinc-100 text-white pt-4 pb-2 px-2'>
                      {tutorial.Title}
                    </Dialog.Title>

                    <div className='my-5 p-2 bg-zinc-600 rounded-lg'>
                      <iframe
                        src={convertToEmbedUrl(tutorial.VideoUrl)}
                        title={tutorial.Title}
                        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                        className='w-full h-full rounded-md'
                      />
                    </div>
                    <div className='mt-2'>
                      <p className='text-base lg:text-lg bg-zinc-700 p-4 rounded-xl dark:text-zinc-300'>
                        {tutorial.Description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='bg-zinc-300 dark:bg-zinc-700 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                  <button
                    className='inline-flex w-full justify-center rounded-md bg-zinc-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-900 sm:mt-0 sm:w-auto sm:ml-3'
                    onClick={onClose}>
                    Close
                  </button>
                  <Link
                    to={tutorial.RedirectUrl}
                    className='inline-flex w-full justify-center rounded-md bg-gradient-to-r from-blue-500 bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm mt-3 sm:mt-0 hover:bg-blue-500 sm:w-auto'
                    onClick={onClose}>
                    Take Me There
                  </Link>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
