import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkAuth, refreshAuthToken } from "../firebase/firebaseAuth";
import { sendEmailVerification } from "firebase/auth";
import { db } from "../firebase/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import zxcvbn from "zxcvbn";

import {
  setPersistence,
  browserSessionPersistence,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { signInWithPopup } from "firebase/auth";
import { auth as firebaseAuth, provider } from "../firebase/firebase";

const VerificationEmailSection = ({
  cooldown,
  resendVerificationEmail,
  verificationEmailSent,
}) => {
  return (
    <div className='verification-section mt-6'>
      <div className='bg-zinc-700 dark:text-white p-4 rounded-xl flex flex-col justify-center items-center gap-4'>
        <svg
          className='w-12 h-12'
          fill='none'
          stroke='currentColor'
          strokeWidth={1.5}
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'>
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z'
          />
        </svg>
        <div className='flex flex-col justify-center items-center text-center'>
          <p>Please Verify your email in your Inbox</p>
          <p className='text-xs text-zinc-400'>
            Once Verified, refresh this page to Login
          </p>
        </div>
      </div>
      <div className='flex flex-col gap-4 justify-center items-center'>
        <button
          className={`resend-button ${
            cooldown > 0
              ? "bg-zinc-600 cursor-not-allowed"
              : "bg-gradient-to-r from-blue-500 to-green-400 cursor-pointer"
          } text-white font-semibold rounded-lg px-4 py-3 mt-6 w-full`}
          disabled={cooldown > 0}
          onClick={resendVerificationEmail}>
          {verificationEmailSent
            ? "Resend Verification Email"
            : "Send Verification Email"}
        </button>
        {cooldown > 0 && (
          <p className='dark:text-zinc-500'>
            Resend will be available in {cooldown} seconds.
          </p>
        )}
        {cooldown === 0 && verificationEmailSent && (
          <p className='dark:text-white'>You may now try again.</p>
        )}
      </div>
    </div>
  );
};

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [unverifiedEmail, setUnverifiedEmail] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordsMatch, setPasswordsMatch] = useState(null);
  const [showResetSection, setShowResetSection] = useState(false);

  const navigate = useNavigate();

  const [isSigningUp, setIsSigningUp] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [cooldown, setCooldown] = useState(0);

  useEffect(() => {
    const unsubscribe = checkAuth((isAuthenticated, user) => {
      if (isAuthenticated) {
        // User is logged in
        if (user.emailVerified) {
          if (sessionStorage.getItem("justVerified")) {
            // If the email has just been verified, log the user out
            signOut(firebaseAuth);
            sessionStorage.removeItem("justVerified"); // Remove the flag from sessionStorage
          } else {
            // Otherwise, navigate to the home page
            navigate("/");
          }
        } else {
          signOut(firebaseAuth);
        }
      } else {
        // User is logged out
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    let cooldownTimer;
    if (cooldown > 0) {
      // Update the cooldown state every second to implement a countdown
      cooldownTimer = setInterval(() => {
        setCooldown((prevCooldown) => prevCooldown - 1);
      }, 1000);
    }
    return () => clearInterval(cooldownTimer); // Clear the interval when the component is unmounted or when cooldown reaches 0
  }, [cooldown]);

  const sendVerificationEmail = () => {
    setCooldown(90);
    const user = firebaseAuth.currentUser;
    if (user && !user.emailVerified) {
      sendEmailVerification(user)
        .then(() => {
          console.log("Verification email sent.");
          setEmailSent(true);
          setVerificationEmailSent(true);
          refreshAuthToken();
          sessionStorage.setItem("justVerified", "true");
        })
        .catch((error) => {
          console.error("Error sending verification email:", error);
          setCooldown(0);
        });
    }
  };

  const resendVerificationEmail = () => {
    setCooldown(90);
    sendVerificationEmail();
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordsMatch(newPassword === confirmPassword);
    if (newPassword === "") {
      setPasswordStrength(0);
    } else {
      setPasswordStrength(zxcvbn(newPassword).score);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setPasswordsMatch(password === newConfirmPassword);
  };

  const toggleResetSection = () => {
    setShowResetSection(!showResetSection);
  };

  const updateDatabase = async () => {
    const user = firebaseAuth.currentUser;
    if (user) {
      const userRef = doc(db, "users", user.uid);
      try {
        await setDoc(
          userRef,
          {
            hasSignedUp: true,
            hasStartedASession: false,
            hasSignedUpForPlan: false,
            hasValidSettingsConfig: false,
          },
          { merge: true }
        );
      } catch (error) {
        console.error("Error updating database:", error);
      }
    }
  };

  const sendZapierWebhook = async (emailParam) => {
    const emailToSend = emailParam || email;
    const zapierWebhookUrl =
      "https://hooks.zapier.com/hooks/catch/15819377/3z2r9pi/";
    try {
      await fetch(zapierWebhookUrl, {
        method: "POST",
        mode: "no-cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: emailToSend }),
      });
    } catch (error) {
      console.error("Error sending Zapier webhook:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await setPersistence(firebaseAuth, browserSessionPersistence);
      if (isSigningUp) {
        if (password !== confirmPassword) {
          throw new Error("Passwords do not match");
        }
        await createUserWithEmailAndPassword(firebaseAuth, email, password);
        setEmailSent(true);
        sendVerificationEmail();
      } else {
        const userCredential = await signInWithEmailAndPassword(
          firebaseAuth,
          email,
          password
        );
        const user = userCredential.user;
        if (!user.emailVerified) {
          setUnverifiedEmail(true); // Set unverifiedEmail state to true
          setError("Email not Verified"); // Set error state to "Email not Verified"
        }
      }
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;

      // Check for specific error codes and set custom messages
      if (
        errorCode === "auth/invalid-email" ||
        errorCode === "auth/user-not-found" ||
        errorCode === "auth/wrong-password"
      ) {
        setError("Invalid Username or Password");
      } else {
        setError(errorMessage); // For other errors, use the default error message
      }

      console.error("Error signing in", errorMessage, "Error code:", errorCode);
    }
  };

  useEffect(() => {
    if (verificationEmailSent) {
      // The verification email has been sent and the countdown has started,
      updateDatabase();
      sendZapierWebhook();
    }
  }, [verificationEmailSent]);

  const handleGoogleSignIn = () => {
    signInWithPopup(firebaseAuth, provider)
      .then(async (result) => {
        const user = result.user;

        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);

        if (!docSnap.exists()) {
          await updateDatabase();
          await sendZapierWebhook(user.email);
        } else {
          // User exists, this is just a login, no need to send email
        }
      })
      .catch((error) => {
        console.error(error);
        setError(error.message);
      });
  };

  useEffect(() => {
    if (error) {
      const errorTimeout = setTimeout(() => {
        setError(null);
      }, 5000);

      return () => clearTimeout(errorTimeout);
    }
  }, [error]);

  useEffect(() => {
    setEmail("");
    setPassword("");
    setError(null);
    setEmailSent(false);
    setVerificationEmailSent(false);
    setUnverifiedEmail(false);
    setConfirmPassword("");
    setCooldown(0);
  }, [isSigningUp, showResetSection]);

  const handlePasswordReset = async () => {
    if (email) {
      try {
        await sendPasswordResetEmail(firebaseAuth, email);
        setEmailSent(true); // Set a flag to show a message that the email was sent
      } catch (error) {
        console.error("Error sending password reset email", error);
        setError(error.message); // Update the error state to display the message
      }
    } else {
      // No email address is set in the state, handle this case as you see fit
      setError("Please enter your email address.");
    }
  };

  return (
    <>
      <div className="flex flex-col bg-cover h-screen overflow-hidden bg-center bg-[url('https://res.cloudinary.com/dkbn21xdu/image/upload/v1720287826/blob-scene-haikei_hsifce_jjyoyu.svg')] transition-all ease-in-out duration-300">
        <div className='flex justify-start items-start py-4 pl-6'>
          <a
            href='https://themattressai.com'
            className='text-white hover:text-zinc-200 active:text-zinc-400'>
            ← Take me Back
          </a>
        </div>
        <section className='flex flex-col md:flex-row items-center'>
          <div className='w-full h-full flex justify-center items-center p-4'>
            <div className='py-6 bg-zinc-500/20 border border-zinc-600 backdrop-blur-lg shadow-2xl rounded-xl w-full md:w-1/2 xl:w-1/3 mx-4 h-full flex items-center justify-center overflow-hidden'>
              <div className='flex flex-col justify-between w-full'>
                <div className='w-full h-full px-6 lg:px-16 xl:px-6'>
                  <div className='flex justify-center items-center'>
                    <div className='gradient-animation rounded-xl dark:shadow-green'>
                      <img
                        src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png'
                        className='w-14 h-14 md:w-16 md:h-16 lg:w-20 lg:h-20 bg-zinc-700 rounded-xl m-1'
                      />
                    </div>
                  </div>
                  <h1 className='text-xl md:text-4xl text-white font-bold leading-tight mt-4'>
                    {isSigningUp ? "Sign Up" : "Login"}
                  </h1>
                  {error && (
                    <div className='bg-red-500 flex flex-row gap-2 text-white p-2 rounded-lg mt-4'>
                      <svg
                        className='w-6 h-6 text-white'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'></path>
                      </svg>
                      <p>{error}</p>
                    </div>
                  )}
                  {!showResetSection ? (
                    // The logic here decides whether to show the verification email section or the sign-up/log-in form
                    unverifiedEmail ? (
                      <VerificationEmailSection
                        cooldown={cooldown}
                        resendVerificationEmail={resendVerificationEmail}
                        verificationEmailSent={verificationEmailSent}
                      />
                    ) : !emailSent ? (
                      <form className='mt-6' onSubmit={handleSubmit}>
                        <div>
                          <label className='block text-zinc-400'>
                            Email Address
                          </label>
                          <input
                            type='email'
                            placeholder='Enter Email Address'
                            className='w-full px-4 py-3 text-zinc-300 rounded-lg bg-zinc-800 mt-2 border focus:border-green-400 focus:bg-zinc-800 focus:outline-none'
                            autoFocus
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className='mt-4'>
                          <div className=' flex flex-row justify-between items-center'>
                            <label className='block text-zinc-400'>
                              Password
                            </label>
                            {isSigningUp && password && (
                              <div className='text-xs text-zinc-500'>
                                Password Strength:{" "}
                                <span
                                  className={
                                    [
                                      "text-red-500",
                                      "text-orange-400",
                                      "text-yellow-400",
                                      "text-blue-400",
                                      "text-green-400",
                                    ][passwordStrength]
                                  }>
                                  {
                                    [
                                      "Very Weak",
                                      "Weak",
                                      "Fair",
                                      "Strong",
                                      "Very Strong",
                                    ][passwordStrength]
                                  }
                                </span>
                              </div>
                            )}
                          </div>
                          <input
                            type='password'
                            placeholder='Enter Password'
                            minLength='6'
                            className='w-full px-4 py-3 text-zinc-300 rounded-lg bg-zinc-800 mt-2 border focus:border-green-400 focus:bg-zinc-800 focus:outline-none'
                            required
                            onChange={handlePasswordChange}
                          />
                        </div>
                        {isSigningUp && (
                          <div className='mt-4'>
                            <div className='flex justify-between items-center'>
                              <label className='block text-zinc-400'>
                                Confirm Password
                              </label>
                              <div>
                                {passwordsMatch !== null && (
                                  <span
                                    className={
                                      passwordsMatch
                                        ? "text-green-400 text-xs"
                                        : "text-red-500 text-xs"
                                    }>
                                    {passwordsMatch
                                      ? "Passwords Match"
                                      : "Passwords don't match"}
                                  </span>
                                )}
                              </div>
                            </div>
                            <input
                              type='password'
                              placeholder='Confirm Password'
                              minLength='6'
                              className='w-full px-4 py-3 text-zinc-300 rounded-lg bg-zinc-800 mt-2 border focus:border-green-400 focus:bg-zinc-800 focus:outline-none'
                              required
                              onChange={handleConfirmPasswordChange}
                            />
                          </div>
                        )}
                        <button
                          type='submit'
                          className='w-full block bg-green-400 hover:bg-green-500 active:bg-green-600 text-white font-semibold rounded-lg px-4 py-3 mt-6'>
                          {isSigningUp ? "Sign Up" : "Log In"}
                        </button>
                      </form>
                    ) : (
                      isSigningUp && (
                        <VerificationEmailSection
                          cooldown={cooldown}
                          resendVerificationEmail={resendVerificationEmail}
                          verificationEmailSent={verificationEmailSent}
                        />
                      )
                    )
                  ) : (
                    <div className='password-reset-section mt-6'>
                      <div className='dark:text-white rounded-xl flex flex-col justify-center items-center gap-4'>
                        {emailSent ? (
                          <>
                            <svg
                              className='w-12 h-12'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth={1.5}
                              viewBox='0 0 24 24'
                              xmlns='http://www.w3.org/2000/svg'
                              aria-hidden='true'>
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
                              />
                            </svg>
                            <div className='flex flex-col justify-center items-center text-center'>
                              <p>Password Reset Email Sent</p>
                              <p className='text-xs text-zinc-400'>
                                Please check your email to reset your password.
                              </p>
                            </div>
                          </>
                        ) : (
                          <div className='flex flex-col w-full'>
                            <label className='block text-zinc-400'>
                              Reset Your Password
                            </label>
                            <input
                              type='email'
                              placeholder='Enter Account Email Address'
                              className='w-full px-4 py-3 text-zinc-300 rounded-lg bg-zinc-800 mt-2 border focus:border-green-400 focus:bg-zinc-800 focus:outline-none'
                              required
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <button
                              className='bg-gradient-to-r from-blue-500 to-green-400 text-white font-semibold rounded-lg px-4 py-3 mt-6 w-full'
                              onClick={handlePasswordReset}>
                              Send Reset Email
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <hr className='my-6 border-zinc-600 w-full' />
                  <button
                    type='button'
                    className='w-full block bg-zinc-700 hover:bg-zinc-900 text-white font-semibold rounded-lg px-4 py-3'>
                    <div
                      className='flex items-center justify-center'
                      onClick={handleGoogleSignIn}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='w-6 h-6'
                        viewBox='0 0 48 48'>
                        <defs>
                          <path
                            id='a'
                            d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z'
                          />
                        </defs>
                        <clipPath id='b'>
                          <use xlinkHref='#a' overflow='visible' />
                        </clipPath>
                        <path
                          clipPath='url(#b)'
                          fill='#FBBC05'
                          d='M0 37V11l17 13z'
                        />
                        <path
                          clipPath='url(#b)'
                          fill='#EA4335'
                          d='M0 11l17 13 7-6.1L48 14V0H0z'
                        />
                        <path
                          clipPath='url(#b)'
                          fill='#34A853'
                          d='M0 37l30-23 7.9 1L48 0v48H0z'
                        />
                        <path
                          clipPath='url(#b)'
                          fill='#4285F4'
                          d='M48 48L17 24l-4-3 35-10z'
                        />
                      </svg>
                      <span className='ml-4'>
                        {isSigningUp
                          ? "Sign Up with Google"
                          : "Log in with Google"}
                      </span>
                    </div>
                  </button>
                  <div className='text-center flex flex-col justify-center items-center'>
                    <p className='mt-8 text-center text-zinc-500 p-2'>
                      {isSigningUp
                        ? "Already have an account?"
                        : "Need An Account?"}
                      <a
                        href='#'
                        className='text-zinc-400 hover:text-green-400 active:text-green-500 font-semibold'
                        onClick={(event) => {
                          event.preventDefault();
                          setIsSigningUp(!isSigningUp);
                        }}>
                        {isSigningUp ? "   Log In" : "   Sign Up"}
                      </a>
                    </p>
                    <p className='text-center text-zinc-500 p-2'>
                      {showResetSection ? "  " : "  Forgot Password?"}
                      <a
                        href='#'
                        className='text-zinc-400 hover:text-green-400 active:text-green-500 font-semibold'
                        onClick={(event) => {
                          event.preventDefault();
                          toggleResetSection();
                        }}>
                        {" "}
                        {showResetSection ? "   Return to Login" : "   Reset"}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
