import { useState, useEffect } from "react";
import axios from "axios";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import useSubscriptionStore from "../stores/subscriptionStore";
import useSettingsStore from "../stores/settingsStore";
import { updateEmail, updatePassword } from "firebase/auth";
import UpgradeModal from "../components/UpgradeModal";
import cloudFunctionsConfig from "../utils/cloudFunctionsConfig";

import {
  doc,
  setDoc,
  getDoc,
  onSnapshot,
  getFirestore,
} from "firebase/firestore";

import { storage, db } from "../firebase/firebase";
import { getAuth } from "firebase/auth";

import { Link } from "react-router-dom";
import Notifier from "../components/Notifier";
import ToastSuccess from "../components/ToastSuccess";
import ToastError from "../components/ToastError";

const Settings = () => {
  const {
    name,
    setName,
    companyName,
    setCompanyName,
    salesPersonName,
    setSalesPersonName,
    companyDescription,
    setCompanyDescription,
    companyValues,
    setCompanyValues,
    assistantGreeting,
    setAssistantGreeting,
    number,
    setNumber,
    sumEmail,
    setSumEmail,
    avatarUrl,
    setAvatarUrl,
    selectedQuestionsLead,
    selectedQuestionsSales,
    setSelectedQuestionsLead,
    setSelectedQuestionsSales,
    redirectUrl,
    setRedirectUrl,
  } = useSettingsStore();

  const [activeTab, setActiveTab] = useState("Lead Generation");
  const [activeSettingTab, setActiveSettingTab] = useState("Account");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [questionErrorToast, setQuestionErrorToast] = useState(false);
  const { currentTier, openUpgradeModal } = useSubscriptionStore();
  const auth = getAuth();
  const [email, setEmail] = useState(
    auth.currentUser ? auth.currentUser.email : ""
  );
  const [questions, setQuestions] = useState([]);
  const [file, setFile] = useState(null);
  const [alignment, setAlignment] = useState("Right Aligned");
  const uid = auth.currentUser.uid;

  const handleSettingTabClick = (tabName) => {
    setActiveSettingTab(tabName);
  };

  const isAllowedTier =
    currentTier === "Premium Plan" ||
    currentTier === "Unlimited Plan" ||
    currentTier === "Launchpad Plan";

  const updateEmailAndPassword = async () => {
    try {
      if (auth.currentUser) {
        if (email !== auth.currentUser.email) {
          await updateEmail(auth.currentUser, email);
          console.log("Email updated successfully!");
        }

        if (password) {
          await updatePassword(auth.currentUser, password);
          console.log("Password updated successfully!");
        }
      }
    } catch (error) {
      console.error("Error updating email or password:", error);
    }
  };

  const updateProfile = async () => {
    const user = auth.currentUser;
    if (!user) {
      // not logged in
      return;
    }

    const userRef = doc(db, "users", user.uid);

    // Using setDoc with merge option to ensure the document is created or updated
    await setDoc(
      userRef,
      {
        name: name,
        companyName: companyName,
      },
      { merge: true }
    );
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "users", uid), (doc) => {
      const data = doc.data();
      if (data && data.avatar) {
        setAvatarUrl(data.avatar);
      }
    });

    return () => unsubscribe();
  }, [uid]);

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const userId = auth.currentUser.uid; // get the current user's ID
      const filePath = `avatars/${userId}/${selectedFile.name}`; // create a path that includes the user's ID
      const storageRef = ref(storage, filePath);
      try {
        await uploadBytes(storageRef, selectedFile);
        const url = await getDownloadURL(storageRef);
        setAvatarUrl(url);

        await setDoc(
          doc(db, "users", userId),
          {
            avatar: url,
          },
          { merge: true }
        );
      } catch (error) {
        console.error("Error uploading file: ", error);
      }
    }
  };

  const fetchUserData = async () => {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const fetchedUserData = docSnap.data();
      if (fetchedUserData.name) {
        setName(fetchedUserData.name);
      }
      if (fetchedUserData.companyName) {
        setCompanyName(fetchedUserData.companyName);
      }
    } else {
      console.log("No User Information Found!");
    }
  };

  useEffect(() => {
    fetchUserData();

    const unsubscribe = onSnapshot(doc(db, "users", uid), (doc) => {
      const data = doc.data();
      if (data && data.avatar) {
        setAvatarUrl(data.avatar);
      }
    });

    return () => unsubscribe();
  }, [db, uid]);

  useEffect(() => {
    const fetchUserSubscription = async () => {
      try {
        const response = await fetch(cloudFunctionsConfig.getUserSubscription, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
          }),
        });

        const data = await response.json();

        if (data && data.tier) {
          useSubscriptionStore.setState({ currentTier: data.tier });
          if (data.tier !== "Loading..." && data.tier !== "No Plan") {
            useSubscriptionStore.setState({ isSubscribed: true });
          } else {
            useSubscriptionStore.setState({ isSubscribed: false });
          }
        }
      } catch (error) {
        console.error("Failed to fetch user subscription:", error);
      }
    };

    fetchUserSubscription();
  }, [email]);

  const handleSubmit = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const db = getFirestore();
        // Save other user details to the config document as before
        await setDoc(
          doc(db, `/users/${user.uid}/custom_config/config`),
          {
            salesPersonName,
            companyDescription,
            companyValues,
            companyName,
            redirectUrl,
            assistantGreeting,
          },
          { merge: true }
        );

        // Save the mobile number to its own document inside the "contact_details" sub-collection
        await setDoc(doc(db, `/users/${user.uid}/details/contact_number`), {
          number,
        });

        await setDoc(doc(db, `/users/${user.uid}/details/summary_email`), {
          sumEmail,
        });
        console.log("Data saved successfully!");
      } else {
        console.error("No user signed in!");
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleSalesmanTabClick = () => {
    if (
      currentTier === "Premium Plan" ||
      currentTier === "Unlimited Plan" ||
      currentTier === "Launchpad Plan"
    ) {
      setActiveTab("AI Salesman");
    } else {
      openUpgradeModal();
    }
  };

  useEffect(() => {
    const user = auth.currentUser;
    const saveUserEmail = async (email) => {
      try {
        const emailRef = doc(db, `/users/${user.uid}/details/account_email`);
        await setDoc(emailRef, { email });
      } catch (error) {
        console.error("Error saving email:", error);
      }
    };

    const fetchData = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          const db = getFirestore();
          const configRef = doc(db, `/users/${user.uid}/custom_config/config`);
          const configSnap = await getDoc(configRef);

          if (configSnap.exists()) {
            setSalesPersonName(configSnap.data().salesPersonName || "");
            setCompanyDescription(configSnap.data().companyDescription || "");
            setCompanyValues(configSnap.data().companyValues || "");
            setRedirectUrl(configSnap.data().redirectUrl || "");
            setAssistantGreeting(configSnap.data().assistantGreeting || "");
          } else {
            console.log("No such config document!");
          }

          // Fetch the mobile number from its document
          const numberRef = doc(
            db,
            `/users/${user.uid}/details/contact_number`
          );
          const numberSnap = await getDoc(numberRef);
          if (numberSnap.exists()) {
            setNumber(numberSnap.data().number || "");
          } else {
            console.log("No such phone number saved!");
          }

          const sumEmailRef = doc(
            db,
            `/users/${user.uid}/details/summary_email`
          );
          const sumEmailSnap = await getDoc(sumEmailRef);
          if (sumEmailSnap.exists()) {
            setSumEmail(sumEmailSnap.data().sumEmail || "");
          } else {
            console.log("No Summary Email saved!");
          }

          // Save the user's email
          saveUserEmail(email);
        } else {
          console.error("No user signed in!");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [email, uid, auth]);

  const handleNumberChange = (event) => {
    setNumber(event.target.value);
  };

  const handleSumEmailChange = (event) => {
    setSumEmail(event.target.value);
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      await updateProfile();
      await updateEmailAndPassword();
      await handleSubmit();

      // For Lead Generation
      const selectedQuestionsLeadArray = selectedQuestionsLead
        ? Object.keys(selectedQuestionsLead).filter(
            (key) => selectedQuestionsLead[key]
          )
        : [];

      const questionsLeadRef = doc(
        db,
        `/users/${auth.currentUser.uid}/question_config/questions_lead`
      );
      await setDoc(questionsLeadRef, { questions: selectedQuestionsLeadArray });

      // For AI Salesman
      const selectedQuestionsSalesArray = selectedQuestionsSales
        ? Object.keys(selectedQuestionsSales).filter(
            (key) => selectedQuestionsSales[key]
          )
        : [];

      const questionsSalesRef = doc(
        db,
        `/users/${auth.currentUser.uid}/question_config/questions`
      );
      await setDoc(questionsSalesRef, {
        questions: selectedQuestionsSalesArray,
      });

      // Making a GET request to the Flask server.
      const serverUrl = import.meta.env.VITE_APP_SERVER_URL;
      const response = await axios.get(`${serverUrl}/create_questions`, {
        params: {
          userID: auth.currentUser.uid,
        },
      });

      const questionsResponse = response.data;

      // Show the toast on successful update
      setShowToast(true);

      // Hide the toast after 3 seconds (or any preferred duration)
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const questionDoc = await getDoc(doc(db, `questions`, `question_list`));
        if (questionDoc.exists()) {
          setQuestions(questionDoc.data().topics || []);
        } else {
          console.log("No questions found");
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, []);

  const handleQuestionLeadSelection = (question) => {
    const isCurrentlySelected = selectedQuestionsLead[question];

    // If the user is trying to select a new question
    if (!isCurrentlySelected) {
      const currentSelectedCount = Object.values(selectedQuestionsLead).filter(
        Boolean
      ).length;
      const maxQuestions =
        currentTier === "Standard Plan" || currentTier === "No Plan"
          ? 4
          : Infinity; // Assuming no limit for other tiers

      // If they have reached their limit, show an error and exit the function
      if (currentSelectedCount >= maxQuestions) {
        setQuestionErrorToast(true);
        setTimeout(() => setQuestionErrorToast(false), 3000);
        return;
      }
    }

    // Update the state
    const newSelectedQuestions = {
      ...selectedQuestionsLead,
      [question]: !isCurrentlySelected,
    };
    setSelectedQuestionsLead(newSelectedQuestions);
    useSettingsStore.getState().checkMissingFields();
  };

  const handleQuestionSalesSelection = (question) => {
    const isCurrentlySelected = selectedQuestionsSales[question];

    // If the user is trying to select a new question
    if (!isCurrentlySelected) {
      const currentSelectedCount = Object.values(selectedQuestionsSales).filter(
        Boolean
      ).length;
      const maxQuestions =
        currentTier === "Standard Plan" || currentTier === "No Plan"
          ? 4
          : Infinity; // Assuming no limit for other tiers

      // If they have reached their limit, show an error and exit the function
      if (currentSelectedCount >= maxQuestions) {
        setQuestionErrorToast(true);
        setTimeout(() => setQuestionErrorToast(false), 3000);
        return;
      }
    }

    const newSelectedQuestions = {
      ...selectedQuestionsSales,
      [question]: !isCurrentlySelected,
    };
    setSelectedQuestionsSales(newSelectedQuestions);
    useSettingsStore.getState().checkMissingFields();
  };

  const fetchSavedQuestions = async () => {
    try {
      // Fetching saved questions for Lead Generation
      const questionsLeadRef = doc(
        db,
        `/users/${auth.currentUser.uid}/question_config/questions_lead`
      );
      const questionsLeadDoc = await getDoc(questionsLeadRef);
      if (questionsLeadDoc.exists()) {
        const savedQuestionsLead = questionsLeadDoc.data().questions;
        let savedQuestionsLeadObject = {};
        savedQuestionsLead.forEach((question) => {
          savedQuestionsLeadObject[question] = true;
        });
        setSelectedQuestionsLead(savedQuestionsLeadObject);
      } else {
        console.log("No saved lead questions found");
      }

      // Fetching saved questions for AI Salesman
      const questionsSalesRef = doc(
        db,
        `/users/${auth.currentUser.uid}/question_config/questions`
      );
      const questionsSalesDoc = await getDoc(questionsSalesRef);
      if (questionsSalesDoc.exists()) {
        const savedQuestionsSales = questionsSalesDoc.data().questions;
        let savedQuestionsSalesObject = {};
        savedQuestionsSales.forEach((question) => {
          savedQuestionsSalesObject[question] = true;
        });
        setSelectedQuestionsSales(savedQuestionsSalesObject);
      } else {
        console.log("No saved salesman questions found");
      }
    } catch (error) {
      console.error("Error fetching saved questions:", error);
    }
  };

  useEffect(() => {
    fetchSavedQuestions();
  }, []);

  const handleEmbedTabClick = (tabName) => {
    if (
      currentTier === "Premium Plan" ||
      currentTier === "Unlimited Plan" ||
      currentTier === "Launchpad Plan"
    ) {
      handleSettingTabClick(tabName);
    } else {
      openUpgradeModal();
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  const handleAlignmentChange = (event) => {
    setAlignment(event.target.value);
  };

  const getScriptUrl = () => {
    switch (alignment) {
      case 'Left Aligned':
        return 'https://cdn.jsdelivr.net/gh/DeBox-Technologies/MattressFrameModalLeft/frameModalLeft.js';
      case 'Center Aligned':
        return 'https://cdn.jsdelivr.net/gh/DeBox-Technologies/MattressFrameModalCenter/frameModalCenter.js';
      default: // 'Right Aligned'
        return 'https://cdn.jsdelivr.net/gh/DeBox-Technologies/MattressFrameModal/frameModal.js';
    }
  };

  const getProjectIDVoiceFlow = () => {
    switch (alignment) {
      case 'Left Aligned':
        return '668d8fc75c6f06ab0dcc06db';
      default: // 'Right Aligned'
        return '668c524b0516aafbf77cda9f';
    }
  };

  return (
    <div className='2xl:px-52 2xl:pt-4 p-4 sm:ml-64 min-h-screen text-zinc-700 dark:text-white h-full relative'>
      <div className='lg:mx-6 h-full'>
        <div className='mt-20 py-6'>
          <div className='flex flex-col gap-4 items'>
            <div className='flex justify-start items-start h-full'>
              <h1 className='text-4xl font-bold text-zinc-700 dark:text-white h-full'>
                Settings
              </h1>
            </div>
            <ol className=' hidden lg:flex flex-row gap-2 items-center'>
              <li
                className={`dark:text-white text-zinc-800 bg-zinc-100 dark:bg-zinc-800 hover:bg-zinc-200 hover:dark:bg-zinc-900 hover:cursor-pointer py-1.5 px-3 rounded-xl ${
                  activeSettingTab === "Account"
                    ? "bg-zinc-200 dark:bg-zinc-900"
                    : ""
                }`}
                onClick={() => handleSettingTabClick("Account")}>
                <a className='text-xs lg:text-base'>Account Settings</a>
              </li>
              <li
                className={`dark:text-white text-zinc-800 bg-zinc-100 dark:bg-zinc-800 hover:bg-zinc-200 hover:dark:bg-zinc-900 hover:cursor-pointer py-1.5 px-3 rounded-xl ${
                  activeSettingTab === "Assistant Configuration"
                    ? "bg-zinc-200 dark:bg-zinc-900"
                    : ""
                }`}
                onClick={() =>
                  handleSettingTabClick("Assistant Configuration")
                }>
                <a className='text-xs lg:text-base'>Assistant Configuration</a>
              </li>
              <li
                className={`dark:text-white text-zinc-800 bg-zinc-100 dark:bg-zinc-800 hover:bg-zinc-200 hover:dark:bg-zinc-900 hover:cursor-pointer py-1.5 px-3 rounded-xl ${
                  activeSettingTab === "Assistant Questions"
                    ? "bg-zinc-200 dark:bg-zinc-900"
                    : ""
                }`}
                onClick={() => handleSettingTabClick("Assistant Questions")}>
                <a className='text-xs lg:text-base'>Assistant Questions</a>
              </li>
              <li
                className={`dark:text-white text-zinc-800 bg-zinc-100 relative dark:bg-zinc-800 hover:bg-zinc-200 hover:dark:bg-zinc-900 hover:cursor-pointer py-1.5 px-3 rounded-xl ${
                  activeSettingTab === "Embed MattressAI"
                    ? "bg-zinc-200 dark:bg-zinc-900"
                    : ""
                }`}
                onClick={() => handleEmbedTabClick("Embed MattressAI")}
                disabled={!isAllowedTier}>
                <a className='text-xs lg:text-base'>Embed MattressAI</a>
                <div>
                  {!isAllowedTier && (
                    <div
                      title='Premium Feature'
                      className='absolute premium-indicator -top-3 -right-3 bg-gradient-to-r from-blue-500 to-green-400 shadow-green p-0.5 rounded-full'>
                      <svg
                        className='w-5 h-5 text-white'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z'></path>
                      </svg>
                    </div>
                  )}
                </div>
              </li>
              <li
                className={`dark:text-white text-zinc-800 bg-zinc-100 relative dark:bg-zinc-800 hover:bg-zinc-200 hover:dark:bg-zinc-900 hover:cursor-pointer py-1.5 px-3 rounded-xl ${
                  activeSettingTab === "Embed MattressAI (V2)"
                    ? "bg-zinc-200 dark:bg-zinc-900"
                    : ""
                }`}
                onClick={() => handleEmbedTabClick("Embed MattressAI (V2)")}
                disabled={!isAllowedTier}>
                <a className='text-xs lg:text-base'>Embed MattressAI (V2)</a>
                <span className="gradient-animation absolute -top-3 -right-3 text-white font-bold text-xs px-1 py-0.5 rounded-full">
                  Beta Feature
                  </span>
                <div>
                  {!isAllowedTier && (
                    <div
                      title='Premium Feature'
                      className='absolute premium-indicator -top-3 -right-3 bg-gradient-to-r from-blue-500 to-green-400 shadow-green p-0.5 rounded-full'>
                      <svg
                        className='w-5 h-5 text-white'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z'></path>
                      </svg>
                    </div>
                  )}
                </div>
              </li>
            </ol>
            <select
              value={activeSettingTab}
              onChange={(e) => handleSettingTabClick(e.target.value)}
              className='flex lg:hidden bg-zinc-200 dark:bg-zinc-800 hover:bg-zinc-300 hover:dark:bg-zinc-900 hover:cursor-pointer py-1.5 px-3 rounded-xl border-none'>
              <option value='Account'>Account Settings</option>
              <option value='Assistant Configuration'>
                Assistant Configuration
              </option>
              <option value='Assistant Questions'>Assistant Questions </option>
              {isAllowedTier && (
                <option value='Embed MattressAI'>Embed MattressAI</option>
              )}
            </select>
          </div>
        </div>

        {/* Account START */}
        {activeSettingTab === "Account" && (
          <div className='bg-zinc-100 dark:bg-zinc-800 p-4 rounded-xl h-full dark:text-white text-zinc-800'>
            <div className='flex flex-col lg:flex-row h-full gap-4 w-full dark:text-white text-zinc-800'>
              <div className='flex flex-col justify-center items-center w-full lg:w-1/2 bg-white dark:bg-zinc-700 rounded-lg shadow-lg h-full'>
                <div className='bg-cover w-full p-4 h-full'>
                  <div className='w-full flex flex-row justify-between items-center relative h-full'>
                    <div className='w-32'>
                      <input
                        id='avatar-input'
                        type='file'
                        accept='image/*'
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        className='bg-cover'
                      />
                      <label htmlFor='avatar-input' className='relative'>
                        <img
                          src={avatarUrl}
                          alt='avatar'
                          className='object-cover rounded-full bg-cover shadow-lg h-full w-full mx-auto bg-white p-1 hover:p-0 transition-all ease-in-out duration-200 hover:cursor-pointer'
                          style={{ aspectRatio: "1/1" }}
                        />
                        <div className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-200 bg-zinc-800/30 rounded-full hover:cursor-pointer'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            stroke='currentColor'
                            strokeWidth='2'
                            className='h-8 w-8 text-white'
                            style={{ aspectRatio: "1/1" }}
                            viewBox='0 0 24 24'
                            fill='none'>
                            <path
                              d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10'
                              strokeLinecap='round'
                              strokeLinejoin='round'></path>
                          </svg>
                        </div>
                      </label>
                    </div>
                    <div className='flex justify-center items-center'>
                      <p className=' border-none p-3 mt-1 rounded-2xl border border-zinc-600 bg-zinc-100/70 dark:bg-zinc-800/70 shadow-lg'>
                        {currentTier || "No Plan"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className='h-full w-full p-4'>
                  <p className='text-xl font-semibold dark:text-white text-zinc-800 h-full'>
                    Account Details
                  </p>
                  <div className='flex flex-col justify-center items-center py-4 gap-3 h-full'>
                    <label className='text-sm font-light dark:text-white text-zinc-800 w-full'>
                      <p className='text-sm font-bold dark:text-white text-zinc-800 w-full'> Full Name </p>
                      <p className='text-sm font-light dark:text-zinc-300 text-zinc-800 w-full'> This is so we know who to contact. </p>
                      <input
                        type='text'
                        placeholder={name || "Enter your Name"}
                        value={name}
                        className=' border-none p-3 mt-1 w-full rounded-2xl bg-zinc-100/70 dark:bg-zinc-800/70 shadow-lg'
                        onChange={(e) => setName(e.target.value)}
                      />
                    </label>
                    <label className='text-sm font-light dark:text-white text-zinc-800 w-full'>
                      <p className='text-sm font-bold dark:text-white text-zinc-800 w-full'> Company Name </p>
                      <p className='text-sm font-light dark:text-zinc-300 text-zinc-800 w-full'> Let the assistant know where it works. </p>
                      <input
                        type='text'
                        placeholder={companyName || "Enter Company Name"}
                        className=' border-none p-3 mt-1 w-full rounded-2xl bg-zinc-100/70 dark:bg-zinc-800/70 shadow-lg'
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className='w-full lg:w-1/2 bg-white dark:bg-zinc-700 rounded-lg min-h-full shadow-lg'>
                <div className='flex flex-col justify-evenly'>
                  <p className='text-xl p-4 font-semibold dark:text-white text-zinc-800'>
                    Account Security
                  </p>
                  <div className='flex flex-col justify-center items-center gap-2 p-4'>
                    <label className='text-sm font-light dark:text-white text-zinc-800 w-full'>
                      <p className='text-sm font-bold dark:text-white text-zinc-800 w-full'> Account Email </p>
                      <p className='text-sm font-light dark:text-zinc-300 text-zinc-800 w-full'> Let the assistant know where it works. </p>
                      <input
                        type='email'
                        placeholder='Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className='p-3 w-full rounded-2xl bg-zinc-100/70 dark:bg-zinc-800/70 shadow-lg'
                      />
                    </label>
                    <label className='text-sm font-light dark:text-white text-zinc-800 w-full'>
                      <p className='text-sm font-bold dark:text-white text-zinc-800 w-full'> Account Password </p>
                      <p className='text-sm font-light dark:text-zinc-300 text-zinc-800 w-full'> Let the assistant know where it works. </p>
                      <input
                        type='password'
                        placeholder='Change Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className='p-3 w-full rounded-2xl bg-zinc-100/70 dark:bg-zinc-800/70 shadow-lg'
                      />
                    </label>
                  </div>
                </div>
                <p className='text-xl p-4 font-semibold dark:text-white text-zinc-800'>
                  Membership
                </p>
                <div className='flex justify-center items-center'>
                  <Link
                    to='/membership'
                    className='text-center bg-zinc-100/70 dark:bg-zinc-800/70 text-zinc-800 dark:text-white font-semibold p-4 rounded-xl w-full hover:bg-zinc-200 dark:hover:bg-zinc-900 transition-all ease-in-out duration-200 m-4 shadow-lg'>
                    <div className='flex flex-row justify-center items-center gap-2'>
                      <h1>Modify Membership</h1>
                      <svg
                        className='w-4 h-4'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25'></path>
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='mt-4'>
              <button
                onClick={handleUpdate}
                disabled={isLoading}
                className={`font-semibold p-3 w-full rounded-lg ${
                  isLoading
                    ? "bg-zinc-400"
                    : "bg-gradient-to-r from-zinc-600 to-zinc-600 dark:from-blue-500 dark:to-green-400 dark:hover:from-blue-600 dark:hover:to-green-500"
                } text-white shadow-lg`}>
                {isLoading ? "Saving..." : "Update"}
              </button>
            </div>
          </div>
        )}
        {/* Account END */}

        {/* Assistant Configuration START */}
        {activeSettingTab === "Assistant Configuration" && (
          <div className='bg-zinc-100 dark:bg-zinc-800 p-4 rounded-xl h-full dark:text-white text-zinc-800'>
            <div className='flex flex-col lg:flex-row justify-center gap-4 w-full bg-white dark:bg-zinc-700 rounded-lg h-full shadow-lg p-4'>
              <div className='w-full lg:w-1/2'>
                <p className='text-xl font-semibold dark:text-white text-zinc-800 mb-5'>
                  Assistant Settings
                </p>
                <Notifier message='Modify your Assistants details and characteristics. These help the Assistant understand how it should act with your customers.' />
                <div className='flex flex-col justify-center items-center gap-3'>
                  <label className='flex flex-col text-sm font-light dark:text-white text-zinc-800 w-full'>
                    <p className='text-sm font-bold dark:text-white text-zinc-800 w-full'> Assistant Name </p>
                    <p className='text-sm font-light dark:text-zinc-300 text-zinc-800 w-full'> Give your assistant a name. </p>
                    <input
                      type='text'
                      placeholder={salesPersonName}
                      className=' border-none p-3 mt-1 w-full rounded-2xl bg-zinc-100/70 dark:bg-zinc-800/70 shadow-lg'
                      value={salesPersonName}
                      onChange={(e) => setSalesPersonName(e.target.value)}
                    />
                  </label>
                  <label className='flex flex-col text-sm font-light dark:text-white text-zinc-800 w-full'>
                    <p className='text-sm font-bold dark:text-white text-zinc-800 w-full'> Company Description </p>
                    <p className='text-sm font-light dark:text-zinc-300 text-zinc-800 w-full'> Let the assistant know what the company does.  </p>
                    <textarea
                      type='text'
                      placeholder={companyDescription}
                      className=' border-none p-3 mt-1 w-full rounded-2xl bg-zinc-100/70 dark:bg-zinc-800/70 shadow-lg'
                      value={companyDescription}
                      onChange={(e) => setCompanyDescription(e.target.value)}
                      rows={2} // This sets the initial height to 4 rows
                    />
                  </label>
                  <label className='flex flex-col text-sm font-light dark:text-white text-zinc-800 w-full'>
                    <p className='text-sm font-bold dark:text-white text-zinc-800 w-full'> Assistant Persona </p>
                    <p className='text-sm font-light dark:text-zinc-300 text-zinc-800 w-full'> Tell the assistant how it should act.  </p>
                    <textarea
                      placeholder={companyValues}
                      className='border-none p-3 mt-1 w-full rounded-2xl bg-zinc-100/70 dark:bg-zinc-800/70 shadow-lg resize-y'
                      value={companyValues}
                      onChange={(e) => setCompanyValues(e.target.value)}
                      rows={3} // This sets the initial height to 4 rows
                    />
                  </label>
                  <label className='flex flex-col text-sm font-light dark:text-white text-zinc-800 w-full'>
                    <p className='text-sm font-bold dark:text-white text-zinc-800 w-full'> Assistant Greeting </p>
                    <p className='text-sm font-light dark:text-zinc-300 text-zinc-800 w-full'> The initial message shown when a customer starts a session. If nothing is set MattressAI will create one using settings you have listed.</p>
                    <textarea
                      type='text'
                      placeholder={assistantGreeting}
                      className=' border-none p-3 mt-1 w-full rounded-2xl bg-zinc-100/70 dark:bg-zinc-800/70 shadow-lg'
                      value={assistantGreeting}
                      onChange={(e) => setAssistantGreeting(e.target.value)}
                      rows={2} // This sets the initial height to 4 rows
                    />
                  </label>
                </div>
              </div>
              <div className='w-full lg:w-1/2'>
                <div className='flex flex-col w-full h-full justify-between'>
                  <div>
                    <p className='text-xl font-semibold dark:text-white text-zinc-800 mb-5'>
                      Assistant Contact Details
                    </p>
                    <Notifier message="Add contact details to receive your Assistant's leads. 
                    These can often be linked directly to your CRM software.
                    MattressAI will send sessions directly to you." />
                    <div className='flex flex-col justify-center items-center gap-3'>
                      <label className='flex flex-col text-sm font-light dark:text-white text-zinc-800 w-full'>
                        <p className='text-sm font-bold dark:text-white text-zinc-800 w-full'> Admin Mobile Number </p>
                        <p className='text-sm font-light dark:text-zinc-300 text-zinc-800 w-full'> Your assistant will send info to this number. If you have Podium, add that number. </p>
                        <div className='flex flex-row justify-between items-center gap-2 h-full w-full'>
                          <input
                            type='tel'
                            value={number}
                            onChange={handleNumberChange}
                            className='rounded-xl w-full p-3 mt-1 bg-zinc-100/70 dark:bg-zinc-800/70 shadow-lg border-none focus:ring-0'
                          />
                        </div>
                      </label>
                      <label className='flex flex-col text-sm font-light dark:text-white text-zinc-800 w-full'>
                        <p className='text-sm font-bold dark:text-white text-zinc-800 w-full'> Admin Email for Summary </p>
                        <p className='text-sm font-light dark:text-white text-zinc-800 w-full'> Your assistant will send info to this email. Your store email is perfect. </p>
                        <div className='flex flex-row justify-between items-center gap-2 h-full w-full'>
                          <input
                            type='tel'
                            value={sumEmail}
                            onChange={handleSumEmailChange}
                            className='rounded-xl w-full p-3 mt-1 bg-zinc-100/70 dark:bg-zinc-800/70 shadow-lg border-none focus:ring-0'
                          />
                        </div>
                      </label>
                      <label className='flex flex-col text-m font-light dark:text-white text-zinc-800 w-full'>
                        <p className='text-sm font-bold dark:text-white text-zinc-800 w-full'> Chat Redirect Links </p>
                        <p className='text-sm font-light dark:text-zinc-300 text-zinc-800 w-full'> When a chat session ends, send the user back to your landing page, deals page or elsewhere. </p>
                        <div className='flex flex-row justify-between items-center gap-2 h-full w-full'>
                          <input
                            placeholder={redirectUrl}
                            value={redirectUrl}
                            onChange={(e) => setRedirectUrl(e.target.value)}
                            className='rounded-xl w-full p-3 mt-1 bg-zinc-100/70 dark:bg-zinc-800/70 shadow-lg border-none focus:ring-0'
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-4'>
              <button
                onClick={handleUpdate}
                disabled={isLoading}
                className={`font-semibold p-3 w-full rounded-lg ${
                  isLoading
                    ? "bg-zinc-400"
                    : "bg-gradient-to-r from-zinc-600 to-zinc-600 dark:from-blue-500 dark:to-green-400 dark:hover:from-blue-600 dark:hover:to-green-500"
                } text-white shadow-lg`}>
                {isLoading ? "Saving..." : "Update"}
              </button>
            </div>
          </div>
        )}
        {/* Assistant Configuration END */}

        {/* Assistant Questions START */}
        {activeSettingTab === "Assistant Questions" && (
          <div className='bg-zinc-100 dark:bg-zinc-800 p-4 rounded-xl h-full dark:text-white text-zinc-800'>
            <div className='flex flex-col justify-center gap-4 w-full bg-white dark:bg-zinc-700 rounded-lg h-full shadow-lg p-4'>
              <div className='w-full'>
                <p className='text-xl font-semibold dark:text-white text-zinc-800 mb-5'>
                  Lead Assistant Questions
                </p>
                <Notifier message='Choose which specific questions your assistants should enquire about. Remember that Plus will suggest a mattress, so ensure it asks enough questions. ' />
              </div>
              <div className='h-full w-full'>
                <div className='flex lg:flex-col xl:flex-row w-full gap-1 p-2 bg-zinc-200 dark:bg-zinc-800 rounded-xl '>
                  <button
                    className={`p-2 w-full text-sm rounded-lg transition-all ease-out duration-300 ${
                      activeTab === "Lead Generation"
                        ? "bg-gradient-to-r from-zinc-600 to-zinc-600 dark:from-blue-500 dark:to-green-400 dark:shadow-green text-white"
                        : ""
                    }`}
                    onClick={() => setActiveTab("Lead Generation")}>
                    Lead Lite Assistant
                  </button>
                  <button
                    className={`p-2 relative w-full text-sm rounded-lg transition-all ease-in-out duration-300 ${
                      activeTab === "AI Salesman"
                        ? "bg-gradient-to-r from-zinc-600 to-zinc-600 dark:from-blue-500 dark:to-green-400 dark:shadow-green text-white"
                        : ""
                    }`}
                    onClick={handleSalesmanTabClick}>
                    {" "}
                    Lead Plus Assistant
                    {(currentTier === "Standard Plan" ||
                      currentTier === "No Plan") && (
                      <div
                        title='Premium Feature'
                        className='absolute premium-indicator -top-3 -right-3 bg-gradient-to-r from-blue-500 to-green-400 shadow-green p-0.5 rounded-full'>
                        <svg
                          className='w-5 h-5 text-white'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                          aria-hidden='true'>
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z'></path>
                        </svg>
                      </div>
                    )}
                  </button>
                </div>
              </div>
              <div className='flex flex-col gap-4 justify-center items-center w-full'>
                {activeTab === "Lead Generation" &&
                  questions.map((question, index) => (
                    <div
                      key={index}
                      className='bg-zinc-100 w-full dark:bg-zinc-600 dark:text-white rounded-lg p-4 flex flex-row gap-4 justify-between items-center'>
                      <h1 className='bg-zinc-300 dark:bg-zinc-700 p-2 rounded-lg'>
                        {question}
                      </h1>
                      <label className='relative inline-flex items-center cursor-pointer'>
                        <input
                          type='checkbox'
                          value={question}
                          checked={selectedQuestionsLead[question] || false}
                          onChange={() => handleQuestionLeadSelection(question)}
                          className='sr-only peer'
                        />
                        <div className="w-11 h-6 bg-zinc-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-zinc-300 rounded-full peer dark:bg-zinc-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-zinc-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-zinc-600 peer-checked:bg-zinc-800 dark:peer-checked:bg-gradient-to-br dark:peer-checked:from-blue-500 dark:peer-checked:to-green-400 dark:peer-checked:shadow-green"></div>
                      </label>
                    </div>
                  ))}
              </div>
              <div className='flex flex-col gap-4 justify-center items-center w-full'>
                {activeTab === "AI Salesman" &&
                  questions.map((question, index) => (
                    <div
                      key={index}
                      className='bg-zinc-100 w-full dark:bg-zinc-600 dark:text-white rounded-lg p-4 flex flex-row gap-4 justify-between items-center'>
                      <h1 className='bg-zinc-300 dark:bg-zinc-700 p-2 rounded-lg'>
                        {question}
                      </h1>
                      <label className='relative inline-flex items-center cursor-pointer'>
                        <input
                          type='checkbox'
                          value={question}
                          checked={selectedQuestionsSales[question] || false}
                          onChange={() =>
                            handleQuestionSalesSelection(question)
                          }
                          className='sr-only peer'
                        />
                        <div className="w-11 h-6 bg-zinc-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-zinc-300 rounded-full peer dark:bg-zinc-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-zinc-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-zinc-600 peer-checked:bg-zinc-800 dark:peer-checked:bg-gradient-to-br dark:peer-checked:from-blue-500 dark:peer-checked:to-green-400 dark:peer-checked:shadow-green"></div>
                      </label>
                    </div>
                  ))}
              </div>
            </div>
            <div className='mt-4'>
              <button
                onClick={handleUpdate}
                disabled={isLoading}
                className={`font-semibold p-3 w-full rounded-lg ${
                  isLoading
                    ? "bg-zinc-400"
                    : "bg-gradient-to-r from-zinc-600 to-zinc-600 dark:from-blue-500 dark:to-green-400 dark:hover:from-blue-600 dark:hover:to-green-500"
                } text-white shadow-lg`}>
                {isLoading ? "Saving..." : "Update"}
              </button>
            </div>
          </div>
        )}
        {isAllowedTier && activeSettingTab === "Embed MattressAI" && (
          <div className='bg-zinc-100 dark:bg-zinc-800 p-4 rounded-xl h-full dark:text-white text-zinc-800'>
            <div className='flex flex-col justify-center gap-4 w-full bg-white dark:bg-zinc-700 rounded-lg h-full shadow-lg p-4'>
              <div className='w-full'>
                <p className='text-xl font-semibold dark:text-white text-zinc-800 mb-5'>
                  Embed MattressAI
                </p>
              </div>
              {/* Step 1 */}
              <div className='bg-zinc-100 dark:bg-zinc-800 rounded-lg h-full p-4 -mt-2'>
                <p className='text-lg font-semibold dark:text-white text-zinc-800 mb-2'>
                  Step 1: Select Configuration and Copy your unique CDN
                </p>
                <select
                  value={alignment}
                  onChange={handleAlignmentChange}
                  className='mb-4 bg-zinc-200 dark:bg-zinc-700 hover:bg-zinc-300 hover:dark:bg-zinc-900 hover:cursor-pointer py-1.5 px-3 rounded-xl border-none'
                >
                  <option value='Left Aligned'>Left Aligned</option>
                  <option value='Right Aligned'>Right Aligned</option>
                  <option value='Center Aligned'>Center Aligned</option>
                </select>
                <p className='text-base dark:text-zinc-200 text-zinc-600 mb-2'>
                  Then copy the following unique scripts in the following order :
                </p>
                <div className='flex flex-col gap-4'>
                  <pre className='bg-zinc-200 dark:bg-zinc-700 text-zinc-600 dark:text-zinc-300 p-4 rounded-lg overflow-x-auto flex flex-row justify-between'>
                    <span>{`<script>
                      window.myChatbotConfig = {
                        src: "https://dashboard.themattressai.com/QRchat/${uid}"
                      };
                      </script>`}
                    </span>
                    <button
                      onClick={() =>
                        copyToClipboard(
                          `<script>\n  window.myChatbotConfig = {\n    src: "https://dashboard.themattressai.com/QRchat/${uid}"\n  };\n</script>`
                        )
                      }>
                      <svg
                        className='w-7 h-7 p-1 hover:cursor-pointer bg-zinc-100 hover:bg-white dark:bg-zinc-800 hover:dark:bg-zinc-800/60 rounded-lg'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth={1.5}
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75'
                        />
                      </svg>
                    </button>
                  </pre>
                  <pre className='bg-zinc-200 dark:bg-zinc-700 text-zinc-600 dark:text-zinc-300 p-4 rounded-lg overflow-x-auto flex flex-row justify-between'>
                    <span>{`<script src="${getScriptUrl()}"></script>`}</span>
                    <button
                      onClick={() =>
                        copyToClipboard(
                          `<script src="${getScriptUrl()}""></script>`
                        )
                      }>
                      <svg
                        className='w-7 h-7 p-1 hover:cursor-pointer bg-zinc-100 hover:bg-white dark:bg-zinc-800 hover:dark:bg-zinc-800/60 rounded-lg'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth={1.5}
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75'
                        />
                      </svg>
                    </button>
                  </pre>
                </div>
              </div>
              {/* Step 2 */}
              <div className='bg-zinc-100 dark:bg-zinc-800 rounded-lg h-full p-4'>
                <p className='text-lg font-semibold dark:text-white text-zinc-800 mb-2'>
                  Step 2: Add the CDN to your HTML
                </p>
                <p className='text-base dark:text-zinc-200 text-zinc-600 mb-2'>
                  Integrate the script to your{" "}
                  <span className='font-bold'>&lt;head&gt;</span> in your
                  website&apos;s <span className='font-bold'>HTML</span> :
                </p>
                <pre className='dark:bg-zinc-700 bg-zinc-200 text-zinc-600 dark:text-zinc-300 p-4 rounded-lg overflow-x-auto'>
                  <code>
                    {"<!DOCTYPE html>\n"}
                    {'<html lang="en">\n'}
                    {"  <head>\n"}
                    {'    <meta charset="UTF-8">\n'}
                    {"    <title>Your Page Title</title>\n"}
                    {"    <!-- MattressAI CDN Integration Script -->\n"}
                    <span className='text-green-500'>{"    <script>\n"}</span>
                    <span className='text-green-500'>
                      {`      window.myChatbotConfig = {
        src: "https://dashboard.themattressai.com/QRchat/${uid}"
      };`}
                    </span>
                    <span className='text-green-500'>
                      {"\n    </script>\n"}
                    </span>
                    <span className='text-green-500'>{"    <script "}</span>
                    <span className='text-green-500'>
                      {
                        `src="${getScriptUrl()}"`
                      }
                    </span>
                    <span className='text-green-500'>{"></script>\n"}</span>
                    {"    <!-- Other head elements -->\n"}
                    {"  </head>\n"}
                    {"  <body>\n"}
                    {"    <!-- Your website content -->\n"}
                    {"  </body>\n"}
                    {"</html>"}
                  </code>
                </pre>
              </div>
            </div>
          </div>
        )}
        {isAllowedTier && activeSettingTab === "Embed MattressAI (V2)" && (
          <div className='bg-zinc-100 dark:bg-zinc-800 p-4 rounded-xl h-full dark:text-white text-zinc-800'>
            <div className='flex flex-col justify-center gap-4 w-full bg-white dark:bg-zinc-700 rounded-lg h-full shadow-lg p-4'>
              <div className='w-full'>
                <p className='text-xl font-semibold dark:text-white text-zinc-800 mb-5'>
                  Embed MattressAI (V2)
                </p>
                <Notifier message='Note: This is an experimental feature. You can quickly launch your Assistant as a Web Chat for your website using our 1-click Web Chat integration. This will allow you to get a fully-automated web chat experience on your website in minutes.' />
              </div>
              {/* Step 1 */}
              <div className='bg-zinc-100 dark:bg-zinc-800 rounded-lg h-full p-4 -mt-2'>
                <p className='text-lg font-semibold dark:text-white text-zinc-800 mb-2'>
                  Installation
                </p>
                <select
                  value={alignment}
                  onChange={handleAlignmentChange}
                  className='mb-4 bg-zinc-200 dark:bg-zinc-700 hover:bg-zinc-300 hover:dark:bg-zinc-900 hover:cursor-pointer py-1.5 px-3 rounded-xl border-none'
                >
                  <option value='Left Aligned'>Left Aligned</option>
                  <option value='Right Aligned'>Right Aligned</option>
                </select>
                <p className='text-base dark:text-zinc-200 text-zinc-600 mb-2'>
                {/* Displaying the tag text by using HTML entities */}
                Paste this code snippet before the closing <span> &lt;/body&gt; </span> tag on all pages you want the widget to appear.
                </p>
                <div className='relative'>
                  <button
                    onClick={() => copyToClipboard(`<script type="text/javascript">
          (function(d, t) {
              var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
              v.onload = function() {
                window.voiceflow.chat.load({
                  verify: { projectID: '${getProjectIDVoiceFlow()}' },
                  url: 'https://general-runtime.voiceflow.com',
                  versionID: 'production',
                  userID: '${uid}',
                });
              }
              v.src = "https://cdn.voiceflow.com/widget/bundle.mjs"; v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
          })(document, 'script');
        </script>`)}
                    className='absolute top-2 right-2 bg-zinc-300 dark:bg-zinc-600 hover:bg-zinc-400 dark:hover:bg-zinc-500 text-zinc-800 dark:text-white p-2 rounded-md'
                  >
                    Copy
                  </button>
                  <pre className='bg-zinc-200 dark:bg-zinc-700 text-zinc-600 dark:text-zinc-300 p-4 rounded-lg overflow-x-auto'>
                    <code>{`<script type="text/javascript">
  (function(d, t) {
      var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
      v.onload = function() {
        window.voiceflow.chat.load({
          verify: { projectID: '${getProjectIDVoiceFlow()}' },
          url: 'https://general-runtime.voiceflow.com',
          versionID: 'production',
          userID: '${uid}',
        });
      }
      v.src = "https://cdn.voiceflow.com/widget/bundle.mjs"; v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
  })(document, 'script');
</script>`}
        </code>
                  </pre>
                </div>
              </div>
              
              <div className='bg-zinc-100 dark:bg-zinc-800 rounded-lg h-full p-4 -mt-2'>
                <p className='text-lg font-semibold dark:text-white text-zinc-800 mb-2'>
                Proactive Text Message Bubbles
                </p>
                <p className='text-base dark:text-zinc-200 text-zinc-600 mb-2'>
                Add custom proactive text messages to draw attention to your MattressAI Assistant. 
                </p>
                <div className='relative'>
                  <button
                    onClick={() => copyToClipboard(`<script type="text/javascript">
          (function(d, t) {
              var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
              v.onload = function() {
                window.voiceflow.chat.load({
                  verify: { projectID: '${getProjectIDVoiceFlow()}' },
                  url: 'https://general-runtime.voiceflow.com',
                  versionID: 'production',
                  userID: '${uid}',
                }).then(() => {
                  window.voiceflow.chat.proactive.clear(); 
                  window.voiceflow.chat.proactive.push({ 
                    type: 'text', 
                    payload: { message: 'Welcome to ${companyName}!' }
                  }, { 
                    type: 'text', 
                    payload: { message: 'Click here to get fitted to a mattress with AI!' }
                  });
                });
              }
              v.src = "https://cdn.voiceflow.com/widget/bundle.mjs"; v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
          })(document, 'script');
        </script>`)}
                    className='absolute top-2 right-2 bg-zinc-300 dark:bg-zinc-600 hover:bg-zinc-400 dark:hover:bg-zinc-500 text-zinc-800 dark:text-white p-2 rounded-md'
                  >
                    Copy
                  </button>
                  <pre className='bg-zinc-200 dark:bg-zinc-700 text-zinc-600 dark:text-zinc-300 p-4 rounded-lg overflow-x-auto'>
                    <code>{`<script type="text/javascript">
  (function(d, t) {
      var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
      v.onload = function() {
        window.voiceflow.chat.load({
          verify: { projectID: '${getProjectIDVoiceFlow()}' },
          url: 'https://general-runtime.voiceflow.com',
          versionID: 'production',
          userID: '${uid}',
        }).then(() => {
          window.voiceflow.chat.proactive.clear(); 
          window.voiceflow.chat.proactive.push({ 
            type: 'text', 
            payload: { message: 'Welcome to ${companyName}!' }
          }, { 
            type: 'text', 
            payload: { message: 'Click here to get fitted to a mattress with AI!' }
          });
        });
      }
      v.src = "https://cdn.voiceflow.com/widget/bundle.mjs"; v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
  })(document, 'script');
</script>`}
        </code>
                  </pre>
                </div>
                <p className='text-base dark:text-zinc-200 text-zinc-600 my-2'>
                To customize your Proactive Text Message Bubbles, you just need to change the text in the 'payload' section as shown below. Change these to suit your brand and style.
                </p>
                <div className='relative'>
                  <pre className='bg-zinc-200 dark:bg-zinc-700 text-zinc-600 dark:text-zinc-300 p-4 rounded-lg overflow-x-auto'>
                    <code>{`{
          window.voiceflow.chat.proactive.clear(); 
          window.voiceflow.chat.proactive.push({ 
            type: 'text', 
            payload: { message: 'Welcome to ${companyName}!' }
          }, { 
            type: 'text', 
            payload: { message: 'Click here to get fitted to a mattress with AI!' }
});`}
                    </code>
                  </pre>
                </div>
              </div>
              <div className='bg-zinc-100 dark:bg-zinc-800 rounded-lg h-full p-4 -mt-2'>
                <p className='text-lg font-semibold dark:text-white text-zinc-800 mb-2'>
                  Shopify Integration
                </p>
                <p className='text-base dark:text-zinc-200 text-zinc-600 my-2'>
                  Follow these additional steps if you have a shopify website.
                </p>
                <ol className="list-decimal list-inside text-base dark:text-zinc-200 text-zinc-600 my-2 pl-4">
                  <li>Online Stores &gt; Themes &gt; Edit code</li>
                  <li>Choose file base.css or theme.css</li>
                  <li>Add code</li>
                </ol>
                <div className='relative'>
                  <pre className='bg-zinc-200 dark:bg-zinc-700 text-zinc-600 dark:text-zinc-300 p-4 rounded-lg overflow-x-auto'>
                  <code>{`#voiceflow-chat {
    display: block !important;
}`}
                    </code>
                  </pre>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='fixed top-20 w-full right-4 z-50'>
        {showToast && <ToastSuccess message='Profile updated successfully!' />}
      </div>
      <div className='fixed top-0 w-full right-0 z-50'>
        {questionErrorToast && (
          <ToastError message='Maximum questions reached for your plan!' />
        )}
      </div>
      <UpgradeModal />
    </div>
    //  Assistant Questions END
  );
};

export default Settings;
