import { useState, useEffect } from "react";
import { auth } from "../firebase/firebase";
import SendEmail from "../components/SendEmailModal";
import SendSMS from "../components/SendSMS";
import ToastSuccess from "../components/ToastSuccess";
import UpgradeModal from "./UpgradeModal";
import useSubscriptionStore from "../stores/subscriptionStore";

const TabSwitcherQR = ({ activeTab, onTabChange }) => {
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const { currentTier, openUpgradeModal } = useSubscriptionStore();

  const userId = auth.currentUser.uid;
  const chatUrl = import.meta.env.VITE_APP_DASHBOARD_URL;

  useEffect(() => {
    onTabChange(activeTab);
  }, [activeTab, onTabChange]);

  const handleSendEmailClick = () => {
    if (currentTier === "Standard Plan") {
      openUpgradeModal();
    } else {
      setShowEmailModal(true);
    }
  };

  const handleSendSMSClick = () => {
    if (currentTier === "Standard Plan") {
      openUpgradeModal();
    } else {
      setShowSMSModal(true);
    }
  };

  const handleCopyToClipboard = () => {
    let copyText;
    if (activeTab === "AI Salesman") {
      copyText = `${chatUrl}/QRchat/${userId}`;
    } else if (activeTab === "Lead Generation") {
      copyText = `${chatUrl}/QRchat2/${userId}`;
    }

    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        console.log("URL copied to clipboard successfully!");

        setShowSuccessToast(true);

        setTimeout(() => {
          setShowSuccessToast(false);
        }, 3000);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  const handleSalesmanTabClick = () => {
    if (currentTier === "Premium Plan" || currentTier === "Unlimited Plan" || currentTier === "Launchpad Plan") {
      onTabChange("AI Salesman");
    } else {
      openUpgradeModal();
    }
  };

  const renderLeadContent = () => (
    <div className='flex flex-col gap-3 justify-center items-center h-full w-full'>
      <div
        title='Send Via Email'
        className='bg-zinc-200 relative dark:bg-zinc-800 hover:bg-zinc-400 dark:hover:bg-zinc-900 cursor-pointer h-full w-full p-3 rounded-xl flex flex-row gap-2 justify-evenly items-center'
        onClick={handleSendEmailClick}>
        <svg
          className='w-7 h-7'
          fill='none'
          stroke='currentColor'
          strokeWidth={1.2}
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'>
          <path
            d='M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z'
            strokeLinecap='round'
            strokeLinejoin='round'></path>
        </svg>
        <h1 className='md:hidden xl:block text-sm'>Lead Via Email</h1>
        {(currentTier === "Standard Plan" || currentTier === "No Plan") && (
          <div
            title='Premium Feature'
            className='absolute premium-indicator -top-1 -right-1 bg-gradient-to-r from-blue-500 to-green-400 shadow-green p-0.5 rounded-full'>
            <svg
              className='w-5 h-5 text-white'
              fill='none'
              stroke='currentColor'
              strokeWidth='1.5'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z'></path>
            </svg>
          </div>
        )}
      </div>
      <div
        title='Send Via SMS Text'
        className='bg-zinc-200 relative dark:bg-zinc-800 hover:bg-zinc-400 dark:hover:bg-zinc-900 cursor-pointer h-full w-full p-3 rounded-xl flex flex-row gap-2 justify-evenly items-center'
        onClick={handleSendSMSClick}>
        <svg
          className='w-7 h-7'
          fill='none'
          stroke='currentColor'
          strokeWidth={1.2}
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'>
          <path
            d='M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z'
            strokeLinecap='round'
            strokeLinejoin='round'></path>
        </svg>
        {(currentTier === "Standard Plan" || currentTier === "No Plan") && (
          <div
            title='Premium Feature'
            className='absolute premium-indicator -top-1 -right-1 bg-gradient-to-r from-blue-500 to-green-400 shadow-green p-0.5 rounded-full'>
            <svg
              className='w-5 h-5 text-white'
              fill='none'
              stroke='currentColor'
              strokeWidth='1.5'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z'></path>
            </svg>
          </div>
        )}
        <h1 className='md:hidden xl:block text-sm'>Lead Via SMS</h1>
      </div>
      <div
        title='Share as Link'
        className='bg-zinc-200 dark:bg-zinc-800 hover:bg-zinc-400 dark:hover:bg-zinc-900 cursor-pointer h-full w-full p-3 rounded-xl flex flex-row gap-2 justify-evenly items-center'
        onClick={handleCopyToClipboard}>
        <svg
          className='w-7 h-7'
          fill='none'
          stroke='currentColor'
          strokeWidth={1.2}
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'>
          <path
            d='M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244'
            strokeLinecap='round'
            strokeLinejoin='round'></path>
        </svg>
        <h1 className='md:hidden xl:block text-sm'>Copy Lead Link</h1>
      </div>
      {showEmailModal && (
        <SendEmail
          onClose={() => setShowEmailModal(false)}
          activeTab={activeTab}
        />
      )}
      {showSMSModal && (
        <SendSMS onClose={() => setShowSMSModal(false)} activeTab={activeTab} />
      )}
    </div>
  );

  const renderSalesmanContent = () => (
    <div className='flex flex-col gap-3 justify-center items-center h-full w-full'>
      <div
        title='Send Via Email'
        className='bg-zinc-200 relative dark:bg-zinc-800 hover:bg-zinc-400 dark:hover:bg-zinc-900 cursor-pointer h-full w-full p-3 rounded-xl flex flex-row gap-2 justify-evenly items-center'
        onClick={handleSendEmailClick}>
        <svg
          className='w-7 h-7'
          fill='none'
          stroke='currentColor'
          strokeWidth={1.2}
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'>
          <path
            d='M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z'
            strokeLinecap='round'
            strokeLinejoin='round'></path>
        </svg>
        <h1 className='md:hidden xl:block text-sm'>Send Via Email</h1>
        {(currentTier === "Standard Plan" || currentTier === "No Plan") && (
          <div
            title='Premium Feature'
            className='absolute premium-indicator -top-1 -right-1 bg-gradient-to-r from-blue-500 to-green-400 shadow-green p-0.5 rounded-full'>
            <svg
              className='w-5 h-5 text-white'
              fill='none'
              stroke='currentColor'
              strokeWidth='1.5'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z'></path>
            </svg>
          </div>
        )}
      </div>
      <div
        title='Send Via SMS Text'
        className='bg-zinc-200 relative dark:bg-zinc-800 hover:bg-zinc-400 dark:hover:bg-zinc-900 cursor-pointer h-full w-full p-3 rounded-xl flex flex-row gap-2 justify-evenly items-center'
        onClick={handleSendSMSClick}>
        <svg
          className='w-7 h-7'
          fill='none'
          stroke='currentColor'
          strokeWidth={1.2}
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'>
          <path
            d='M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z'
            strokeLinecap='round'
            strokeLinejoin='round'></path>
        </svg>
        {(currentTier === "Standard Plan" || currentTier === "No Plan") && (
          <div
            title='Premium Feature'
            className='absolute premium-indicator -top-1 -right-1 bg-gradient-to-r from-blue-500 to-green-400 shadow-green p-0.5 rounded-full'>
            <svg
              className='w-5 h-5 text-white'
              fill='none'
              stroke='currentColor'
              strokeWidth='1.5'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z'></path>
            </svg>
          </div>
        )}
        <h1 className='md:hidden xl:block text-sm'>Send Via SMS</h1>
      </div>
      <div
        title='Share as Link'
        className='bg-zinc-200 dark:bg-zinc-800 hover:bg-zinc-400 dark:hover:bg-zinc-900 cursor-pointer h-full w-full p-3 rounded-xl flex flex-row gap-2 justify-evenly items-center'
        onClick={handleCopyToClipboard}>
        <svg
          className='w-7 h-7'
          fill='none'
          stroke='currentColor'
          strokeWidth={1.2}
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'>
          <path
            d='M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244'
            strokeLinecap='round'
            strokeLinejoin='round'></path>
        </svg>
        <h1 className='md:hidden xl:block text-sm'>Copy a Link</h1>
      </div>
      {showEmailModal && (
        <SendEmail
          onClose={() => setShowEmailModal(false)}
          activeTab={activeTab}
        />
      )}
      {showSMSModal && (
        <SendSMS onClose={() => setShowSMSModal(false)} activeTab={activeTab} />
      )}
    </div>
  );

  return (
    <>
      <div className='flex flex-col gap-4 justify-evenly h-full w-full'>
        <div className=''>
          <div className='flex lg:flex-col xl:flex-row w-full gap-1 p-2 bg-zinc-200 dark:bg-zinc-800 rounded-xl '>
            <button
              className={`p-4 w-full text-sm rounded-lg transition-all ease-out duration-300 ${
                activeTab === "Lead Generation"
                  ? "bg-gradient-to-r from-zinc-600 to-zinc-600 shadow-2xl dark:from-blue-500 dark:to-green-400 dark:shadow-green text-white"
                  : ""
              }`}
              onClick={() => onTabChange("Lead Generation")}>
              <div className='flex flex-row justify-center items-center gap-2'>
                <svg
                  className='h-5 w-5'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={1.5}
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z'
                  />
                </svg>
                <h1>Lite</h1>
              </div>
            </button>
            <button
              className={`p-4 relative w-full text-sm rounded-lg transition-all ease-in-out duration-300 ${
                activeTab === "AI Salesman"
                  ? "bg-gradient-to-r from-zinc-600 to-zinc-600 shadow-2xl dark:from-blue-500 dark:to-green-400 dark:shadow-green text-white"
                  : ""
              }`}
              onClick={handleSalesmanTabClick}>
              <div className='flex flex-row justify-center items-center gap-2'>
                <svg
                  className='h-5 w-5'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={1.5}
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z'
                  />
                </svg>
                <h1>Plus</h1>
              </div>
              {(currentTier === "Standard Plan" || currentTier === "No Plan") && (
                <div
                  title='Premium Feature'
                  className='absolute premium-indicator -top-3 -right-3 bg-gradient-to-r from-blue-500 to-green-400 shadow-green p-0.5 rounded-full'>
                  <svg
                    className='w-5 h-5 text-white'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='1.5'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z'></path>
                  </svg>
                </div>
              )}
            </button>
          </div>
        </div>

        <div className='h-full w-full'>
          {activeTab === "Lead Generation"
            ? renderLeadContent()
            : renderSalesmanContent()}
        </div>
      </div>

      {showEmailModal && (
        <SendEmail
          onClose={() => setShowEmailModal(false)}
          activeTab={activeTab}
        />
      )}
      {showSMSModal && (
        <SendSMS onClose={() => setShowSMSModal(false)} activeTab={activeTab} />
      )}

      {showSuccessToast && (
        <ToastSuccess
          message='URL copied successfully!'
          className='mt-20 mr-8'
        />
      )}
      {openUpgradeModal && <UpgradeModal />}
    </>
  );
};

export default TabSwitcherQR;
