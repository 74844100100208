const CarouselSlide2 = () => {
  return (
    <div className='bg-zinc-100 dark:bg-zinc-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
      <div className='flex justify-center items-center mb-8'>
        <div className='gradient-animation rounded-xl dark:shadow-green shadow-2xl'>
          <img
            src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png'
            className='w-12 h-12 bg-zinc-700 rounded-xl m-1 shadow-2xl'
          />
        </div>
      </div>
      <div className='flex flex-row items-center gap-2 lg:gap-0 w-full'>
        <div className='w-1/2 h-full'>
          <img src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720300067/widget_j5wtoe.png' />
        </div>
        <div className='w-1/2 mt-3 sm:ml-4 sm:mt-0 text-left'>
          <div className='flex flex-col'>
            <div className='hidden lg:flex justify-start items-center mb-8'>
              <div className='bg-zinc-200 dark:bg-zinc-700 rounded-full'>
                <svg
                  className='w-12 h-12 p-2 dark:text-white'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z'></path>
                </svg>
              </div>
            </div>

            <h1 className='text-3xl font-semibold leading-6 dark:text-zinc-100'>
              Choose your Plan
            </h1>
            <div className='mt-2'>
              <p className='text-sm lg:text-xl dark:text-zinc-500 lg:mb-4'>
                Your store currently{" "}
                <span className='dark:text-white font-bold'>
                  doesn&apos;t have a plan selected
                </span>
                , you will have to{" "}
                <span className='dark:text-white font-bold'>upgrade</span> to
                gain access MattressAI&apos;s features.
              </p>
            </div>
            <div>
              <p className='text-sm lg:text-xl dark:text-zinc-500'>
                Feel free to try our{" "}
                <span className='dark:text-white font-bold'>Leads Lite Assistant Demo</span> to
                preview the features before subscribing to a plan.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselSlide2;
