import { auth, db } from '../firebase/firebase';
import { doc, setDoc } from 'firebase/firestore';

const updateDatabase = async () => {
  const user = auth.currentUser

  if (user) {
    const userRef = doc(db, "users", user.uid);
    try {
      await setDoc(
        userRef,
        { hasValidSettingsConfig: true },
        { merge: true }
      );
    } catch (error) {
      console.error("Error updating database:", error);
      throw error;
    }
  }
};

export default updateDatabase;
