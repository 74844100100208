import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import ReactApexChart from "react-apexcharts";

function ChartComponent() {
  const [weeklyCounts, setWeeklyCounts] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [relevantDays, setRelevantDays] = useState([]);
  const db = getFirestore();

  const getWeeklyCounts = async (relevantDays) => {
    const counts = [];
    for (const day of relevantDays) {
      const count = await fetchCountsForDay(day);
      counts.push(count);
    }
    return counts;
  };

  const fetchCountsForDay = async (formattedDate) => {
    const auth = getAuth();
    const user = auth.currentUser;

    const ipDocRef = doc(db, `users/${user.uid}/data/IP_address`);
    const ipDoc = await getDoc(ipDocRef);

    if (ipDoc.exists()) {
      const data = ipDoc.data();
      return Object.values(data).reduce(
        (sum, ipData) => sum + (ipData[formattedDate] || 0),
        0
      );
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const getRelevantDays = () => {
      let currentDate = new Date();
      let dates = [];
      currentDate.setDate(currentDate.getDate() - 4);
      for (let i = 0; i < 7; i++) {
        const formattedDate = `${currentDate.getFullYear()}-${(
          currentDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${currentDate
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        dates.push(formattedDate);
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return dates;
    };

    setRelevantDays(getRelevantDays());
  }, []);

  useEffect(() => {
    const fetchAndPlotData = async () => {
      setWeeklyCounts(await getWeeklyCounts(relevantDays));
    };

    if (relevantDays.length) {
      fetchAndPlotData();
    }
  }, [relevantDays]);

  const chartOptions = {
    chart: {
      height: "100%",
      width: "100%",
      type: "area",
      fontFamily: "Montserrat, sans-serif",
      dropShadow: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.55,
        opacityTo: 0,
        shade: "#22c55e",
        gradientToColors: ["#22c55e"],
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
      colors: ["#a1a1aa"],
    },

    grid: {
      show: false,
      strokeDashArray: 4,
      padding: {
        left: 2,
        right: 2,
        top: 0,
      },
    },

    xaxis: {
      categories: relevantDays,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      type: "datetime",
    },
    yaxis: {
      show: false,
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    series: [
      {
        name: "Visitors",
        data: weeklyCounts,
        color: "#3b82f6",
      },
    ],
  };

  const renderChart = () => {
    return (
      <ReactApexChart
        options={chartOptions}
        series={chartOptions.series}
        type='area'
        height='100%'
        width='100%'
        className='h-full w-full'
      />
    );
  };

  return (
    <div id='area-chart' className='h-full w-full'>
      {renderChart()}
    </div>
  );
}

export default ChartComponent;
