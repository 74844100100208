import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import useSubscriptionStore from "./subscriptionStore";

const initialState = {
  name: "",
  companyName: "",
  salesPersonName: "",
  companyDescription: "",
  companyValues: "",
  number: "",
  sumEmail: "",
  avatarUrl:
    "https://res.cloudinary.com/djr22sgp3/image/upload/v1686063705/blank-profile-picture-973460_1280_vphxzq.jpg",
  canAccessRestrictedSections: false,
  selectedQuestionsLead: {},
  selectedQuestionsSales: {},
  isInitialized: false,
  canLaunchChat: false,
  showRedirectModal: false,
  redirectUrl: "https://www.themattressai.com",
  assistantGreeting: "",
};

const useSettingsStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      setIsInitialized: (value) => set({ isInitialized: value }),
      setName: (value) => set({ name: value }),
      setCompanyName: (value) => set({ companyName: value }),
      setSalesPersonName: (value) => set({ salesPersonName: value }),
      setCompanyDescription: (value) => set({ companyDescription: value }),
      setCompanyValues: (value) => set({ companyValues: value }),
      setNumber: (value) => set({ number: value }),
      setSumEmail: (value) => set({ sumEmail: value }),
      setAvatarUrl: (value) => set({ avatarUrl: value }),
      setShowRedirectModal: (value) => set({ showRedirectModal: value }),
      setMissingFields: (fields) => set({ missingFields: fields }),
      setRedirectUrl: (url) => set({ redirectUrl: url }),
      setAssistantGreeting: (greeting) => set({ assistantGreeting: greeting }),
      setSelectedQuestionsLead: (questions) =>
        set({ selectedQuestionsLead: questions }),
      setSelectedQuestionsSales: (questions) =>
        set({ selectedQuestionsSales: questions }),
      setCanLaunchChat: (value) => set({ canLaunchChat: value }),
      checkMissingFields: () => {
        const { currentTier } = useSubscriptionStore.getState();
        const fields = [
          { key: "name", label: "Full Name" },
          { key: "companyName", label: "Company Name" },
          { key: "salesPersonName", label: "Assistant Name" },
          { key: "companyDescription", label: "Company Description" },
          { key: "companyValues", label: "Company Values" },
          { key: "number", label: "Phone Number" },
          { key: "sumEmail", label: "Summarized Email" },
        ];

        const newMissingFields = fields
          .filter((field) => {
            if (field.default) {
              return get()[field.key] === field.default;
            }
            return !get()[field.key];
          })
          .map((field) => field.label);

        const selectedQuestionsLead = get().selectedQuestionsLead || {};
        const selectedQuestionsSales = get().selectedQuestionsSales || {};
        const isPremiumUser = currentTier === "Premium Plan" || currentTier === "Unlimited Plan" || currentTier === "Launchpad Plan";

        if (isPremiumUser) {
          if (
            Object.keys(selectedQuestionsLead).length === 0 ||
            !Object.values(selectedQuestionsLead).some((v) => v)
          ) {
            newMissingFields.push("Lead Lite Questions");
          }

          if (
            Object.keys(selectedQuestionsSales).length === 0 ||
            !Object.values(selectedQuestionsSales).some((v) => v)
          ) {
            newMissingFields.push("Lead Plus Questions");
          }
        } else {
          if (
            Object.keys(selectedQuestionsLead).length === 0 ||
            !Object.values(selectedQuestionsLead).some((v) => v)
          ) {
            newMissingFields.push("Lead Questions");
          }
        }

        set({
          missingFields: newMissingFields,
          canAccessRestrictedSections: newMissingFields.length === 0,
          canLaunchChat: newMissingFields.length === 0,
          showRedirectModal: newMissingFields.length > 0,
          isInitialized: true,
        });
      },
      resetState: () => set({ ...initialState }),
      setupRealtimeListeners: async (uid) => {
        const userRef = doc(db, "users", uid);
        const configRef = doc(db, `/users/${uid}/custom_config/config`);
        const numberRef = doc(db, `/users/${uid}/details/contact_number`);
        const sumEmailRef = doc(db, `/users/${uid}/details/summary_email`);
        const questionsRefLead = doc(
          db,
          `/users/${uid}/question_config/questions_lead`
        );
        const questionsRefSales = doc(
          db,
          `/users/${uid}/question_config/questions`
        );

        const unsubscribeUser = onSnapshot(userRef, (docSnapshot) => {
          const data = docSnapshot.data();
          if (data) {
            set({
              avatarUrl: data.avatar || initialState.avatarUrl,
              name: data.name || "",
              companyName: data.companyName || "",
            });
          }
        });

        const unsubscribeConfig = onSnapshot(configRef, (docSnapshot) => {
          const data = docSnapshot.data();
          if (data) {
            set({
              salesPersonName: data.salesPersonName || "",
              companyDescription: data.companyDescription || "",
              companyValues: data.companyValues || "",
            });
          }
        });

        const unsubscribeNumber = onSnapshot(numberRef, (docSnapshot) => {
          const data = docSnapshot.data();
          if (data) {
            set({ number: data.number || "" });
          }
        });

        const unsubscribeSumEmail = onSnapshot(sumEmailRef, (docSnapshot) => {
          const data = docSnapshot.data();
          if (data) {
            set({ sumEmail: data.sumEmail || "" });
          }
        });

        const unsubscribeQuestionsLead = onSnapshot(
          questionsRefLead,
          (docSnapshot) => {
            const data = docSnapshot.data();
            if (data && data.questions) {
              let savedQuestionsObject = {};
              data.questions.forEach((question) => {
                savedQuestionsObject[question] = true;
              });
              set({ selectedQuestionsLead: savedQuestionsObject });
            }
            // Call the checkMissingFields method inside the callback
            get().checkMissingFields();
          }
        );

        const unsubscribeQuestionsSales = onSnapshot(
          questionsRefSales,
          (docSnapshot) => {
            const data = docSnapshot.data();
            if (data && data.questions) {
              let savedQuestionsObject = {};
              data.questions.forEach((question) => {
                savedQuestionsObject[question] = true;
              });
              set({ selectedQuestionsSales: savedQuestionsObject });
            }
            // Call the checkMissingFields method inside the callback
            get().checkMissingFields();
          }
        );

        return () => {
          unsubscribeUser();
          unsubscribeConfig();
          unsubscribeNumber();
          unsubscribeSumEmail();
          unsubscribeQuestionsLead();
          unsubscribeQuestionsSales();
        };
      },
    }),

    {
      name: "settings-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useSettingsStore;
