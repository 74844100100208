import { Link } from "react-router-dom";
import useSettingsStore from "../stores/settingsStore";

const RedirectModal = () => {

  const { showRedirectModal, missingFields } = useSettingsStore();

  return (
    showRedirectModal && (
      <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
        <div className='bg-white dark:bg-zinc-800 p-8 rounded-xl text-zinc-800 dark:text-white'>
          <div className=' flex flex-col gap-6'>
            <div>
              <h2 className='text-xl font-semibold'>
                Please Complete Your Setup
              </h2>
              <p>
                You need to fill in the following details in the settings page
                before proceeding.
              </p>
            </div>
            <ul className="bg-zinc-100 dark:bg-zinc-700 p-4 rounded-xl shadow-2xl">
              {missingFields.map((field, index) => (
                <li key={index}>{field}</li>
              ))}
            </ul>
          </div>
          <Link
            to='/settings'
            className='font-semibold mt-8 inline-block bg-zinc-800 dark:bg-gradient-to-r dark:from-blue-500 dark:to-green-400 text-white rounded-xl px-4 py-2 shadow-xl'>
            Go to Settings
          </Link>
        </div>
      </div>
    )
  );
};

export default RedirectModal;
