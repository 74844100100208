import { useState } from "react";

import tutorialsData from "../data/Tutorials.json";
import TutorialModal from "../components/TutorialModal";

const TutorialsGrid = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleOpenModal = (tutorial) => {
    setSelectedTutorial(tutorial);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setSelectedTutorial(null);
  };

  const filteredTutorials = tutorialsData.filter((tutorial) =>
    tutorial.Title.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  return (
    <>
      <div className='2xl:px-52 2xl:pt-4 p-4 sm:ml-64 h-full'>
        <div className='min-h-screen mt-20 p-6 h-full w-full'>
          <div className='flex justify-start items-start h-full pb-6'>
            <h1 className='text-4xl font-bold text-zinc-700 dark:text-white'>
              Help, Tutorials and FAQ's
            </h1>
          </div>
          <div>
            <input
              type='text'
              placeholder='Search...'
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              className='w-full rounded-xl border-none dark:bg-zinc-800 dark:text-white mb-4'
            />
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 w-full h-full bg-zinc-800 p-4 rounded-xl'>
            {filteredTutorials.length > 0 ? (
              filteredTutorials.map((tutorial, index) => (
                <div
                  key={index}
                  className='bg-zinc-700 p-2 rounded-lg hover:cursor-pointer'
                  onClick={() => {
                      if (tutorial.Type === "video") {
                          handleOpenModal(tutorial);
                      } else if (tutorial.Type === "document") {
                          window.open(tutorial.VideoUrl, "_blank");
                      }
                  }}>
                  <div className='p-2 bg-zinc-800 rounded-lg'>
                      <img
                          src={tutorial.Thumbnail}
                          alt={tutorial.Title}
                          className='w-full rounded-md'
                      />
                  </div>
                  <h1 className='text-white pt-4 pb-2 text-lg px-2'>
                      {tutorial.Title}
                  </h1>
                </div>
              ))
            ) : (
              searchKeyword && (
                <p className="col-span-3 text-center text-white">
                  No Results, Try Rephrasing your search or Contact The MattressAi Team
                </p>
              )
            )}
          </div>
        </div>
      </div>
      {isOpen && selectedTutorial && (
        <TutorialModal
          tutorial={selectedTutorial}
          onClose={handleCloseModal}
          isOpen={isOpen}
        />
      )}
    </>
  );
};

export default TutorialsGrid;
