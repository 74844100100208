/* eslint-disable no-unused-vars */
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import { app } from "../firebase/firebase";
import { getAuth } from "firebase/auth";
import ToastSuccess from "./ToastSuccess";
import useSubscriptionStore from "../stores/subscriptionStore";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import cloudFunctionsConfig from "../utils/cloudFunctionsConfig";

const PricingCard = ({ price }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const firestoreDB = getFirestore(app);

  const {
    isSubscribed,
    setIsSubscribed,
    currentSubscriptionId,
    setCurrentSubscriptionId,
    currentTier,
    setCurrentTier,
    setShowMembershipModal,
    fetchSubscriptionStatus,
  } = useSubscriptionStore();

  const updateSubscriptionPlanInFirestore = async (
    userId,
    subscriptionPlan
  ) => {
    const userDocRef = doc(firestoreDB, "users", userId);

    try {
      await setDoc(
        userDocRef,
        {
          subscriptionPlan: subscriptionPlan,
        },
        { merge: true }
      );
      console.log("Subscription plan updated successfully!");
    } catch (error) {
      console.error("Failed to update subscription plan:", error);
    }
  };

  const handleSubscribe = async () => {
    setLoading(true);
    if (!stripe) {
      console.error("Stripe is not initialized.");
      return;
    }

    const auth = getAuth(app);
    const user = auth.currentUser;
    if (!user) {
      console.error("User is not authenticated");
      return;
    }

    try {
      const baseURL = import.meta.env.VITE_APP_MEMBERSHIP_URL || import.meta.env.VITE_APP_DASHBOARD_URL;
      const idToken = await user.getIdToken(true);

      console.log("id:", user.uid);

      const sessionResponse = await fetch(
        cloudFunctionsConfig.createCheckoutSession,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            priceId: price.id,
            baseURL: baseURL,
            isTrial: false,
            userId: user.uid
          }),
        }
      );

      if (!sessionResponse.ok) {
        const errorData = await sessionResponse.json();
        throw new Error(errorData.error || "Failed to create session.");
      }

      const session = await sessionResponse.json();

      if (session.sessionId) {
        const { error } = await stripe.redirectToCheckout({
          sessionId: session.sessionId,
        });
        if (error) {
          console.error(error);
        }
      } else {
        console.error("Session ID not received.");
      }
    } catch (err) {
      console.error("Error in handleSubscribe:", err.message);
    }
  };

  const handleCancelSubscription = async () => {
    const auth = getAuth(app);
    const user = auth.currentUser;

    setLoading(true);
    const baseURL = import.meta.env.VITE_APP_MEMBERSHIP_URL || import.meta.env.VITE_APP_DASHBOARD_URL;

    if (!user) {
      console.error("User is not authenticated");
      return;
    }

    try {
      const response = await fetch(
        cloudFunctionsConfig.createCustomerPortalSession,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
            baseURL: baseURL,
          }),
        }
      );

      const contentType = response.headers.get("Content-Type");
      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        window.location.href = data.url;
      } else {
        const text = await response.text();
        console.error("Unexpected response from server:", text);
      }
    } catch (error) {
      console.error("Error redirecting to Stripe's Customer Portal:", error);
    }
    setLoading(false);
  };


  const formattedPrice = `$${price.price / 100}`;
  const recurringInterval = `${
    price.recurring.interval.charAt(0).toUpperCase() +
    price.recurring.interval.slice(1)
  }`;

  if (price.name === "Launchpad Plan") {
    return null;
  }

  return (
    <>
      <div className='bg-white dark:bg-zinc-700 p-6 rounded-xl shadow-lg w-full xl:max-w-3xl mb-4'>
        <div className='flex flex-col justify-between h-full'>
          <div className='flex flex-row justify-between items-center dark:text-white'>
            <h2 className='text-xl mb-2'>{price.name}</h2>
            <p className='text-lg mb-4'>
              <span className='text-3xl font-semibold'>{formattedPrice}</span> /{" "}
              {recurringInterval}
            </p>
          </div>
          {price.metadata && (
            <ul className='mb-4 dark:text-zinc-300 flex flex-col justify-evenly h-full'>
              {Object.entries(price.metadata).map(([key, value]) => (
                <div key={key} className='flex flex-row gap-4 mb-4'>
                  <div>
                    <svg
                      className='w-6 h-6'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
                    </svg>
                  </div>
                  <li>{value}</li>
                </div>
              ))}
            </ul>
          )}

          <button
            onClick={() => setShowPriceModal(true)}
            className={`font-semibold text-white px-4 py-2 rounded-xl transition duration-300 mt-4
              ${
                currentTier === price.name
                  ? "bg-gradient-to-r from-blue-500 to-green-400"
                  : "bg-zinc-800"
              }`}>
            {currentTier === price.name ? "Modify" : "Choose"}
          </button>
          <div className="flex flex-row justify-end items-center gap-1.5 mt-1">
            <h1 className="text-zinc-800 dark:text-zinc-400 text-xs">Secure Payment via</h1>
            <img className="w-[8%] dark:invert dark:opacity-50" src="https://res.cloudinary.com/djr22sgp3/image/upload/v1696936370/stripe-svgrepo-com_xb8dff.svg"/>
          </div>

          <div
            className={
              showPriceModal
                ? "fixed inset-0 flex items-center justify-center z-50"
                : "hidden"
            }>
            <div className='bg-white dark:bg-zinc-700 rounded-lg shadow-lg p-6 w-96 z-40'>
              {showPriceModal && (
                <>
                  <div className='absolute top-4 right-4'>
                    {showToast && <ToastSuccess message={toastMessage} />}
                  </div>

                  <div className='flex flex-row justify-between items-center dark:text-white'>
                    <h2 className='text-xl mb-2 dark:text-white'>
                      {price.name}
                    </h2>
                    <p className='text-lg mb-4'>
                      <span className='text-3xl font-semibold'>
                        {formattedPrice}
                      </span>{" "}
                      / {recurringInterval}
                    </p>
                  </div>
                  {price.metadata && (
                    <ul className='mb-4 dark:text-zinc-300'>
                      {Object.entries(price.metadata).map(([key, value]) => (
                        <div key={key} className='flex flex-row gap-4 mb-4'>
                          <div>
                            <svg
                              className='w-6 h-6'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              viewBox='0 0 24 24'
                              xmlns='http://www.w3.org/2000/svg'
                              aria-hidden='true'>
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
                            </svg>
                          </div>
                          <li>{value}</li>
                        </div>
                      ))}
                    </ul>
                  )}
                  {isSubscribed && (
                    <div className='text-sm flex flex-row items-center gap-4 font-semibold bg-zinc-800 dark:bg-gradient-to-r dark:from-blue-500 dark:to-green-400 text-white px-4 py-2 rounded-xl transition duration-300 my-4 w-full'>
                      <svg
                        className='w-6 h-6'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z'></path>
                      </svg>
                      <p>
                        Subscribed to the{" "}
                        <span className='text-base'>{currentTier}</span>.
                      </p>
                    </div>
                  )}

                  {isSubscribed ? (
                    <button
                      onClick={handleCancelSubscription}
                      disabled={loading} // Disable the button when loading
                      className='bg-red-600 text-white px-4 py-2 rounded-xl transition duration-300 mt-2'>
                      {loading ? "Redirecting..." : `Modify ${currentTier}`}
                    </button>
                  ) : (
                    <button
                      onClick={handleSubscribe}
                      disabled={loading}
                      className={`font-semibold bg-zinc-800 dark:bg-gradient-to-r dark:from-blue-500 dark:to-green-400 text-white px-4 py-2 rounded-xl transition duration-300 mt-2`}>
                      {loading ? "Redirecting to Stripe..." : "Subscribe"}
                    </button>
                  )}
                </>
              )}
            </div>
            <div
              className='absolute inset-0 bg-black opacity-50'
              onClick={() => setShowPriceModal(false)}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingCard;
