/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";

import { useStripe } from "@stripe/react-stripe-js";
import ToastSuccess from "./ToastSuccess";

import useSubscriptionStore from "../stores/subscriptionStore";
import ToastError from "./ToastError";

import cloudFunctionsConfig from "../utils/cloudFunctionsConfig";

const CurrentTierCard = () => {
  const [last4, setLast4] = useState(null);
  const [expMonth, setExpMonth] = useState(null);
  const [expYear, setExpYear] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const {
    currentTier,
    trialInfo,
    fetchTrialInfo,
    hasUsedTrial,
    isCanceledButStillActive,
    subscriptionEndsAt,
  } = useSubscriptionStore();

  const formattedEndDate = subscriptionEndsAt
    ? new Date(subscriptionEndsAt).toLocaleDateString()
    : "";

  useEffect(() => {
    fetchTrialInfo();
    const fetchUserSubscription = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        console.error("User is not authenticated");
        return;
      }

      const userEmail = user.email;

      try {
        const cardResponse = await fetch(
          cloudFunctionsConfig.getCustomerPaymentMethod,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: userEmail,
            }),
          }
        );

        const cardData = await cardResponse.json();
        if (cardData && cardData.last4) {
          setLast4(cardData.last4);
          setExpMonth(cardData.exp_month);
          setExpYear(cardData.exp_year);
        }
      } catch (error) {
        console.error("Failed to fetch user subscription:", error);
      }
    };

    fetchUserSubscription();
  }, []);

  const handleStartTrial = async () => {
    setLoading(true);

    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error("User is not authenticated");
      setLoading(false);
      return;
    }

    if (trialInfo.isTrialActive) {
      setShowErrorToast(true);
      setLoading(false);
      return;
    }

    const token = await user.getIdToken();

    const response = await fetch(cloudFunctionsConfig.createCheckoutSession, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify({
        priceId: import.meta.env.VITE_APP_STRIPE_PRICE_ID,
        baseURL: window.location.origin,
        isTrial: true,
        userId: user.uid,
      }),
    });

    const result = await response.json();

    if (result && result.sessionId) {
      const { error } = await stripe.redirectToCheckout({
        sessionId: result.sessionId,
      });

      if (error) {
        console.error(error);
      }
    }
    setLoading(false);
  };

  const handleUpdateCardClick = async () => {
    setLoading(true);

    const auth = getAuth();
    const user = auth.currentUser;
    const baseURL =
      import.meta.env.VITE_APP_MEMBERSHIP_URL ||
      import.meta.env.VITE_APP_DASHBOARD_URL;

    if (!user) {
      console.error("User is not authenticated");
      return;
    }

    // Directly create a customer portal session using the user's email
    try {
      const response = await fetch(
        cloudFunctionsConfig.createCustomerPortalSession,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
            baseURL: baseURL,
          }),
        }
      );

      const contentType = response.headers.get("Content-Type");
      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        window.location.href = data.url;
      } else {
        const text = await response.text();
        console.error("Unexpected response from server:", text);
      }
    } catch (error) {
      console.error("Error redirecting to Stripe's Customer Portal:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToast]);

  useEffect(() => {
    if (showErrorToast) {
      const timer = setTimeout(() => {
        setShowErrorToast(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showErrorToast]);

  return (
    <div className='w-full h-full flex flex-col items-center gap-8'>
      <div className='flex flex-col lg:flex-row gap-8 w-full'>
        <div className='flex flex-col gap-6 w-full overflow-hidden relative bg-zinc-100 dark:bg-zinc-800 dark:text-white p-4 rounded-xl shadow-2xl'>
          <div className='h-full flex justify-start items-start text-xl'>
            Current Tier
          </div>
          {isCanceledButStillActive && (
            <div className='absolute px-2 py-1 text-xs top-4 right-4 rounded-lg dark:bg-gradient-to-r dark:from-blue-500 dark:to-green-400 bg-zinc-700 text-white'>
              <div className='flex flex-row gap-2 items-center justify-center'>
                <div>
                  <svg
                    className='w-5 h-5 text-white'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth={1.5}
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z'
                    />
                  </svg>
                </div>
                <h1>Active Until {formattedEndDate}</h1>
              </div>
            </div>
          )}
          <div className='h-full flex justify-end items-end text-2xl font-semibold'>
            {currentTier}
          </div>
          <div className='absolute left-3 -bottom-28 lg:-bottom-6 bg-white dark:bg-zinc-700 shadow-2xl rounded-t-2xl w-[55%] h-[70%] p-2'>
            <div className='h-full w-full bg-zinc-100 dark:bg-zinc-800/60 rounded-t-xl p-2'>
              <div className='flex justify-center items-start mt-1'>
                <div className='gradient-animation absolute left-50% rounded-lg shadow-green p-1'>
                  <img
                    src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png'
                    className='w-7 h-7 bg-zinc-700 rounded-lg'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col relative overflow-hidden gap-6 w-full bg-zinc-100 dark:bg-zinc-800 dark:text-white p-4 rounded-xl shadow-2xl'>
          <div className='h-full flex justify-start items-start text-xl'>
            Membership Billing
          </div>
          <div className='h-full flex justify-end items-end text-md'>
            <button
              className='bg-zinc-800 font-semibold dark:bg-gradient-to-r dark:from-blue-500 dark:to-green-400 hover:dark:from-blue-600 hover:dark:to-green-500 p-2 rounded-xl text-white'
              onClick={() => setIsModalOpen(true)}>
              Modify
            </button>
            {isModalOpen && (
              <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center z-50'>
                <div
                  className='absolute top-0 left-0 w-full h-full bg-black opacity-80'
                  onClick={() => setIsModalOpen(false)}></div>
                <div className='bg-white dark:bg-zinc-700 w-[90%] h-[50%] lg:w-[30%] lg:h-[50%] p-6 rounded-xl shadow-lg z-10 flex flex-col justify-between'>
                  <h2 className='text-xl mb-4 dark:text-white'>
                    Update Billing Details
                  </h2>
                  <div className='h-[60%] relative bg-white dark:bg-gradient-to-br dark:from-zinc-800 dark:to-zinc-500 shadow-2xl rounded-2xl w-full p-4'>
                    <div className='flex flex-col justify-between h-full'>
                      <div className='flex justify-start'>
                        {last4 ? `**** **** **** ${last4}` : "No card on file"}
                      </div>
                      <div className='flex justify-end'>
                        {expMonth && expYear
                          ? `${expMonth}/${expYear}`
                          : "No card on file"}
                      </div>
                    </div>
                  </div>

                  <div className='flex flex-col lg:flex-row justify-between items-center gap-4 w-full'>
                    <div className='w-full'>
                      <div className='flex flex-row justify-center lg:justify-start items-center gap-1.5 mt-1'>
                        <h1 className='text-zinc-800 dark:text-zinc-400 text-xs'>
                          Secured via
                        </h1>
                        <img
                          className='w-[10%] lg:w-[20%] dark:invert dark:opacity-50'
                          src='https://res.cloudinary.com/djr22sgp3/image/upload/v1696936370/stripe-svgrepo-com_xb8dff.svg'
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='flex w-full lg:justify-end'>
                        <button
                          className='bg-zinc-800 font-semibold dark:bg-gradient-to-r dark:from-blue-500 dark:to-green-400 p-2 rounded-xl text-white w-full'
                          onClick={handleUpdateCardClick}>
                          {" "}
                          {loading
                            ? "Redirecting To Stripe..."
                            : "Update Billing Account"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='absolute left-3 -bottom-28 lg:-bottom-6 bg-white dark:bg-zinc-700 shadow-2xl rounded-t-2xl w-[55%] h-[70%] p-2'>
            <h1 className='p-1'>**** **** **** {last4}</h1>
          </div>
        </div>
        {showToast && (
          <ToastSuccess
            message='Card updated successfully!'
            className='mt-4 mr-4'
          />
        )}
      </div>
      {(trialInfo.isTrialActive || !hasUsedTrial) && (
        <div className='w-full bg-zinc-100 dark:bg-zinc-800 dark:text-white p-4 rounded-xl shadow-2xl flex flex-col gap-6'>
          <div className='h-full w-full flex flex-col lg:flex-row items-center justify-between gap-4 lg:gap-0'>
            <div className='w-full lg:w-2/3'>
              <div className='w-full flex flex-col gap-4 dark:text-white'>
                <h1 className='h-full w-full flex justify-start items-start text-xl'>
                  15 Day Free Trial
                </h1>
                <div className='flex flex-row items-center gap-4'>
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='1.5'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
                  </svg>
                  <h1>Get Free Access to MattressAI.</h1>
                </div>
                <div className='flex flex-row items-center gap-4'>
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='1.5'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
                  </svg>
                  <h1>Get Access to Premium Features for 15 Days.</h1>
                </div>
                <div className='flex flex-row items-center gap-4'>
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='1.5'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
                  </svg>
                  <h1>Test our Premium Plan.</h1>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-1/3 h-full'>
              <div className='bg-white dark:bg-zinc-700 shadow-xl w-full rounded-2xl h-52 p-4'>
                <div className='flex flex-col h-full justify-between'>
                  <div className='h-full w-full flex justify-between relative'>
                    <div>
                      <div className='gradient-animation absolute left-50% rounded-lg shadow-green p-1'>
                        <img
                          src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png'
                          className='w-7 h-7 bg-zinc-700 rounded-lg'
                        />
                      </div>
                    </div>

                    {trialInfo.isTrialActive ? (
                      <div>
                        <h1 className='text-xl font-bold bg-zinc-600 p-2 rounded-xl'>
                          Trial Active
                        </h1>
                      </div>
                    ) : (
                      <h1 className='text-xl font-bold'>Free Trial</h1>
                    )}
                  </div>
                  <div className='w-full flex justify-end'>
                    <button
                      data-tooltip-target='tooltip-default'
                      onClick={handleStartTrial}
                      disabled={loading}
                      className='font-semibold text-white px-4 py-2 rounded-xl transition duration-300 mt-4 bg-gradient-to-r from-blue-500 to-green-400'>
                      {loading
                        ? "Redirecting To Stripe..."
                        : trialInfo.isTrialActive
                        ? "Trial Active"
                        : "Start Trial"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showErrorToast && (
        <div className='fixed top-0 right-0 w-full'>
          <ToastError message='Cancel your Trial using the Billing Widget Above.' />
        </div>
      )}
    </div>
  );
};

export default CurrentTierCard;
