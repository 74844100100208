// cloudFunctionsConfig.js
const devConfig = {
  createCheckoutSession: "https://us-central1-mattressai-dev.cloudfunctions.net/createCheckoutSession",
  createCustomerPortalSession: "https://us-central1-mattressai-dev.cloudfunctions.net/createCustomerPortalSession",
  generateJWT: "https://us-central1-mattressai-dev.cloudfunctions.net/generateJWT",
  getCustomerPaymentMethod: "https://us-central1-mattressai-dev.cloudfunctions.net/getCustomerPaymentMethod",
  getStripeProducts: "https://us-central1-mattressai-dev.cloudfunctions.net/getStripeProducts",
  getUserSubscription: "https://us-central1-mattressai-dev.cloudfunctions.net/getUserSubscription",
  healthCheck: "https://us-central1-mattressai-dev.cloudfunctions.net/healthCheck",
  stripeSubscriptionWebhook: "https://us-central1-mattressai-dev.cloudfunctions.net/stripeSubscriptionWebhook",
  stripeWebhook: "https://us-central1-mattressai-dev.cloudfunctions.net/stripeWebhook",
  updateCustomerPaymentMethod: "https://us-central1-mattressai-dev.cloudfunctions.net/updateCustomerPaymentMethod",
  // createOneTimeCheckoutSession: "https://us-central1-mattressai-dev.cloudfunctions.net/createOneTimeCheckoutSession",
};

const prodConfig = {
  cancelSubscription: "https://us-central1-mattressai-fb51e.cloudfunctions.net/cancelSubscription",
  createCheckoutSession: "https://us-central1-mattressai-fb51e.cloudfunctions.net/createCheckoutSession",
  createCustomerPortalSession: "https://us-central1-mattressai-fb51e.cloudfunctions.net/createCustomerPortalSession",
  generateJWT: "https://us-central1-mattressai-fb51e.cloudfunctions.net/generateJWT",
  getCustomerPaymentMethod: "https://us-central1-mattressai-fb51e.cloudfunctions.net/getCustomerPaymentMethod",
  getStripeProducts: "https://us-central1-mattressai-fb51e.cloudfunctions.net/getStripeProducts",
  getUserSubscription: "https://us-central1-mattressai-fb51e.cloudfunctions.net/getUserSubscription",
  healthCheck: "https://us-central1-mattressai-fb51e.cloudfunctions.net/healthCheck",
  stripeSubscriptionWebhook: "https://us-central1-mattressai-fb51e.cloudfunctions.net/stripeSubscriptionWebhook",
  stripeWebhook: "https://us-central1-mattressai-fb51e.cloudfunctions.net/stripeWebhook",
  updateCustomerPaymentMethod: "https://us-central1-mattressai-fb51e.cloudfunctions.net/updateCustomerPaymentMethod",
  // createOneTimeCheckoutSession: "https://us-central1-mattressai-fb51e.cloudfunctions.net/createOneTimeCheckoutSession",
};

const cloudFunctionsConfig = process.env.NODE_ENV === 'development' ? devConfig : prodConfig;

export default cloudFunctionsConfig;
