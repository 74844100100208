import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useSubscriptionStore from "../stores/subscriptionStore";
import { Link } from "react-router-dom";
import { auth, db } from "../firebase/firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import axios from "axios";

import CarouselSlide1 from "./Carousel/CarouselSlide1";
import CarouselSlide2 from "./Carousel/CarouselSlide2";
import CarouselSlide3 from "./Carousel/CarouselSlide3";
import CarouselSlide4 from "./Carousel/CarouselSlide4";
import CarouselSlide5 from "./Carousel/CarouselSlide5";
import ToastError from "./ToastError";

export default function MembershipModal() {
  const { showMembershipModal } = useSubscriptionStore();
  const [showToast, setShowToast] = useState(false);

  const updateClickStatus = async (userId, field, value) => {
    const userRef = doc(db, "users", userId);

    // Get the document first
    const docSnap = await getDoc(userRef);

    // Check if the document exists
    if (docSnap.exists()) {
      // If the document exists, update it
      await updateDoc(userRef, {
        [field]: value,
      });
    } else {
      // If the document doesn't exist, create it with the new field
      await setDoc(userRef, {
        [field]: value,
      }, { merge: true });
    }
  };

  const handleChatLaunch = async (e) => {
    if (e.target.disabled) {
      return;
    }

    try {
      const userId = auth.currentUser.uid;

      const functionsUrl = import.meta.env.VITE_APP_FUNCTIONS_URL;
      const tokenResponse = await axios.post(`${functionsUrl}/generateJWT`, {
        userId: userId,
      });
      const token = tokenResponse.data.token;

      const serverUrl = import.meta.env.VITE_APP_SERVER_URL;
      const chatUrl = import.meta.env.VITE_APP_CHAT_URL;

      const response = await axios.get(
        `${serverUrl}/start-chat-demo?userID=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      const chatRedirectUrl = `${chatUrl}/demo?token=${data.token}`;
      window.location.href = chatRedirectUrl;
    } catch (error) {
      console.error("Error in handleChatLaunch:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    }
  };

  const [currentSlide, setCurrentSlide] = useState(0);
  const nextSlide = () => {
    if (currentSlide < 4) {
      setCurrentSlide((prevSlide) => prevSlide + 1);
    }
  };
  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prevSlide) => prevSlide - 1);
    }
  };

  const handleDisabledClick = (e) => {
    e.preventDefault();
    console.log("Showing toast");
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const handleButtonClick = async (e) => {
    if (currentSlide === 4) {
      // Update Firestore for Launch Demo click
      const userId = auth.currentUser.uid;
      await updateClickStatus(userId, "hasLaunchedDemo", true);
      handleChatLaunch(e);
    } else {
      handleDisabledClick(e);
    }
  };

  return (
    <Transition.Root show={showMembershipModal} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-zinc-900 bg-opacity-75 transition-opacity' />
        </Transition.Child>
        <div className='fixed top-0 w-full right-0 z-50'>
          {showToast && (
            <ToastError message='Please navigate to the last slide to continue.' />
          )}
        </div>
        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl'>
                {currentSlide === 0 && <CarouselSlide1 />}
                {currentSlide === 1 && <CarouselSlide2 />}
                {currentSlide === 2 && <CarouselSlide3 />}
                {currentSlide === 3 && <CarouselSlide4 />}
                {currentSlide === 4 && <CarouselSlide5 />}
                <div className='carousel-controls bg-zinc-100 dark:bg-zinc-800 text-white flex flex-row justify-between items-center w-full p-6'>
                  <button onClick={prevSlide}>
                    <svg
                      className='w-8 h-8 text-zinc-800 dark:text-white'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18'></path>
                    </svg>
                  </button>
                  <div className='flex mx-4'>
                    <div
                      className={`h-3 w-3 mx-2 rounded-full ${
                        currentSlide === 0
                          ? "bg-zinc-700 dark:bg-zinc-100"
                          : "bg-zinc-400"
                      }`}
                    />
                    <div
                      className={`h-3 w-3 mx-2 rounded-full ${
                        currentSlide === 1
                          ? "bg-zinc-700 dark:bg-zinc-100"
                          : "bg-zinc-400"
                      }`}
                    />
                    <div
                      className={`h-3 w-3 mx-2 rounded-full ${
                        currentSlide === 2
                          ? "bg-zinc-700 dark:bg-zinc-100"
                          : "bg-zinc-400"
                      }`}
                    />
                    <div
                      className={`h-3 w-3 mx-2 rounded-full ${
                        currentSlide === 3
                          ? "bg-zinc-700 dark:bg-zinc-100"
                          : "bg-zinc-400"
                      }`}
                    />
                    <div
                      className={`h-3 w-3 mx-2 rounded-full ${
                        currentSlide === 4
                          ? "bg-zinc-700 dark:bg-zinc-100"
                          : "bg-zinc-400"
                      }`}
                    />
                  </div>
                  <button onClick={nextSlide}>
                    <svg
                      className='w-8 h-8 text-zinc-800 dark:text-white'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3'
                      />
                    </svg>
                  </button>
                </div>
                <div className='bg-zinc-300 dark:bg-zinc-700 px-4 py-3 flex flex-col gap-2 sm:flex-row-reverse sm:px-6'>
                  <Link
                    to={currentSlide === 4 ? "/membership" : "#"}
                    className={`inline-flex w-full justify-center rounded-md bg-zinc-800 dark:bg-gradient-to-r dark:from-blue-500 dark:bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto ${
                      currentSlide < 4
                        ? "opacity-50 cursor-not-allowed"
                        : "opacity-100"
                    }`}
                    onClick={async (e) => {
                      if (currentSlide === 4) {
                        // closeMembershipModal();
                        const userId = auth.currentUser.uid;
                        await updateClickStatus(
                          userId,
                          "hasClickedUpgrade",
                          true
                        );
                      } else {
                        handleDisabledClick(e);
                      }
                    }}>
                    Upgrade
                  </Link>

                  <button
                    className={`inline-flex w-full justify-center rounded-md bg-zinc-100 dark:bg-zinc-800 px-3 py-2 text-sm font-semibold dark:text-white shadow-sm hover:bg-white hover:dark:bg-zinc-900 sm:ml-3 sm:w-auto ${
                      currentSlide < 4
                        ? "opacity-50 cursor-not-allowed"
                        : "opacity-100"
                    }`}
                    onClick={handleButtonClick}>
                    Launch Demo
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
