import { useRef, useState, useEffect } from "react";
import { auth } from "../firebase/firebase";
import ChartComponent from "../components/UsersGraph";
import DocsCard from "../components/DocsCard";
import useSettingsStore from "../stores/settingsStore";
import TabSwitcherQR from "../components/TabSwitcherQR";
import { QRCode } from "react-qrcode-logo";
import html2canvas from "html2canvas";

import sparkles from "../assets/Sparkles.svg";
import fire from "../assets/Fire.svg";

const QRCodeGenerator = () => {
  const [qrValue, setQrValue] = useState("");
  const [canGenerate, setCanGenerate] = useState(false);
  const [activeTab, setActiveTab] = useState("Lead Generation");
  const qrRef = useRef(null);

  const { missingFields, checkMissingFields } = useSettingsStore();

  useEffect(() => {
    checkMissingFields();
  }, []);

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  useEffect(() => {
    if (missingFields && missingFields.length > 0) {
      setCanGenerate(false);
    } else {
      setCanGenerate(true);
    }
  }, [missingFields]);

  const handleGenerateClick = async () => {
    console.log(canGenerate);
    if (!canGenerate) {
      return;
    }

    const dashboardUrl = import.meta.env.VITE_APP_DASHBOARD_URL;

    try {
      const userId = auth.currentUser.uid;
      let qrUrl;

      if (activeTab === "AI Salesman") {
        qrUrl = `${dashboardUrl}/QRchat/${userId}`;
      } else if (activeTab === "Lead Generation") {
        qrUrl = `${dashboardUrl}/QRchat2/${userId}`;
      }

      console.log("activeTab:", activeTab, "qrUrl:", qrUrl);
      setQrValue(qrUrl);
    } catch (error) {
      console.error("Error in handleGenerateClick:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    }
  };

  useEffect(() => {
    if (canGenerate) {
      handleGenerateClick();
    }
  }, [activeTab, canGenerate]);

  const logoImage = activeTab === "AI Salesman" ? fire : sparkles;

  const handleDownloadClick = () => {
    html2canvas(qrRef.current).then((canvas) => {
      const link = document.createElement("a");
      link.download = "MattressAI-QR.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  return (
    <>
      <div className='2xl:px-52 2xl:pt-4 p-4 sm:ml-64 h-full'>
        <div className='min-h-screen mt-20 p-6 h-full'>
          <h1 className='text-4xl font-bold h-full flex justify-start items-start text-stone-800 dark:text-white mb-4'>
            Share MattressAI
          </h1>
          <div className='h-full'>
            <div className='bg-zinc-800 text-white my-4 p-4 rounded-xl flex flex-row items-center gap-3 h-full'>
              <svg
                fill='none'
                stroke='currentColor'
                className='w-5 h-5'
                strokeWidth='1.5'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'></path>
              </svg>
              <p>
                Select from the options below to share your MattressAI
                Assistants to your customers.
              </p>
            </div>
            <div className='flex flex-col w-full h-full gap-4'>
              <div className='grid grid-cols-2 gap-4 h-full'>
                <div className='bg-zinc-100 flex justify-center items-center col-span-2 lg:col-span-1 dark:bg-zinc-800 rounded-xl p-4 h-full w-full'>
                  <div className='flex flex-col lg:flex-row gap-4 items-center h-full w-full'>
                    <div className='w-full h-full bg-white dark:bg-zinc-700 shadow-2xl p-4 rounded-xl text-zinc-700 dark:text-zinc-500'>
                      <TabSwitcherQR
                        activeTab={activeTab}
                        onTabChange={handleTabChange}
                      />
                    </div>
                    <div className='flex flex-col justify-center bg-white dark:bg-zinc-700 rounded-xl shadow-2xl items-center gap-8 p-4 h-full w-full'>
                      <svg
                        className={`font-semibold w-24 h-24 lg:w-36 lg:h-36 bg-gradient-to-r text-white from-zinc-600 to-zinc-600 dark:from-blue-500 dark:to-green-400 dark:hover:from-blue-600 dark:hover:to-green-500 dark:shadow-green rounded-xl ${
                          qrValue ? "hidden" : ""
                        }`}
                        fill='none'
                        stroke='currentColor'
                        strokeWidth={1.2}
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z'
                        />
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z'
                        />
                      </svg>

                      {qrValue ? (
                        <div className='font-semibold p-0 bg-gradient-to-br shadow-2xl from-zinc-300 to-zinc-400 dark:from-blue-500 dark:to-green-400 rounded-xl transition-all ease-in-out duration-500'>
                          {qrValue ? (
                            <div className='rounded-xl overflow-hidden'>
                              <div id='react-qrcode-logo' ref={qrRef}>
                                <QRCode
                                  value={qrValue}
                                  logoImage={logoImage}
                                  logoWidth={20}
                                  logoHeight={20}
                                  removeQrCodeBehindLogo={true}
                                  logoPadding={5}
                                  logoPaddingStyle='circle'
                                  bgColor='#27272a'
                                  fgColor='#f4f4f5'
                                  qrStyle='dots'
                                  quietZone={15}
                                  ecLevel='H'
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      {qrValue ? (
                        <>
                          <div className='flex flex-col gap-2 w-full'>
                            <button
                              onClick={handleDownloadClick}
                              className='text-white shadow-2xl font-bold bg-zinc-700 dark:bg-zinc-800 w-full p-2 rounded-lg'>
                              Download QR
                            </button>
                          </div>
                        </>
                      ) : (
                        <button
                          onClick={handleGenerateClick}
                          disabled={!canGenerate}
                          className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded ${
                            !canGenerate ? "opacity-50 cursor-not-allowed" : ""
                          }`}>
                          Generate
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className='h-[40vh] lg:h-full w-full flex flex-col justify-between items-center bg-zinc-100 dark:bg-zinc-800 col-span-2 lg:col-span-1 rounded-xl p-4 text-zinc-800 dark:text-zinc-300'>
                  <div className='w-full flex'>
                    <h1 className='text-xl text-zinc-800 dark:text-zinc-200 mb-2'>
                      Assistant Chat Traffic
                    </h1>
                  </div>
                  <div className='bg-zinc-700 rounded-lg h-full w-full'>
                    <ChartComponent />
                  </div>
                </div>
              </div>
              <DocsCard />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QRCodeGenerator;
