import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { logout } from "../firebase/firebaseAuth";
import { auth, db } from "../firebase/firebase";
import { onSnapshotsInSync } from "firebase/firestore";
import { doc, onSnapshot } from "firebase/firestore";
import useSubscriptionStore from "../stores/subscriptionStore";
import useSettingsStore from "../stores/settingsStore";

const SideBarNav = () => {
  const { isSubscribed, currentTier } = useSubscriptionStore();
  const checkMissingFields = useSettingsStore(
    (state) => state.checkMissingFields
  );
  const canAccessRestrictedSections = useSettingsStore(
    (state) => state.canAccessRestrictedSections
  );
  const canAccessBrands =
    currentTier === "Premium Plan" || currentTier === "Unlimited Plan" || currentTier === "Launchpad Plan";

  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isOnline, setIsOnline] = useState(false);
  const [userProfile, setUserProfile] = useState({
    name: "",
    email: "",
    avatar: "",
  });

  const {
    name,
    companyName,
    salesPersonName,
    companyDescription,
    companyValues,
    number,
    sumEmail,
    selectedQuestions,
  } = useSettingsStore((state) => ({
    name: state.name,
    companyName: state.companyName,
    salesPersonName: state.salesPersonName,
    companyDescription: state.companyDescription,
    companyValues: state.companyValues,
    number: state.number,
    sumEmail: state.sumEmail,
    selectedQuestions: state.selectedQuestions,
  }));

  useEffect(() => {
    checkMissingFields();
  }, [
    name,
    companyName,
    salesPersonName,
    companyDescription,
    companyValues,
    number,
    sumEmail,
    selectedQuestions,
    checkMissingFields,
  ]);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    let unsubscribeSnapshot = null;
    if (user) {
      const docRef = doc(db, "users", user.uid);

      unsubscribeSnapshot = onSnapshot(
        docRef,
        (doc) => {
          if (doc.exists()) {
            setUserProfile(doc.data());
          } else {
            setUserProfile({
              name: "New User",
              email: "Unknown",
              avatar:
                "https://res.cloudinary.com/djr22sgp3/image/upload/v1686063705/blank-profile-picture-973460_1280_vphxzq.jpg",
            });
          }
        },
        (error) => {
          console.error("Error fetching user document: ", error);
          alert(
            "An error occurred while fetching your profile. Please try again later."
          );
        }
      );
    }

    return () => {
      if (unsubscribeSnapshot) {
        unsubscribeSnapshot();
      }
    };
  }, [user]);

  useEffect(() => {
    const themeToggleDarkIcon = document.getElementById(
      "theme-toggle-dark-icon"
    );
    const themeToggleLightIcon = document.getElementById(
      "theme-toggle-light-icon"
    );

    if (
      localStorage.getItem("color-theme") === "dark" ||
      (!("color-theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      themeToggleLightIcon.classList.remove("hidden");
    } else {
      themeToggleDarkIcon.classList.remove("hidden");
    }

    const themeToggleBtn = document.getElementById("theme-toggle");

    const handleThemeToggle = () => {
      themeToggleDarkIcon.classList.toggle("hidden");
      themeToggleLightIcon.classList.toggle("hidden");

      if (localStorage.getItem("color-theme")) {
        if (localStorage.getItem("color-theme") === "light") {
          document.documentElement.classList.add("dark");
          localStorage.setItem("color-theme", "dark");
        } else {
          document.documentElement.classList.remove("dark");
          localStorage.setItem("color-theme", "light");
        }
      } else {
        if (document.documentElement.classList.contains("dark")) {
          document.documentElement.classList.remove("dark");
          localStorage.setItem("color-theme", "light");
        } else {
          document.documentElement.classList.add("dark");
          localStorage.setItem("color-theme", "dark");
        }
      }
    };

    themeToggleBtn.addEventListener("click", handleThemeToggle);

    return () => {
      themeToggleBtn.removeEventListener("click", handleThemeToggle);
    };
  }, []);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const closeDropdown = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", closeDropdown);

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, []);

  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        // Check if the event target is the sidebar button
        if (event.target.closest('button[data-drawer-target="logo-sidebar"]')) {
          return;
        }
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  useEffect(() => {
    const unsubscribe = onSnapshotsInSync(db, () => {
      setIsOnline(true); // Set online status when all snapshots are in sync
    });

    return () => {
      unsubscribe(); // Unsubscribe when the component is unmounted
    };
  }, []);

  useEffect(() => {
    function handleNetworkChange() {
      setIsOnline(navigator.onLine);
    }

    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);

    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
    };
  }, []);

  return (
    <>
      <nav className='fixed top-0 z-50 w-full bg-stone-100 border-b border-zinc-200 dark:bg-zinc-800 dark:border-zinc-700'>
        <div className='px-3 py-3 lg:px-5 lg:pl-3'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center justify-start'>
              <button
                onClick={toggleSidebar}
                data-drawer-target='logo-sidebar'
                data-drawer-toggle='logo-sidebar'
                aria-controls='logo-sidebar'
                type='button'
                className='inline-flex items-center p-2 text-sm text-zinc-500 rounded-lg sm:hidden hover:bg-zinc-200 focus:outline-none focus:ring-2 focus:ring-zinc-200 dark:text-zinc-400 dark:hover:bg-zinc-700 dark:focus:ring-zinc-600'>
                <span className='sr-only'>Open sidebar</span>
                <svg
                  className='w-6 h-6'
                  aria-hidden='true'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    clipRule='evenodd'
                    fillRule='evenodd'
                    d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'></path>
                </svg>
              </button>
              <Link
                to={"/"}
                className='flex ml-2 gap-2 flex-row justify-center items-center'>
                <div className='gradient-animation rounded-lg shadow-green p-[0.170rem]'>
                  <img
                    src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png'
                    className='w-7 h-7 bg-zinc-700 rounded-md'
                  />
                </div>
                <span className='self-center hidden md:block text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white'>
                  MattressAI
                </span>
              </Link>
            </div>
            <div className='flex items-center'>
              <div className='flex items-center ml-3'>
                <div>
                  <div className='flex flex-row items-center gap-3'>
                    <Link
                      to='/membership'
                      className='hidden lg:flex flex-row gap-4 bg-zinc-300 dark:bg-zinc-700 hover:bg-zinc-400 hover:dark:bg-zinc-900 px-2 py-1 rounded-lg cursor-pointer'
                      title={`Subscribed to ${currentTier}`}>
                      <p className='text-zinc-700 dark:text-white'>
                        {isSubscribed ? `${currentTier}` : "No Plan"}
                      </p>
                    </Link>
                    <div
                      className='flex flex-row gap-2 justify-center items-center bg-zinc-300 dark:bg-zinc-700 px-2 py-1 rounded-lg cursor-help'
                      title='App Connection Status'>
                      <h1 className='dark:text-white'>
                        {isOnline ? "Online" : "Offline"}
                      </h1>
                      <div
                        className={`rounded-full w-3 h-3 animate-pulse ${
                          isOnline ? "bg-green-400" : "bg-red-400"
                        }`}
                      />
                    </div>

                    <div className='border-r border-zinc-800 dark:border-zinc-500 pr-4'>
                      <button
                        id='theme-toggle'
                        type='button'
                        className='text-zinc-700 dark:text-zinc-400 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-xl text-sm p-2'>
                        <svg
                          id='theme-toggle-dark-icon'
                          className='hidden w-5 h-5 md:w-6 md:h-6'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z' />
                        </svg>
                        <svg
                          id='theme-toggle-light-icon'
                          className='hidden w-5 h-5 md:w-6 md:h-6'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z'
                            fillRule='evenodd'
                            clipRule='evenodd'
                          />
                        </svg>
                      </button>
                    </div>
                    <button
                      type='button'
                      className='ml-2 flex text-sm bg-zinc-800 rounded-full focus:ring-4 focus:ring-zinc-300 dark:focus:ring-zinc-600'
                      aria-expanded='false'
                      onClick={toggleDropdown}>
                      <span className='sr-only'>Open user menu</span>
                      <img
                        className='w-8 h-8 rounded-full object-cover'
                        src={
                          userProfile.avatar ||
                          "https://res.cloudinary.com/djr22sgp3/image/upload/v1686063705/blank-profile-picture-973460_1280_vphxzq.jpg"
                        }
                        alt=''
                      />
                    </button>
                  </div>
                </div>
                <div
                  className={`z-50 ${
                    isDropdownOpen ? "" : "hidden"
                  } absolute top-20 right-8 text-base list-none bg-stone-100 divide-y divide-zinc-100 rounded shadow dark:bg-zinc-800 dark:divide-zinc-600`}
                  id='dropdown-user'
                  ref={dropdownRef}>
                  <div className='px-4 py-3' role='none'>
                    <p
                      className='text-sm text-zinc-900 dark:text-white'
                      role='none'>
                      {userProfile.name || "New User"}
                    </p>
                    <p
                      className='text-sm font-medium text-zinc-900 truncate dark:text-zinc-300'
                      role='none'>
                      {user ? user.email : "example@MattressAi.com"}
                    </p>
                  </div>
                  <ul className='py-1' role='none'>
                    <li>
                      <Link
                        to={"/"}
                        className='block px-4 py-2 text-sm text-zinc-700 hover:bg-zinc-200 dark:text-zinc-300 dark:hover:bg-zinc-600 dark:hover:text-white'
                        role='menuitem'>
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/settings"}
                        className='block px-4 py-2 text-sm text-zinc-700 hover:bg-zinc-200 dark:text-zinc-300 dark:hover:bg-zinc-600 dark:hover:text-white'
                        role='menuitem'>
                        Settings
                      </Link>
                    </li>
                    <li>
                      <a
                        href='#'
                        className='block px-4 py-2 text-sm text-zinc-700 hover:bg-zinc-200 dark:text-zinc-300 dark:hover:bg-zinc-600 dark:hover:text-white'
                        role='menuitem'
                        onClick={async (event) => {
                          event.preventDefault();
                          try {
                            await logout();
                          } catch (error) {
                            console.error("Error signing out", error);
                          }
                        }}>
                        Sign out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        ref={sidebarRef}
        id='logo-sidebar'
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } bg-stone-100 border-r border-zinc-200 sm:translate-x-0 dark:bg-zinc-800 dark:border-zinc-700`}
        aria-label='Sidebar'>
        <div className='h-full px-3 pb-4 overflow-y-auto bg-stone-100 dark:bg-zinc-800'>
          <ul className='space-y-2 font-medium'>
            <li>
              <div className='flex flex-row justify-between items-center p-2 text-zinc-400 rounded-lg dark:text-zinc-400'>
                  <span className=''>Merchant Dashboard</span>
              </div>
            </li>
            <li>
              <Link
                to={"/"}
                className='flex items-center p-2 text-zinc-900 rounded-lg dark:text-white hover:bg-zinc-200 dark:hover:bg-zinc-700'>
                <svg
                  aria-hidden='true'
                  className='w-6 h-6 text-zinc-500 transition duration-75 dark:text-zinc-400 group-hover:text-zinc-900 dark:group-hover:text-white'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={2}
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25'></path>
                </svg>
                <span className='ml-3'>Home</span>
              </Link>
            </li>
      
            <li>
              <Link
                to={canAccessRestrictedSections ? "/sessions" : "#"}
                className={`flex items-center p-2 text-zinc-900 rounded-lg dark:text-white hover:bg-zinc-200 dark:hover:bg-zinc-700 ${
                  !canAccessRestrictedSections
                    ? "opacity-50 pointer-events-none"
                    : ""
                }`}>
                <svg
                  aria-hidden='true'
                  className='flex-shrink-0 w-6 h-6 text-zinc-500 transition duration-75 dark:text-zinc-400 group-hover:text-zinc-900 dark:group-hover:text-white'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={2}
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z'></path>
                </svg>
                <span className='flex-1 ml-3 whitespace-nowrap'>Sessions</span>
                {!canAccessRestrictedSections && (
                  <span className='locked-icon inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-zinc-800 bg-zinc-200 rounded-full dark:bg-zinc-700 dark:text-zinc-300'>
                    <svg
                      className='w-6 h-6 p-0.5'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z'></path>
                    </svg>
                  </span>
                )}
              </Link>
            </li>
            <li>
              <Link className='flex flex-row justify-between items-center p-2 text-zinc-900 rounded-lg dark:text-white hover:bg-zinc-200 dark:hover:bg-zinc-700'>
                <div className='flex flex-row'>
                  <svg
                    aria-hidden='true'
                    className='w-6 h-6 text-zinc-500 transition duration-75 dark:text-zinc-400 group-hover:text-zinc-900 dark:group-hover:text-white'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth={2}
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z" />
                  </svg>
                  <span className='ml-3'>Analytics</span>
                </div>
                <span className='inline-flex items-center justify-center px-2 ml-3 text-xs font-medium text-zinc-800 bg-zinc-200 rounded-full dark:bg-zinc-700 dark:text-zinc-300'>
                  Building
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={canAccessRestrictedSections ? "/generate" : "#"}
                className={`flex items-center p-2 text-zinc-900 rounded-lg dark:text-white hover:bg-zinc-200 dark:hover:bg-zinc-700 ${
                  !canAccessRestrictedSections
                    ? "opacity-50 pointer-events-none"
                    : ""
                }`}>
                <svg
                  aria-hidden='true'
                  className='flex-shrink-0 w-6 h-6 text-zinc-500 transition duration-75 dark:text-zinc-400 group-hover:text-zinc-900 dark:group-hover:text-white'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={2}
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z'
                  />
                </svg>
                <span className='flex-1 ml-3 whitespace-nowrap'>Share</span>
                {!canAccessRestrictedSections && (
                  <span className='locked-icon inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-zinc-800 bg-zinc-200 rounded-full dark:bg-zinc-700 dark:text-zinc-300'>
                    <svg
                      className='w-6 h-6 p-0.5'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z'></path>
                    </svg>
                  </span>
                )}
              </Link>
            </li>
            <li>
              <div className='flex flex-row justify-between items-center p-2 text-zinc-400 rounded-lg dark:text-zinc-400'>
                  <span className=''>Assistant Setup</span>
              </div>
            </li>
            <li>
              <Link
                to={"/settings"}
                className='flex items-center p-2 text-zinc-900 rounded-lg dark:text-white hover:bg-zinc-200 dark:hover:bg-zinc-700'>
                <svg
                  aria-hidden='true'
                  className='flex-shrink-0 w-6 h-6 text-zinc-500 transition duration-75 dark:text-zinc-400 group-hover:text-zinc-900 dark:group-hover:text-white'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={2}
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z'></path>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'></path>
                </svg>
                <span className='flex-1 ml-3 whitespace-nowrap'>Settings</span>
              </Link>
            </li>
            <li>
              <Link
                to={canAccessBrands ? "/brands" : "#"}
                className={`flex items-center p-2 text-zinc-900 rounded-lg dark:text-white hover:bg-zinc-200 dark:hover:bg-zinc-700 ${
                  !canAccessBrands ? "opacity-50 pointer-events-none" : ""
                }`}>
                <svg
                  aria-hidden='true'
                  className='flex-shrink-0 w-6 h-6 text-zinc-500 transition duration-75 dark:text-zinc-400 group-hover:text-zinc-900 dark:group-hover:text-white'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={2}
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'></path>
                </svg>
                <span className='flex-1 ml-3 whitespace-nowrap'>Merchant Brands</span>
                {!canAccessBrands && (
                  <span className='locked-icon inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-zinc-800 bg-zinc-200 rounded-full dark:bg-zinc-700 dark:text-zinc-300'>
                    <svg
                      className='w-6 h-6 p-0.5'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z'></path>
                    </svg>
                  </span>
                )}
              </Link>
            </li>
            
            <li>
              <Link className='flex flex-row justify-between items-center p-2 text-zinc-900 rounded-lg dark:text-white hover:bg-zinc-200 dark:hover:bg-zinc-700'>
                <div className='flex flex-row'>
                  <svg
                    aria-hidden='true'
                    className='w-6 h-6 text-zinc-500 transition duration-75 dark:text-zinc-400 group-hover:text-zinc-900 dark:group-hover:text-white'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth={2}
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                  </svg>
                  <span className='ml-3'>Integrations</span>
                </div>
                <span className='inline-flex items-center justify-center px-2 ml-3 text-xs font-medium text-zinc-800 bg-zinc-200 rounded-full dark:bg-zinc-700 dark:text-zinc-300'>
                  Building
                </span>
              </Link>
            </li>
            
            <li>
              <div className='flex flex-row justify-between items-center p-2 text-zinc-400 rounded-lg dark:text-zinc-400'>
                  <span className=''>Merchant Section</span>
              </div>
            </li>
            <li>
              <Link
                to={"/membership"}
                className='flex items-center p-2 text-zinc-900 rounded-lg dark:text-white hover:bg-zinc-200 dark:hover:bg-zinc-700'>
                <svg
                  aria-hidden='true'
                  className='flex-shrink-0 w-6 h-6 text-zinc-500 transition duration-75 dark:text-zinc-400 group-hover:text-zinc-900 dark:group-hover:text-white'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={1.5}
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path d='M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'></path>
                </svg>
                <span className='flex-1 ml-3 whitespace-nowrap'>
                  Membership
                </span>
                <span className='inline-flex items-center justify-center px-2 ml-3 text-xs font-medium text-zinc-800 bg-zinc-200 rounded-full dark:bg-zinc-700 dark:text-zinc-300'>
                  {currentTier === "Standard Plan"
                    ? "Standard"
                    : currentTier === "Premium Plan"
                    ? "Premium"
                    : currentTier === "Launchpad Plan"
                    ? "Launchpad"
                    : currentTier === "Unlimited Plan"
                    ? "Unlimited"
                    : "None"}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={"/feedback"}
                className='flex items-center p-2 text-zinc-900 rounded-lg dark:text-white hover:bg-zinc-200 dark:hover:bg-zinc-700'>
                <svg
                  aria-hidden='true'
                  className='flex-shrink-0 w-6 h-6 text-zinc-500 transition duration-75 dark:text-zinc-400 group-hover:text-zinc-900 dark:group-hover:text-white'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={1.5}
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z'
                  />
                </svg>
                <span className='flex-1 ml-3 whitespace-nowrap'>Feedback</span>
              </Link>
            </li>
            <li>
              <Link className='flex flex-row justify-between items-center p-2 text-zinc-900 rounded-lg dark:text-white hover:bg-zinc-200 dark:hover:bg-zinc-700'>
                <div className='flex flex-row'>
                  <svg
                    aria-hidden='true'
                    className='w-6 h-6 text-zinc-500 transition duration-75 dark:text-zinc-400 group-hover:text-zinc-900 dark:group-hover:text-white'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth={2}
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z'
                    />
                  </svg>
                  <span className='ml-3'>Capital</span>
                </div>
                <span className='inline-flex items-center justify-center px-2 ml-3 text-xs font-medium text-zinc-800 bg-zinc-200 rounded-full dark:bg-zinc-700 dark:text-zinc-300'>
                  Building
                </span>
              </Link>
            </li>
            
            
            <li>
              <Link
                to={"/docs"}
                className='flex items-center p-2 text-zinc-900 rounded-lg dark:text-white hover:bg-zinc-200 dark:hover:bg-zinc-700'>
                <svg
                  aria-hidden='true'
                  className='flex-shrink-0 w-6 h-6 text-zinc-500 transition duration-75 dark:text-zinc-400 group-hover:text-zinc-900 dark:group-hover:text-white'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={2}
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'></path>
                </svg>
                <span className='flex-1 ml-3 whitespace-nowrap'>Tutorials</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default SideBarNav;