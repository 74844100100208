import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import useSubscriptionStore from '../stores/subscriptionStore'
import { Link } from 'react-router-dom'

export default function UpgradeModal() {
  const [open, setOpen] = useState(true)

  const cancelButtonRef = useRef(null)

  const { showUpgradeModal, closeUpgradeModal, currentTier } = useSubscriptionStore();


  return (
    <Transition.Root show={showUpgradeModal} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zinc-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-zinc-100 dark:bg-zinc-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-zinc-300 dark:bg-zinc-600 sm:mx-0 sm:h-10 sm:w-10">
                      <svg className='w-7 h-7 dark:text-white' fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"></path>
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-xl font-semibold leading-6 dark:text-zinc-100">
                        Upgrade Your Plan
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-md dark:text-zinc-500">
                          You are currently on the <span className='dark:text-white font-bold'>{currentTier}</span>, you will have to upgrade to gain access to this feature, any many more !
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-zinc-300 dark:bg-zinc-700 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <Link
                    to="/membership"
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-gradient-to-r from-blue-500 bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                    onClick={closeUpgradeModal}
                  >
                      Upgrade
                  </Link>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-zinc-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-900 sm:mt-0 sm:w-auto"
                    onClick={closeUpgradeModal}
                    ref={cancelButtonRef}
                  >
                      Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
