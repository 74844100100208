import { useMemo, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import SideBarNav from "./components/SideBarNav";
import Home from "./pages/Home";
import Membership from "./pages/Membership";
import Brands from "./pages/Brands";
import Settings from "./pages/Settings";
import Sessions from "./pages/Sessions";
import Login from "./pages/Login";
import QRCodeGenerator from "./pages/GenerateQr";
import HiddenLeadChatLaunch from "./pages/HiddenLeadChatLaunch";
import HiddenSuggestionChatLaunch from "./pages/HiddenSuggestionChatLaunch";
import Docs from "./pages/Docs";
import Feedback from "./pages/Feedback";
import TutorialsGrid from "./pages/TutorialsGrid";
import MeetingsGrid from "./pages/MeetingsGrid";

import LoadingComponent from "./components/LoadingComponent";
import RedirectModal from "./components/RedirectModal";
import MembershipModal from "./components/NoPlanModal";
import { useInitializeApp } from "./middleware/useInitializeApp";
import useSettingsStore from "./stores/settingsStore";

function App() {
  const { isAuthenticated, hasCompletedChecks } = useInitializeApp();
  const stripePublicKey = import.meta.env.VITE_APP_STRIPE_PUBLIC_KEY;
  const stripePromise = useMemo(
    () => loadStripe(stripePublicKey),
    [stripePublicKey]
  );

  if (!hasCompletedChecks) {
    return <LoadingComponent />;
  }

  return (
    <Router>
      <div className='bg-white dark:bg-zinc-700 transition-all ease-in-out duration-300'>
        <Elements stripe={stripePromise}>
          <Routes>
            {/* Define specific routes here */}
            <Route path='/QRchat2/:userId' element={<HiddenLeadChatLaunch />} />
            <Route
              path='/QRchat/:userId'
              element={<HiddenSuggestionChatLaunch />}
            />
            <Route path='/login' element={<Login />} />
            <Route
              path='/*'
              element={
                isAuthenticated ? <RoutesAndSidebarWithLocation /> : <Login />
              }
            />
          </Routes>
        </Elements>
      </div>
    </Router>
  );
}

const RoutesAndSidebarWithLocation = ({
  isAuthenticated,
  hasCompletedChecks,
}) => {
  const location = useLocation();
  const {
    missingFields,
    showRedirectModal,
    setShowRedirectModal,
    isInitialized,
  } = useSettingsStore();

  useEffect(() => {
    const shouldShowModal =
      missingFields &&
      missingFields.length > 0 &&
      !["/membership", "/settings", "/docs"].includes(location.pathname);

    if (shouldShowModal !== showRedirectModal) {
      setShowRedirectModal(shouldShowModal);
    }
  }, [location, showRedirectModal, missingFields, setShowRedirectModal]);

  return (
    <>
      {location.pathname !== "/login" && <SideBarNav />}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/membership' element={<Membership />} />
        <Route path='/sessions' element={<Sessions />} />
        <Route path='/brands' element={<Brands />} />
        <Route path='/generate' element={<QRCodeGenerator />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/docs' element={<Docs />} />
        <Route path='/feedback' element={<Feedback />} />
        <Route path='/guide' element={<TutorialsGrid />} />
        <Route path='/meetings' element={<MeetingsGrid />} />
      </Routes>

      {location.pathname !== "/membership" &&
        location.pathname !== "/docs" &&
        location.pathname !== "/meetings" &&
        location.pathname !== "/guide" &&
        isAuthenticated &&
        hasCompletedChecks && <MembershipModal />}

      {!showRedirectModal &&
        isAuthenticated &&
        hasCompletedChecks &&
        isInitialized &&
        showRedirectModal &&
        location.pathname !== "/settings" && (
          <RedirectModal
            showRedirectModal={showRedirectModal}
            missingFields={missingFields}
          />
        )}
    </>
  );
};

export default App;
