import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { Link } from "react-router-dom";
import { getAuth } from "firebase/auth";

const db = getFirestore();

const MiniSessionList = () => {
  const [sessions, setSessions] = useState([]);

  const fetchSessions = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const sessionsCollection = collection(db, `users/${user.uid}/sessions`);
    const sessionSnapshot = await getDocs(sessionsCollection);

    const sortedSessions = sessionSnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .sort((a, b) => new Date(b.Date) - new Date(a.Date)); // Sorting by Date

    setSessions(sortedSessions);
  };

  const SaleSVG = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Sale Closed'
        className='w-6 h-6'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
      </svg>
    </div>
  );

  const NoSaleSVG = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Sale Missed'
        className='w-6 h-6'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
      </svg>
    </div>
  );

  const WarmLeadSVG = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Cold Lead'
        className='w-6 h-6'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 3.75 18 6m0 0 2.25 2.25M18 6l2.25-2.25M18 6l-2.25 2.25m1.5 13.5c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z"></path>
      </svg>
    </div>
  );

  const VisitBookedSVG = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Visit Booked'
        className='w-6 h-6'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path>
      </svg>
    </div>
  );

  const NotVerifiedLeadSVG = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Not Verified'
        className='w-5 h-5'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path strokeLinecap="round" strokeLinejoin="round" d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"></path>
      </svg>
    </div>
  );

  const VerifiedSVG = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Verified Lead'
        className='w-6 h-6'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z'
        />
      </svg>
    </div>
  );

  const NotVerifiedSVG = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Not Verified'
        className='w-5 h-5'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636'
        />
      </svg>
    </div>
  );

  const LiteAssitantType = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Lite Assistant'
        className='w-6 h-6'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z'
        />
      </svg>
    </div>
  );

  const PlusAssitantType = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Plus Assistant'
        className='w-6 h-6'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z'
        />
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z'
        />
      </svg>
    </div>
  );

  useEffect(() => {
    fetchSessions();
  }, []);

  return (
    <>
      <div className='rounded-2xl w-full px-4 dark:text-white text-zinc-800'>
        <div className='flex justify-between items-center rounded-lg shadow-2xl dark:bg-zinc-800 bg-zinc-100 p-4 mb-4'>
          <h1 className='text-xl dark:text-white text-zinc-800'>Recent Sessions</h1>
          <Link
            to={"/sessions"}
            className='font-semibold bg-gradient-to-r hover:border hover:border-white flex flex-row justify-center items-center gap-2 from-zinc-600 to-zinc-600 dark:from-blue-500 dark:to-green-400 dark:shadow-green py-1 px-3 text-white text-xl rounded-xl hover:scale-105 transition-all ease-in-out duration-200'>
            <h1>Sessions</h1>
            <svg className="w-5 h-5" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
          </svg>
          </Link>
        </div>
        <div className='relative overflow-x-auto shadow-2xl sm:rounded-lg flex justify-center items-center'>
          <table className='w-full mx-auto text-sm text-left text-zinc-500 dark:text-zinc-400'>
            <tbody>
              {sessions.slice(0, 4).map((item) => (
                <tr
                  key={item.id}
                  className='dark:bg-zinc-800 bg-zinc-100 hover:bg-zinc-50 dark:hover:bg-zinc-700 transition-all ease-in-out duration-200'>
                  <td className='px-6 py-3 hidden lg:table-cell'>
                    {item["Customer Name"]}
                  </td>
                  <td className='px-2 py-2 hidden lg:table-cell'>
                    <div className="flex justify-center items-center gap-2 w-full h-full">
                      {/* Conditionally render Assistant Type SVG */}
                      {item["Assistant Type"] === "Lite Assistant" ? (
                        <LiteAssitantType title='Lead Lite' />
                      ) : (
                        <PlusAssitantType title='Lead Plus' />
                      )}
                    </div>
                  </td>
                  <td className='px-2 py-2 hidden lg:table-cell'>
                    <div className="flex justify-center items-center gap-2 w-full h-full">
                      {item["Verified Lead Status"] ? (
                          <VerifiedSVG />
                        ) : (
                          <NotVerifiedSVG />
                        )}
                    </div>
                  </td>
                  <td className='px-2 py-2 hidden lg:table-cell'>
                    <div className="flex justify-center items-center gap-2 w-full h-full">
                      {item["Sale Status"] ? (
                              item["Sale Status"] === "Not Contacted" ? (
                                <WarmLeadSVG />
                              ) : item["Sale Status"] === "Booked Visit" ? (
                                <VisitBookedSVG />
                              ) : item["Sale Status"] === "No Sale" ? (
                                <NoSaleSVG />
                              ) : item["Sale Status"] === "Sale" ? (
                                <SaleSVG />
                              ) : item["Sale Status"] === "Not Verified" ? (
                                <NotVerifiedLeadSVG />
                              ) : <NotVerifiedLeadSVG /> // Default case if "Sale Status" has an unexpected value
                            ) : <NotVerifiedLeadSVG /> // Show this when there's no "Sale Status" value
                            }
                    </div>
                  </td>
                  <td className='px-3 py-3 text-right'>
                    <div className='flex flex-row justify-center items-center gap-8'>
                      <Link to={"/sessions"}>
                        <svg
                          className='text-zinc-700 dark:text-white w-6 h-6'
                          aria-hidden='true'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                        </svg>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MiniSessionList;
