import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, sendEmailVerification, reload } from "firebase/auth";
import { app } from './firebase';
import useSettingsStore from "../stores/settingsStore";
import useSubscriptionStore from "../stores/subscriptionStore";


// Initialize the Firebase Auth
const auth = getAuth(app);

// Function to signup a new user
export const signup = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      // Continue with sending email verification
      sendEmailVerification(userCredential.user)
        .then(() => {
          console.log("Verification email sent.");
        })
        .catch(error => {
          console.error("Error sending verification email:", error);
        });
      return userCredential.user;
    });
};


export const refreshAuthToken = () => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    reload(user)
      .then(() => {
        console.log("User token refreshed.");
      })
      .catch(error => {
        console.error('Error reloading user:', error);
      });
  }
};

// Function to login a user
export const login = (email, password) => {

  useSettingsStore.getState().resetState();
  useSubscriptionStore.getState().reset();

  return signInWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      // Fetch subscription status after a successful login
      useSubscriptionStore.getState().fetchSubscriptionStatus();
      return userCredential.user;
    });
};

// Function to logout a user
export const logout = () => {

  useSettingsStore.getState().resetState();
  useSubscriptionStore.getState().reset();

  return signOut(auth);
};

// Function to check the user's authentication status
export const checkAuth = (callback) => {
  return onAuthStateChanged(auth, user => {
    if (user) {
      if (user.emailVerified) {
        useSubscriptionStore.getState().fetchSubscriptionStatus();
        callback(true, user);
      } else {
        callback(false);
        console.log("Email not verified");
      }
    } else {
      // Reset the Zustand states here
      useSettingsStore.getState().resetState();
      useSubscriptionStore.getState().reset();
      callback(false);
    }
  });
};
