import CurrentTierCard from "../components/CurrentTierCard";
import PricingContainer from "../components/PricingContainer";

const Membership = () => {
  return (
    <div className='2xl:px-52 2xl:pt-4 p-4 sm:ml-64'>
      <div className='min-h-screen mt-20 p-6'>
        <h1 className='text-4xl font-bold mb-6 dark:text-white'>Membership</h1>
        <div className="flex flex-col justify-center items-center gap-8">
          <div className='flex justify-center items-center relative w-full'>
            <CurrentTierCard />
          </div>
          <div className='flex justify-center items-center relative w-full'>
            <PricingContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Membership;
