const CarouselSlide1 = () => {
  return (
    <div className='bg-zinc-100 dark:bg-zinc-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
      <div className='flex justify-center items-center mb-8'>
        <div className='gradient-animation rounded-xl dark:shadow-green shadow-2xl'>
          <img
            src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png'
            className='w-12 h-12 bg-zinc-700 rounded-xl m-1 shadow-2xl'
          />
        </div>
      </div>
      <div className='flex flex-row items-center gap-2 lg:gap-6 w-full'>
        <div className='lg:w-1/2 h-full'>
          <div
            className='hidden lg:block relative bg-zinc-300 dark:bg-zinc-700 rounded-xl overflow-hidden'
            style={{ paddingBottom: "100%" }}>
            <img
              src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1707824737/Screenshot_2024-02-13_at_11.42.47_xon2o2.webp'
              className='absolute top-0 left-[5%] scale-90 shadow-2xl w-full h-full object-cover object-left rounded-l-lg'
            />
          </div>
        </div>
        <div className='lg:w-1/2 mt-3 sm:ml-4 sm:mt-0 text-left'>
          <div className='flex flex-col'>
            <div className='hidden lg:flex justify-start items-center mb-8'>
              <div className='bg-zinc-200 dark:bg-zinc-700 rounded-full'>
                <svg
                  className='w-12 h-12 p-2 dark:text-white'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5'
                  />
                </svg>
              </div>
            </div>

            <h1 className='text-3xl font-semibold leading-6 dark:text-zinc-100'>
              Welcome to MattressAI 
            </h1>
            <div className='mt-2'>
              <p className='text-sm lg:text-xl dark:text-zinc-500 lg:mb-4'>
                The MattressAI Assistants are in 
                <span className='dark:text-white font-bold'>
                {" "}
                 continuous developement.
                </span> {" "}
                 While advanced, they might exhibit occasional inconsistencies.
              </p>
            </div>
            <div>
              <p className='text-sm lg:text-xl dark:text-zinc-500'>
                We value your
                <span className='dark:text-white font-bold'>
                  {" "}
                  feedback and patience
                </span>{" "}
                as we change the mattress industry together.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselSlide1;
