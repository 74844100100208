/* eslint-disable react/no-unescaped-entities */
import { Link } from 'react-router-dom';

const Docs = () => {
  return (
    <div className='p-4 sm:ml-64 min-h-screen text-zinc-700 dark:text-white h-full'>
      <div className='lg:mx-6 h-full'>
        <div className='flex justify-start items-start h-full'>
          <h1 className='text-4xl font-bold mt-20 py-6 text-zinc-700 dark:text-white'>
            MattressAI Help and Accelerator Section 🚀
          </h1>
        </div>
        <div className='flex flex-col w-full h-full gap-4'>
          <div className='h-full flex flex-col lg:flex-row gap-4 bg-zinc-100 dark:bg-zinc-800 p-4 rounded-xl'>
            <Link to={'/meetings'} className='w-full hover:scale-105 hover:cursor-pointer transition-all ease-in-out duration-300 bg-white dark:bg-zinc-700 text-white lg:m-4 p-4 rounded-xl h-full shadow-2xl overflow-hidden'>
              <div className='w-full relative flex flex-col gap-4 justify-evenly items-start h-[30vh] lg:h-[60vh]'>
                <div className='bg-zinc-200 dark:bg-zinc-800/60 p-4 rounded-xl h-full'>
                  <img
                    className='shadow-2xl object-cover object-center rounded-lg h-full'
                    src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1698313269/book_a_session_page_ipdrdx.webp'
                  />
                </div>
                <div className='py-6 px-1'>
                  <h2 className='text-3xl font-semibold mb-3 text-zinc-700 dark:text-white'>
                    Book Live Sessions 📅
                    </h2>
                  <p className='text-sm text-zinc-600 dark:text-zinc-400'>
                    Schedule Live Sessions with MattressAI's Experts, who can
                    assist with Setup, Strategy and Troubleshooting.
                  </p>
                </div>
              </div>
            </Link>
            <Link to={'/guide'} className='w-full hover:scale-105 hover:cursor-pointer transition-all ease-in-out duration-300 bg-white dark:bg-zinc-700 text-white lg:m-4 p-4 rounded-xl h-full shadow-2xl overflow-hidden'>
              <div className='w-full relative flex flex-col gap-4 justify-evenly items-start h-[30vh] lg:h-[60vh]'>
                <div className='bg-zinc-200 dark:bg-zinc-800/60 p-4 rounded-xl h-full'>
                  <img
                    className='shadow-2xl object-cover object-center rounded-lg h-full'
                    src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1698317246/tuts_faws_page_tck0xd.png'
                  />
                </div>
                <div className='py-6 px-1'>
                  <h2 className='text-3xl font-semibold mb-3 text-zinc-700 dark:text-white'>Tutorials and FAQs 👨🏼‍💻</h2>
                  <p className='text-sm text-zinc-600 dark:text-zinc-400'>
                    Quickly learn how to use MattressAI's features with our easy to 
                    follow video tutorials from our Founders and Lead Developers.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Docs;
