const ToastError = ({ message }) => {
  return (
    <div className="absolute top-20 right-8">
      <div
        id='toast-success'
        className='flex items-center w-full max-w-md p-4 mb-4 text-zinc-500 bg-white rounded-lg shadow dark:text-zinc-400 dark:bg-zinc-900'
        role='alert'>
        <div className='inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200'>
          <svg
            className='w-5 h-5'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            stroke='currentColor'
            strokeWidth='2'
            fill="none"
            viewBox='0 0 24 24'>
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"></path>
          </svg>
          <span className='sr-only'>Check icon</span>
        </div>
        <div className='ml-3 text-md font-normal'>{message}</div>
      </div>
    </div>
  );
};

export default ToastError;
