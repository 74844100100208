import { useState, useEffect } from "react";
import { auth } from "../firebase/firebase";
import useSettingsStore from "../stores/settingsStore";
import useSubscriptionStore from "../stores/subscriptionStore";
import updateDatabase from "../utils/updateDatabase";
import { checkAuth } from "../firebase/firebaseAuth";

export const useInitializeApp = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasCompletedChecks, setHasCompletedChecks] = useState(false);
  const { checkMissingFields } = useSettingsStore();
  const { fetchSubscriptionStatus } = useSubscriptionStore();

  useEffect(() => {
    let authUnsubscribe;

    const initialize = async () => {
      authUnsubscribe = await checkAuth(setIsAuthenticated);

      if (isAuthenticated) {
        const userId = auth.currentUser ? auth.currentUser.uid : null;
        if (userId) {
          useSettingsStore.getState().setupRealtimeListeners(userId);

          checkMissingFields();

          const { missingFields } = useSettingsStore.getState();
          if (missingFields.length === 0) {
            await updateDatabase();
          }

          await fetchSubscriptionStatus();
        }
      }
      setHasCompletedChecks(true);
    };

    initialize();

    return () => {
      if (authUnsubscribe) {
        authUnsubscribe();
      }
    };
  }, [isAuthenticated]);

  return { isAuthenticated, hasCompletedChecks };
};
