/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { deleteDoc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const db = getFirestore();

const SessionList = () => {
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterVerifiedLeads, setFilterVerifiedLeads] = useState(false);
  const [startDate, setStartDate] = useState(null);
  
  const [endDate, setEndDate] = useState(null);

  const isDateInRange = (date, start, end) => {
    const sessionDate = new Date(date);

    const sessionYear = sessionDate.getUTCFullYear();
    const sessionMonth = sessionDate.getUTCMonth();
    const sessionDay = sessionDate.getUTCDate();

    if (start) {
      const startYear = start.getUTCFullYear();
      const startMonth = start.getUTCMonth();
      const startDay = start.getUTCDate();

      if (
        sessionYear < startYear ||
        (sessionYear === startYear && sessionMonth < startMonth) ||
        (sessionYear === startYear &&
          sessionMonth === startMonth &&
          sessionDay < startDay)
      ) {
        return false;
      }
    }

    if (end) {
      const endYear = end.getUTCFullYear();
      const endMonth = end.getUTCMonth();
      const endDay = end.getUTCDate();

      if (
        sessionYear > endYear ||
        (sessionYear === endYear && sessionMonth > endMonth) ||
        (sessionYear === endYear &&
          sessionMonth === endMonth &&
          sessionDay > endDay)
      ) {
        return false;
      }
    }

    return true;
  };

  const fetchSessions = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const sessionsCollection = collection(db, `users/${user.uid}/sessions`);
  
    const sessionSnapshot = await getDocs(sessionsCollection);
  
    const sortedSessions = sessionSnapshot.docs
      .map((doc) => ({
        id: doc.id,
        saleStatus: doc.data()["Sale Status"], // Extracting "Sale Status" and assigning it to saleStatus
        ...doc.data(),
      }))
      .sort((a, b) => {
        const aDateTime = new Date(`${a.Date} ${a.Time.replace(/-/g, ":")}`);
        const bDateTime = new Date(`${b.Date} ${b.Time.replace(/-/g, ":")}`);
        return bDateTime - aDateTime;
      });
  
    const filteredByDate = sortedSessions.filter((session) =>
      isDateInRange(session.Date, startDate, endDate)
    );
  
    setSessions(filteredByDate);
  };

  const updateSaleStatus = async (sessionId, newStatus) => {
    console.log(`Updating session ${sessionId} with new status: ${newStatus}`); // Log the function call
  
    const auth = getAuth();
    const user = auth.currentUser;
    console.log(`Current user ID: ${user.uid}`); // Verify we have the correct user ID
  
    const sessionDocRef = doc(db, `users/${user.uid}/sessions`, sessionId);
    
    try {
      await updateDoc(sessionDocRef, {
        "Sale Status": newStatus
      });
      console.log("Sale Status updated successfully in the database");
      fetchSessions(); // Re-fetch sessions to update the UI
    } catch (error) {
      console.error("Error updating Sale Status: ", error);
    }
  };

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    console.log(`New status selected: ${newStatus}`); // Log the new status
  
    // Log the selected session before update
    console.log('Selected session before update:', selectedSession);
  
    const updatedSession = { ...selectedSession, ["Sale Status"]: newStatus };
    
    // Update the local state if you're maintaining selectedSession in the state
    setSelectedSession(updatedSession);
  
    console.log('Selected session after update:', updatedSession); // Log the updated session object
  
    // Update the database
    updateSaleStatus(selectedSession['Session ID'], newStatus);
  };

  useEffect(() => {
    fetchSessions();
  }, [startDate, endDate]);

  const fetchSessionSummary = async (sessionId) => {
    const auth = getAuth();
    const user = auth.currentUser;
    const sessionDocRef = doc(db, `users/${user.uid}/sessions`, sessionId);
    const sessionDocSnapshot = await getDoc(sessionDocRef);
    setSelectedSession(sessionDocSnapshot.data());
    setIsModalOpen(true);
  };

  const handleDelete = async (sessionId) => {
    const auth = getAuth();
    const user = auth.currentUser;
    const sessionDocRef = doc(db, `users/${user.uid}/sessions`, sessionId);
    await deleteDoc(sessionDocRef);
    fetchSessions();
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSession(null);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilterVerifiedLeads(e.target.checked);
  };

  const filteredSessions = sessions.filter((session) => {
    // Check in customer name
    const isInCustomerName = session["Customer Name"] &&
      session["Customer Name"].toLowerCase().includes(searchTerm.toLowerCase());
  
    // Check in summary
    const isInSummary = Object.values(session.Summary || {}).some(value =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    // Check for verified lead status if filter is active
    // Adjusting the check to account for both boolean true and string "true"
    const isVerifiedLead = !filterVerifiedLeads || 
      (filterVerifiedLeads && (session["Verified Lead Status"] === true || session["Verified Lead Status"] === "true"));
  
    return (isInCustomerName || isInSummary) && isVerifiedLead;
  });

  const groupedSessions = filteredSessions.reduce((acc, session) => {
    (acc[session.Date] = acc[session.Date] || []).push(session);
    return acc;
  }, {});

  const handleStartDateChange = (e) => {
    setStartDate(new Date(e.target.value));
  };

  const handleEndDateChange = (e) => {
    setEndDate(new Date(e.target.value));
  };

  const resetDateFilters = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleDateFilter = () => {};

  const SaleSVG = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Sale Closed'
        className='w-6 h-6'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
      </svg>
      <div className='absolute -top-6 -left-7  bg-zinc-500 text-white text-xs max-w-44 rounded py-1 px-2 opacity-0 group-hover:opacity-100'>
        Sale Closed
      </div>
    </div>
  );

  const NoSaleSVG = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Sale Missed'
        className='w-6 h-6'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
      </svg>
      <div className='absolute -top-6 -left-7  bg-zinc-500 text-white text-xs max-w-44 rounded py-1 px-2 opacity-0 group-hover:opacity-100'>
        Sale Missed
      </div>
    </div>
  );

  const WarmLeadSVG = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Cold Lead'
        className='w-6 h-6'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 3.75 18 6m0 0 2.25 2.25M18 6l2.25-2.25M18 6l-2.25 2.25m1.5 13.5c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z" />
      </svg>
      <div className='absolute -top-6 -left-7  bg-zinc-500 text-white text-xs max-w-44 rounded py-1 px-2 opacity-0 group-hover:opacity-100'>
        Not Contacted
      </div>
    </div>
  );

  const VisitBookedSVG = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Visit Booked'
        className='w-6 h-6'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path>
      </svg>
      <div className='absolute -top-6 -left-7  bg-zinc-500 text-white text-xs max-w-44 rounded py-1 px-2 opacity-0 group-hover:opacity-100'>
      Visit Booked
      </div>
    </div>
  );

  const NotVerifiedLeadSVG = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Not Verified'
        className='w-5 h-5'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path strokeLinecap="round" strokeLinejoin="round" d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"></path>
      </svg>
      <div className='absolute -top-6 -left-9  bg-zinc-500 text-white text-xs max-w-44 rounded py-1 px-2 opacity-0 group-hover:opacity-100'>
        Un-verified Lead
      </div>
    </div>
  );

  const VerifiedSVG = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Verified Lead'
        className='w-6 h-6'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z'
        />
      </svg>
      <div className='absolute -top-6 -left-7  bg-zinc-500 text-white text-xs max-w-44 rounded py-1 px-2 opacity-0 group-hover:opacity-100'>
        Verified Lead
      </div>
    </div>
  );

  const NotVerifiedSVG = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Not Verified'
        className='w-5 h-5'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636'
        />
      </svg>
      <div className='absolute -top-6 -left-9  bg-zinc-500 text-white text-xs max-w-44 rounded py-1 px-2 opacity-0 group-hover:opacity-100'>
        Un-verified Lead
      </div>
    </div>
  );

  const LiteAssitantType = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Lite Assistant'
        className='w-6 h-6'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z'
        />
      </svg>
      <div className='absolute -top-6 -left-4  bg-zinc-500 text-white text-xs max-w-44 rounded py-1 px-2 opacity-0 group-hover:opacity-100'>
        Lead Lite
      </div>
    </div>
  );

  const PlusAssitantType = () => (
    <div className='relative group rounded p-1 cursor-pointer'>
      <svg
        title='Plus Assistant'
        className='w-6 h-6'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z'
        />
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z'
        />
      </svg>
      <div className='absolute -top-6 -left-4  bg-zinc-500 text-white text-xs max-w-44 rounded py-1 px-2 opacity-0 group-hover:opacity-100'>
        Lead Plus
      </div>
    </div>
  );

  return (
    <>
      <div className='bg-zinc-800 dark:bg-zinc-900 p-4 rounded-2xl overflow-hidden'>
        <div className='flex flex-col xl:flex-row bg-zinc-300 dark:bg-zinc-700 p-4 rounded-lg gap-4 mb-4'>
          <div className='w-full xl:w-2/3'>
            <label className='text-zinc-900 dark:text-white'>
              Search Sessions
              <input
                type='text'
                placeholder='Session Summary Search...'
                value={searchTerm}
                onChange={handleSearchChange}
                className='p-2 mt-2 w-full border-none rounded-md border dark:bg-zinc-800 shadow-2xl dark:text-white bg-zinc-100'
              />
            </label>
          </div>
          <div className="w-full xl:w-1/6 lg:w-full">
            <div className="flex flex-col w-full">
              <label className="text-zinc-900 dark:text-white">
                Filter Verified
              </label>
              <div className="flex justify-end items-center p-2 mt-2 w-full rounded-md dark:bg-zinc-800 shadow-2xl bg-zinc-100">
                <label className='relative inline-flex items-center cursor-pointer'>
                  <input
                    type='checkbox'
                    checked={filterVerifiedLeads}
                    onChange={handleFilterChange}
                    className='sr-only peer'
                  />
                  <div className = "w-11 h-6 bg-zinc-900 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-zinc-300 rounded-full peer dark:bg-zinc-600 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-zinc-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-zinc-600 peer-checked:bg-zinc-900 dark:peer-checked:bg-gradient-to-br dark:peer-checked:from-blue-500 dark:peer-checked:to-green-400 dark:peer-checked:shadow-green"></div>
                </label>
              </div>
            </div>
          </div>
          <div className='w-full xl:w-1/3'>
            <div className='flex flex-col w-full'>
              <div className='flex justify-between items-center'>
                <label className='text-zinc-900 dark:text-white'>
                  Filter Date <span className='text-xs'>( From - To )</span>
                </label>
                <div className='flex flex-row gap-2'>
                  <button
                    onClick={handleDateFilter}
                    title='Filter Date Results'
                    className='text-zinc-500 hover:text-zinc-100 dark:text-white hover:dark:text-zinc-500 rounded-md bg-zinc-800 py-0.5 px-1'>
                    <svg
                      className='w-5 h-5 p-0.5'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z'></path>
                    </svg>
                  </button>
                  <button
                    onClick={resetDateFilters}
                    title='Reset Date Results'
                    className='text-zinc-500 hover:text-zinc-100 dark:text-white hover:dark:text-zinc-500 rounded-md bg-zinc-800 py-0.5 px-1'>
                    <svg
                      className='w-5 h-5 p-0.5'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div className='w-full mt-2'>
                <div className='flex flex-row gap-4'>
                  <input
                    type='date'
                    onChange={handleStartDateChange}
                    className='p-2 w-full border-none rounded-md border dark:bg-zinc-800 shadow-2xl dark:text-zinc-500 bg-zinc-100'
                    placeholder='Start Date'
                  />
                  <input
                    type='date'
                    onChange={handleEndDateChange}
                    className='p-2 w-full border-none rounded-md border dark:bg-zinc-800 shadow-2xl dark:text-zinc-500 bg-zinc-100'
                    placeholder='End Date'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='relative overflow-x-auto shadow-md rounded-lg overflow-hidden'>
          <table className='mx-auto w-full text-sm text-left text-zinc-500 dark:text-white overflow-hidden'>
            <thead className='text-xs text-zinc-700 uppercase bg-zinc-300 dark:bg-zinc-600 dark:text-zinc-300 overflow-hidden'>
              <tr>
                <th scope='col' className='px-4 py-3'>
                  Customer Name
                </th>
                <th scope='col' className='px-4 py-3 hidden lg:table-cell '>
                  Contact Number
                </th>
                <th scope='col' className=' px-4 py-3 hidden lg:table-cell flex justify-center text-center'>
                  Assistant
                </th>
                <th scope='col' className='px-4 py-3 hidden lg:table-cell flex justify-center text-center'>
                  Verified
                </th>
                <th scope='col' className='px-4 py-3 hidden lg:table-cell '>
                  Time
                </th>
                <th scope='col' className='px-4 py-3 hidden lg:table-cell flex justify-center text-center'>
                  Status
                </th>
                <th scope='col' className='px-4 py-3'>
                  <span className='sr-only'>Edit</span>
                </th>
              </tr>
            </thead>
            {filteredSessions.length === 0 && searchTerm ? (
              <tbody>
                <tr>
                  <td
                    colSpan='7'
                    className='px-6 py-2 text-center text-zinc-900 bg-zinc-100 dark:bg-zinc-800 dark:text-white'>
                    Oops. We can't find that.
                  </td>
                </tr>
              </tbody>
            ) : (
              Object.entries(groupedSessions).map(([date, dateSessions]) => (
                <tbody key={date}>
                  <tr>
                    <td
                      colSpan='7'
                      className='px-4 py-2 font-bold text-zinc-900 bg-zinc-200 dark:bg-zinc-700 dark:text-white'>
                      {date}
                    </td>
                  </tr>
                  {dateSessions.map((item) => (
                    <tr
                      key={item.id}
                      className='bg-zinc-100 hover:cursor-pointer border-b dark:bg-zinc-800 dark:border-zinc-700 hover:bg-zinc-50 dark:hover:bg-zinc-700/70 transition-all ease-in-out duration-200'>
                      <th
                        scope='row'
                        onClick={() => fetchSessionSummary(item.id)}
                        className='px-4 py-4 font-medium text-zinc-900 whitespace-nowrap dark:text-white'>
                        {item["Customer Name"]}
                      </th>
                      <td className='px-4 py-4 hidden lg:table-cell' onClick={() => fetchSessionSummary(item.id)}>
                        {item.Summary && item.Summary["Customer Contact"] ? item.Summary["Customer Contact"] : "N/A"}
                      </td>
                      <td className='px-4 py-4 hidden lg:table-cell' onClick={() => { fetchSessionSummary(item.id); }}>
                        <div className="flex justify-center items-center w-full h-full">
                          {item["Assistant Type"] === "Lite Assistant" ? (
                            <LiteAssitantType title='Lead Lite' />
                          ) : (
                            <PlusAssitantType title='Lead Plus' />
                          )}
                        </div>
                      </td>
                      <td className='px-4 py-4 hidden lg:table-cell' onClick={() => { fetchSessionSummary(item.id); }}>
                        <div className="flex justify-center items-center w-full h-full">
                          {item["Verified Lead Status"] ? (
                            <VerifiedSVG />
                          ) : (
                            <NotVerifiedSVG />
                          )}
                        </div>
                      </td>
                      <td
                        className='px-4 py-4 hidden lg:table-cell'
                        onClick={() => fetchSessionSummary(item.id)}>
                        {item.Time.replace(/-/g, ":")}
                      </td>
                      <td className='px-4 py-4 hidden lg:table-cell' onClick={() => { fetchSessionSummary(item.id); }}>
                        <div className="flex justify-center items-center w-full h-full">
                          {item["Sale Status"] ? (
                            item["Sale Status"] === "Booked Visit" ? (
                              <VisitBookedSVG />
                            ) : item["Sale Status"] === "No Sale" ? (
                              <NoSaleSVG />
                            ) : item["Sale Status"] === "Sale" ? (
                              <SaleSVG />
                            ) : item["Sale Status"] === "Not Verified" ? (
                              <NotVerifiedLeadSVG />
                            ) : item["Sale Status"] === "Not Contacted" ? (
                              <WarmLeadSVG />
                            ) : <NotVerifiedLeadSVG /> // Fallback for any other "Sale Status" values not explicitly handled
                          ) : item["Verified Lead Status"] === true || item["Verified Lead Status"] === "true" ? (
                            <WarmLeadSVG /> // Lead is verified but no "Sale Status"
                          ) : (
                            <NotVerifiedLeadSVG /> // Lead is not verified and no "Sale Status"
                          )}
                        </div>
                      </td>
                      <td className='px-6 py-4 text-right'>
                        <div className='flex flex-row justify-center items-center gap-8'>
                          <button onClick={() => fetchSessionSummary(item.id)}>
                            <svg
                              className='text-zinc-700 dark:text-white hover:dark:text-green-500 hover:text-zinc-900 w-6 h-6'
                              aria-hidden='true'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              viewBox='0 0 24 24'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path
                                d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                                strokeLinecap='round'
                                strokeLinejoin='round'></path>
                              <path
                                d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                                strokeLinecap='round'
                                strokeLinejoin='round'></path>
                            </svg>
                          </button>
                          <button onClick={() => handleDelete(item.id)}>
                            <svg
                              className='text-zinc-700 dark:text-white hover:dark:text-red-500 hover:text-zinc-900 w-6 h-6 z-50'
                              aria-hidden='true'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              viewBox='0 0 24 24'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path
                                d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                strokeLinecap='round'
                                strokeLinejoin='round'></path>
                            </svg>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ))
            )}
          </table>
        </div>
      </div>
      {isModalOpen && selectedSession && (
        <div
          id='staticModal'
          data-modal-backdrop='static'
          tabIndex='-1'
          aria-hidden='true'
          className='fixed top-0 left-0 bottom-0 right-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='relative w-full h-[88vh] md:w-[50%] lg:w-[40%] xl:w-[30%] mx-4 bg-zinc-100 rounded-lg shadow dark:bg-zinc-700 overflow-hidden'>
            <div className='flex items-center justify-between p-4 border-b rounded-t dark:border-zinc-600'>
              <h3 className='text-3xl font-semibold text-zinc-900 dark:text-white p-2'>
                Session Summary
              </h3>
              <div className='p-4'>
                <div className='flex justify-end items-end'>
                  <button
                    type='button'
                    className='text-zinc-500 bg-white hover:bg-green-400 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-xl border border-zinc-200 text-sm font-medium px-5 py-2.5 hover:text-zinc-900 focus:z-10 dark:bg-zinc-700 dark:text-zinc-300 dark:border-zinc-500 dark:hover:text-white dark:hover:bg-green-400 dark:focus:ring-white'
                    onClick={closeModal}>
                    <svg
                      className='w-6 h-6'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M6 18L18 6M6 6l12 12'></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className='flex flex-row gap-4 h-[75vh] p-6 text-lg leading-relaxed dark:text-white overflow-y-auto'>
              <div className='flex flex-col gap-4'>
                <div className='bg-white text-zinc-800 w-full dark:bg-zinc-800 dark:text-white shadow-2xl text-md p-4 rounded-xl col-span-full'>
                  <div className='flex flex-col w-full text-left'>
                    <h1 className='py-2 text-xl font-semibold'>
                      Lead Status
                    </h1>
                    {selectedSession && (
                      <select
                      className='flex bg-zinc-200 dark:bg-zinc-800 hover:bg-zinc-300 hover:dark:bg-zinc-900 hover:cursor-pointer py-1.5 px-3 rounded-xl border'
                      value={selectedSession["Sale Status"] || "Not Contacted"}
                      onChange={handleStatusChange}>
                      <option value="Not Contacted">Not Contacted</option>
                      <option value="Booked Visit">Appointment Booked</option>
                      <option value="No Sale">No Sale</option>
                      <option value="Sale">Successful Sale</option>
                      <option value="Not Verified">Contact Not Verified</option>
                    </select>
                    )}
                  </div>
                </div>
                <div className='bg-white text-zinc-800 dark:bg-zinc-800 dark:text-white shadow-2xl text-md p-4 rounded-xl col-span-full'>
                  <div className='flex flex-col text-left'>
                    <h1 className='py-2 text-xl font-semibold'>
                      Verbal Summary
                    </h1>
                    <p>{selectedSession["Verbal Summary"]}</p>
                  </div>
                </div>
                {selectedSession.Summary &&
                  (function () {
                    const prioritizedKeys = [
                      "Customer Name",
                      "Budget",
                      "Customer Contact",
                    ];

                    const isValidValue = (value) => {
                      return (
                        value !== undefined &&
                        value !== "N/A" &&
                        value !== null &&
                        value !== 0
                      );
                    };

                    const displayValue = (value) => {
                      if (typeof value === "boolean")
                        return value ? "Yes" : "No";
                      return value;
                    };

                    const otherKeys = Object.keys(
                      selectedSession.Summary
                    ).filter(
                      (key) =>
                        !prioritizedKeys.includes(key) &&
                        isValidValue(selectedSession.Summary[key])
                    );

                    const allKeys = [
                      ...prioritizedKeys.filter((key) =>
                        isValidValue(selectedSession.Summary[key])
                      ),
                      ...otherKeys,
                    ];

                    return allKeys.map((key, index) => (
                      <div
                        key={index}
                        className='flex flex-col gap-4 p-2 rounded-xl shadow-2xl bg-white text-zinc-800 dark:bg-zinc-800 dark:text-white'>
                        <div className='w-full h-full flex justify-start items-start text-left'>
                          <strong>{key}</strong>
                        </div>
                        <div className='w-full h-full flex justify-end items-end text-right'>
                          <div className='bg-zinc-100 dark:bg-zinc-800 rounded-md py-1 px-3 m-0.5'>
                            {displayValue(selectedSession.Summary[key])}
                          </div>
                        </div>
                      </div>
                    ));
                  })()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SessionList;
