import { useState } from "react";
import useSettingsStore from "../stores/settingsStore";
import ToastSuccess from "../components/ToastSuccess";

const Feedback = () => {
  const [showToast, setShowToast] = useState(false);
  const { name, sumEmail, number } = useSettingsStore((state) => ({
    name: state.name,
    sumEmail: state.sumEmail,
    number: state.number,
  }));

  const [feedback, setFeedback] = useState("");
  const [suggestions, setSuggestions] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const overallExperience = document.querySelector(
      'input[name="overallExperience"]:checked'
    ).value;
    const chatQuality = document.querySelector(
      'input[name="chatQuality"]:checked'
    ).value;
    const chatConsistency = document.querySelector(
      'input[name="chatConsistency"]:checked'
    ).value;
    const chatSpeed = document.querySelector(
      'input[name="chatSpeed"]:checked'
    ).value;

    const formData = {
      name,
      email: sumEmail,
      phoneNumber: number,
      overallExperience,
      chatQuality,
      chatConsistency,
      chatSpeed,
      feedback,
      suggestions,
    };

    try {
      // Replace with your Zapier Webhook URL
      const ZAPIER_WEBHOOK_URL =
        "https://hooks.zapier.com/hooks/catch/15819377/35pxet4/";

      await fetch(ZAPIER_WEBHOOK_URL, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      console.log("Feedback Submitted!");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
    } catch (error) {
      console.error(
        "There was an error submitting the feedback:",
        error.message,
        error.stack
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='2xl:px-52 2xl:pt-4 p-4 sm:ml-64 h-full'>
      <div className='min-h-screen mt-20 p-6 h-full relative'>
        <div className='fixed top-[10%] right-8 w-full'>
          {showToast && (
            <ToastSuccess
              message='Feedback submitted successfully!'
              className='mt-4 mr-4'
            />
          )}
        </div>
        <div className='flex flex-row justify-between items-center'>
          <h1 className='text-4xl font-bold h-full flex justify-start items-start text-zinc-800 dark:text-white mb-4'>
            Feedback
          </h1>
          <div className='bg-zinc-100 dark:bg-gradient-to-r dark:from-blue-500 dark:to-green-400 dark:shadow-green p-2 rounded-xl flex flex-row gap-4 items-center mb-4'>
            <svg
              className='w-5 h-5 text-zinc-800 dark:text-white'
              fill='none'
              stroke='currentColor'
              strokeWidth={1.5}
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0'
              />
            </svg>
            <h1 className='hidden text-sm font-bold h-full lg:flex justify-start items-start text-zinc-800 dark:text-white'>
              Submit Feedback for a chance to win a 3-Months Unlimited Plan for Free!
            </h1>
          </div>
        </div>
        <div>
          <div className='bg-zinc-800 text-white my-4 p-4 rounded-xl flex flex-row items-center gap-3 h-full'>
            <svg
              fill='none'
              stroke='currentColor'
              className='w-5 h-5'
              strokeWidth='1.5'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'></path>
            </svg>
            <p>
              If you have any feedback or suggestions for MattressAI. Please let
              us know by filling out the form below.
            </p>
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              <div className='flex flex-col gap-4 bg-zinc-200 dark:bg-zinc-800 p-4 rounded-xl'>
                {/* Overall Experience */}
                <div className='flex flex-col gap-2'>
                  <div className='grid grid-cols-1 lg:grid-cols-2 w-full gap-4'>
                    <div className='bg-white dark:bg-zinc-700 p-4 rounded-xl flex flex-col gap-4'>
                      <label className='text-zinc-800 dark:text-white font-semibold'>
                        Overall Experience
                      </label>
                      <div className='flex flex-row items-center gap-4 text-zinc-800 dark:text-white'>
                        {[1, 2, 3, 4, 5].map((num) => (
                          <div key={num} className='flex items-center'>
                            <input
                              type='radio'
                              id={`rating-${num}`}
                              name='overallExperience'
                              value={num}
                              className='mr-2'
                            />
                            <label htmlFor={`rating-${num}`}>{num}</label>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className='bg-white dark:bg-zinc-700 p-4 rounded-xl flex flex-col gap-4'>
                      <label className='text-zinc-800 dark:text-white font-semibold'>
                        Chat Quality
                      </label>
                      <div className='flex flex-row items-center gap-4 text-zinc-800 dark:text-white'>
                        {[1, 2, 3, 4, 5].map((num) => (
                          <div key={num} className='flex items-center'>
                            <input
                              type='radio'
                              id={`chatQuality-${num}`}
                              name='chatQuality'
                              value={num}
                              className='mr-2'
                            />
                            <label htmlFor={`rating-${num}`}>{num}</label>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className='bg-white dark:bg-zinc-700 p-4 rounded-xl flex flex-col gap-4'>
                      <label className='text-zinc-800 dark:text-white font-semibold'>
                        Chat Consistency
                      </label>
                      <div className='flex flex-row items-center gap-4 text-zinc-800 dark:text-white'>
                        {[1, 2, 3, 4, 5].map((num) => (
                          <div key={num} className='flex items-center'>
                            <input
                              type='radio'
                              id={`chatConsistency-${num}`}
                              name='chatConsistency'
                              value={num}
                              className='mr-2'
                            />
                            <label htmlFor={`rating-${num}`}>{num}</label>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className='bg-white dark:bg-zinc-700 p-4 rounded-xl flex flex-col gap-4'>
                      <label className='text-zinc-800 dark:text-white font-semibold'>
                        Chat Speed
                      </label>
                      <div className='flex flex-row items-center gap-4 text-zinc-800 dark:text-white'>
                        {[1, 2, 3, 4, 5].map((num) => (
                          <div key={num} className='flex items-center'>
                            <input
                              type='radio'
                              id={`chatSpeed-${num}`}
                              name='chatSpeed'
                              value={num}
                              className='mr-2'
                            />
                            <label htmlFor={`rating-${num}`}>{num}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='flex flex-col gap-2'>
                  <label className='text-zinc-800 dark:text-white font-semibold'>
                    Feedback
                  </label>
                  <textarea
                    rows='5'
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    className='bg-white dark:bg-zinc-700 text-zinc-800 dark:text-white rounded-xl p-4 border-none resize-none'
                  />
                </div>

                <div className='flex flex-col gap-2'>
                  <label className='text-zinc-800 dark:text-white font-semibold'>
                    Suggestions for Improvement
                  </label>
                  <textarea
                    rows='5'
                    value={suggestions}
                    onChange={(e) => setSuggestions(e.target.value)}
                    className='bg-white dark:bg-zinc-700 text-zinc-800 dark:text-white rounded-xl p-4 border-none resize-none'
                  />
                </div>

                <div className='flex justify-end'>
                  <button
                    type='submit'
                    disabled={loading}
                    className={`bg-white text-zinc-800 dark:bg-gradient-to-r dark:from-blue-500 dark:to-green-400 dark:shadow-green p-2 rounded-xl dark:text-white w-44 ${
                      loading ? "opacity-50" : ""
                    }`}>
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
