import { useState, useEffect } from "react";
import { auth } from "../firebase/firebase";
import axios from "axios";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import ToastError from "../components/ToastError";
import ToastSuccess from "../components/ToastSuccess";
import useSubscriptionStore from "../stores/subscriptionStore";
import { Link } from "react-router-dom";
import { deleteDoc } from 'firebase/firestore';

const Brands = () => {
  const userId = auth.currentUser.uid;
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successToastMessage, setSuccessToastMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { currentTier } = useSubscriptionStore();

  const filteredBrands = brands.filter(brand => brand.toLowerCase().includes(searchTerm.toLowerCase()));

  const db = getFirestore();

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const brandDoc = await getDoc(doc(db, `brands`, `brand_list`));
        if (brandDoc.exists()) {
          setBrands(brandDoc.data().unique_brands);
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
  }, [db]);

  const handleBrandSelection = (brandName) => {
    const currentSelectedCount =
      Object.values(selectedBrands).filter(Boolean).length;
    const isCurrentlySelected = selectedBrands[brandName];

    const maxBrands = (currentTier === "Standard Plan" || currentTier === "No Plan") ? 2 : 4;

    if (!isCurrentlySelected && currentSelectedCount >= maxBrands) {
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
      return;
    }

    setSelectedBrands((prevState) => ({
      ...prevState,
      [brandName]: !prevState[brandName],
    }));
  };


  useEffect(() => {
    const fetchUserSelectedBrands = async () => {
      try {
        const brandsConfigRef = doc(
          db,
          `/users/${userId}/brands_config/brands`
        );
        const userBrandsDoc = await getDoc(brandsConfigRef);
        if (userBrandsDoc.exists()) {
          const userBrands = userBrandsDoc.data().brands;
          let brandState = {};
          userBrands.forEach((brand) => {
            brandState[brand] = true;
          });
          setSelectedBrands(brandState);
        }
      } catch (error) {
        console.error("Error fetching user selected brands:", error);
      }
    };

    fetchUserSelectedBrands();
  }, [userId, db]);

  const saveSelectedBrands = async () => {
    setIsLoading(true);

    // First, delete/clear the existing brands_config document
    const brandsConfigRef = doc(db, `/users/${userId}/brands_config/brands`);
    try {
      await deleteDoc(brandsConfigRef);
      console.log('Deleted existing brands configuration');
    } catch (error) {
      console.error('Error deleting brands configuration:', error);
      setIsLoading(false);
      return;
    }

    try {
      // Convert the selectedBrands object to an array format.
      const selectedBrandsArray = Object.keys(selectedBrands).filter(
        (brand) => selectedBrands[brand]
      );

      // Save the array to Firestore
      const brandsConfigRef = doc(db, `/users/${userId}/brands_config/brands`);
      await setDoc(
        brandsConfigRef,
        { brands: selectedBrandsArray },
        { merge: true }
      );

      // Get the server URL from the environment variable
      const serverUrl = import.meta.env.VITE_APP_SERVER_URL;

      // Make a request to the Flask endpoint
      const response = await axios.get(`${serverUrl}/create_brands`, {
        params: {
          userID: userId,
        },
      });

      console.log(response.data); // Log the response from Flask

      setSuccessToastMessage("Brands have successfully synced!");
      setShowSuccessToast(true);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // Set loading state back to false regardless of the outcome
    }
  };

  useEffect(() => {
    if (showSuccessToast) {
      const timeout = setTimeout(() => {
        setShowSuccessToast(false);
      }, 5000); // hides after 5 seconds

      return () => clearTimeout(timeout);
    }
  }, [showSuccessToast]);

  return (
    <>
      <div className='2xl:px-52 2xl:pt-4 p-4 sm:ml-64'>
        <div className='relative min-h-screen mt-20 p-6'>
          {showToast && (
            <ToastError message={currentTier === "Standard Plan" || currentTier === "No Plan" ? 'You can only select up to 2 brands with your plan.' : 'You can only select up to 4 brands.'} />
          )}
          {showSuccessToast && <ToastSuccess message={successToastMessage} />}
          <div className='flex flex-row justify-between items-center'>
            <h1 className='text-2xl lg:text-4xl font-bold flex justify-start items-start text-zinc-800 dark:text-white '>
              Merchant Brands
            </h1>
            <div className="flex flex-row gap-4">
              {(currentTier === "Standard Plan" || currentTier === "No Plan") ? (
                <Link to='/membership' title='Premium Feature' className="flex flex-row gap-4 cursor-pointer items-center premium-indicator bg-gradient-to-r from-blue-500 to-green-400 dark:shadow-green py-0.5 px-2 rounded-xl">
                  <svg className='w-5 h-5 text-white' fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"></path>
                  </svg>
                  <h1 className="text-white hidden lg:inline-block">Upgrade your Plan to Select up to 4 Brands.</h1>
                </Link>
              ) : (
                <div className="flex flex-row gap-4 items-center py-0.5 px-2 rounded-xl">
                  <h1 className="text-black dark:text-white">You can select up to 4 Brands.</h1>
                </div>
              )}
              <button
                onClick={saveSelectedBrands}
                disabled={isLoading}
                className={`flex flex-row items-center gap-2 bg-zinc-700 hover:bg-zinc-800 dark:bg-zinc-800 dark:hover:bg-zinc-900 text-white font-bold py-2 px-4 rounded-xl ${
                  isLoading ? "opacity-60 cursor-not-allowed" : ""
                }`}>
                {isLoading ? (
                  "Loading..."
                ) : (
                  <>
                    <svg
                      className='w-6 h-6'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5'></path>
                    </svg>
                    <h1>Sync <span className="hidden lg:inline-block">Brands</span></h1>
                  </>
                )}
              </button>
            </div>
          </div>
          <div className='bg-zinc-800 text-white my-4 p-4 rounded-xl flex flex-row items-center gap-3'>
            <svg
              fill='none'
              stroke='currentColor'
              className='w-5 h-5'
              strokeWidth='1.5'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'></path>
            </svg>
            <p>
              Select which Brands your MattressAI Leads Plus Assistant will use during its conversations with customers.
            </p>
          </div>
          <div className='bg-zinc-200 dark:bg-zinc-800  h-full p-3 rounded-xl shadow-xl flex flex-col gap-3 w-full'>
            <div className='my-2 w-full'>
              <input
                type='text'
                placeholder='Search for a brand...'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className='p-2 w-full border-none rounded-lg border dark:bg-zinc-700 shadow-2xl dark:text-white'
              />
            </div>
            {filteredBrands.length > 0 ? (
              filteredBrands
                .sort((a, b) => a.localeCompare(b))
                .reduce((acc, brand, index, arr) => {
                  const prevBrand = arr[index - 1];
                  const isFirstOfGroup = !prevBrand || brand[0].toUpperCase() !== prevBrand[0].toUpperCase();

                  if (isFirstOfGroup) {
                    acc.push(
                      <div key={`sep-${brand[0]}`} className='my-1 bg-zinc-300 dark:bg-zinc-700 rounded-lg p-1 pl-2 text-zinc-800 dark:text-white font-bold'>
                        {brand[0].toUpperCase()}
                      </div>
                    );
                  }

                  acc.push(
                    <div
                      key={index}
                      className='bg-zinc-100 dark:bg-zinc-600 dark:text-white rounded-lg p-4 flex flex-row justify-between items-center'>
                      <h1>{brand}</h1>
                      <label className='relative inline-flex items-center cursor-pointer'>
                        <input
                          type='checkbox'
                          value={brand}
                          checked={selectedBrands[brand] || false}
                          onChange={() => handleBrandSelection(brand)}
                          className='sr-only peer'
                        />
                        <div className="w-11 h-6 bg-zinc-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-zinc-300 rounded-full peer dark:bg-zinc-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-zinc-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-zinc-600 peer-checked:bg-zinc-800 dark:peer-checked:bg-gradient-to-br dark:peer-checked:from-blue-500 dark:peer-checked:to-green-400 dark:peer-checked:shadow-green"></div>
                      </label>
                    </div>
                  );

                  return acc;
                }, [])
            ) : (
              searchTerm && <div className='bg-zinc-100 dark:bg-zinc-600 dark:text-white rounded-lg p-4 flex flex-row justify-between items-center'>
                <div className="flex flex-row gap-4 items-center">
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"></path>
                  </svg>
                  <h1>Brand not found, Please check your search query.</h1>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;
