import { useState, useEffect } from "react";
import PricingCard from "./PricingCards";
import cloudFunctionsConfig from "../utils/cloudFunctionsConfig";
import LaunchpadModal from "./LaunchpadModal";
import useSubscriptionStore from "../stores/subscriptionStore";

const PricingContainer = ({ fetchUserSubscription }) => {
  const [prices, setPrices] = useState([]);
  const [billingFrequency, setBillingFrequency] = useState("monthly");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { currentTier } = useSubscriptionStore();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    // Fetch Stripe products and prices
    const fetchProducts = async () => {
      try {
        const response = await fetch(cloudFunctionsConfig.getStripeProducts);
        const fetchedPrices = await response.json();

        if (!Array.isArray(fetchedPrices)) {
          console.error("Unexpected response format from the API");
          return;
        }

        const transformedPrices = fetchedPrices.flatMap((product) =>
          product.prices.map((price) => {
            return {
              id: price.id,
              name: product.name,
              price: price.unit_amount,
              metadata: product.metadata,
              recurring: price.recurring,
            };
          })
        );

        setPrices(transformedPrices);
      } catch (error) {
        console.error("Failed to fetch Stripe products", error);
      }
    };

    fetchProducts();
  }, []);

  const filteredPrices = prices.filter((price) => {
    if (!price.recurring) return false;
    if (billingFrequency === "monthly")
      return price.recurring.interval === "month";
    else return price.recurring.interval === "year";
  });

  return (
    <>
      <div className='flex flex-col justify-center items-center gap-6 w-full bg-zinc-100 dark:bg-zinc-800 p-8 rounded-xl shadow-2xl'>
        <div className='flex bg-zinc-200 dark:bg-zinc-700 p-1.5 rounded-xl shadow-2xl'>
          <div
            className={`cursor-pointer p-2.5 px-5 rounded-lg ${
              billingFrequency === "monthly"
                ? "bg-zinc-800 dark:bg-gradient-to-r dark:from-blue-500 dark:to-green-400 text-white font-bold dark:shadow-green"
                : "font-semibold text-zinc-800 dark:text-white hover:text-zinc-700"
            }`}
            onClick={() => setBillingFrequency("monthly")}>
            Monthly Billing
          </div>
          <div
            className={`cursor-pointer p-2.5 px-5 rounded-lg ${
              billingFrequency === "yearly"
                ? "bg-zinc-800 dark:bg-gradient-to-r dark:from-blue-500 dark:to-green-400 text-white font-bold dark:shadow-green"
                : "font-semibold text-zinc-800 dark:text-white hover:text-zinc-700"
            }`}
            onClick={() => setBillingFrequency("yearly")}>
            Yearly Billing
          </div>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-3 gap-6'>
          {filteredPrices
            .sort((a, b) => a.price - b.price)
            .map((price) => (
              <PricingCard
                key={price.id}
                price={price}
                fetchUserSubscription={fetchUserSubscription}
              />
            ))}
        </div>
        {/* <div className='bg-white dark:bg-zinc-700 p-6 rounded-xl shadow-lg w-full mb-4'>
          <div className='flex flex-col justify-between gap-12'>
            <div className='flex flex-row justify-between items-center'>
              <div className='flex flex-row gap-4 items-center'>
                <svg
                  className='w-10 h-10 dark:text-white'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'>
                  <path
                    d='M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                <h1 className='text-2xl dark:text-white'>Launchpad Plan</h1>
              </div>
              <div>
                <h1 className='text-xl dark:text-white'>
                  Eligibility on demand
                </h1>
              </div>
            </div>
            <div className='flex flex-row justify-between'>
              <div className='flex flex-col gap-4 dark:text-white'>
                <div className='flex flex-row items-center gap-4'>
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='1.5'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
                  </svg>
                  <h1>For strategic MattressAI partners.</h1>
                </div>
                <div className='flex flex-row items-center gap-4'>
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='1.5'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
                  </svg>
                  <h1>Flexibility for Early Adopters.</h1>
                </div>
                <div className='flex flex-row items-center gap-4'>
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='1.5'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
                  </svg>
                  <h1>No Contract. No Fees. No strings attached.</h1>
                </div>
              </div>
              <div className='flex justify-end items-end'>
                <button
                  onClick={handleOpenModal}
                  disabled={currentTier === "Launchpad Plan"}
                  className={`p-2.5 px-5 rounded-xl ${
                    currentTier === "Launchpad Plan"
                      ? "dark:bg-gradient-to-r opacity-70 dark:from-blue-500 dark:to-green-400 shadow-none cursor-not-allowed"
                      : "bg-zinc-800 dark:bg-gradient-to-r cursor-pointer dark:from-blue-500 dark:to-green-400"
                  } text-white font-bold dark:shadow-green`}>
                  {currentTier === "Launchpad Plan" ? "Active" : "Enter Key"}
                </button>
                {isModalOpen && (
                  <LaunchpadModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default PricingContainer;
