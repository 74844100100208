const CarouselSlide3 = () => {
  return (
    <div className='bg-zinc-100 dark:bg-zinc-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
      <div className='flex justify-center items-center mb-8'>
        <div className='gradient-animation rounded-xl dark:shadow-green shadow-2xl'>
          <img
            src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720271826/fff_fufol2.png'
            className='w-12 h-12 bg-zinc-700 rounded-xl m-1 shadow-2xl'
          />
        </div>
      </div>
      <div className='flex flex-row items-center gap-2 lg:gap-0 w-full'>
        <div className='w-1/2 h-full'>
          <img src='https://res.cloudinary.com/dkbn21xdu/image/upload/v1720299283/sharing_features_p7yuxi.png' />
        </div>
        <div className='w-1/2 mt-3 sm:ml-4 sm:mt-0 text-left'>
          <div className='flex flex-col'>
            <div className='hidden lg:flex justify-start items-center mb-8'>
              <div className='bg-zinc-200 dark:bg-zinc-700 rounded-full'>
                <svg
                  className='w-12 h-12 p-2 dark:text-white'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                </svg>
              </div>
            </div>

            <h1 className='text-3xl font-semibold leading-6 dark:text-zinc-100'>
              Use our 15 Day Free Trial
            </h1>
            <div className='mt-2'>
              <p className='text-sm lg:text-xl dark:text-zinc-500 lg:mb-4'>
                To celebrate our early users we are offering a
                <span className='dark:text-white font-bold'>
                {" "}
                15 Day Free Trial
                </span>{" "}
                 to MattressAI. This give you access to all features and both MattressAI Assistants. Just click the{" "}
                <span className='dark:text-white font-bold'>Free Trial</span>{" "}
                button in the Membership page to get started.
              </p>
            </div>
            <div>
              <p className='text-sm lg:text-xl dark:text-zinc-500'>
                Thank you for your support, any feedback is appreciated.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselSlide3;
