const ToastSuccess = ({ message, className }) => {
  return (
    <div className={`absolute top-0 right-0 z-50 ${className}`}>
      <div
        id='toast-success'
        className='flex items-center w-full max-w-md p-4 mb-4 text-zinc-500 bg-white rounded-lg shadow dark:text-zinc-400 dark:bg-zinc-800'
        role='alert'>
        <div className='inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200'>
          <svg
            className='w-5 h-5'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 20 20'>
            <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
          </svg>
          <span className='sr-only'>Check icon</span>
        </div>
        <div className='ml-3 text-md font-normal'>{message}</div>
      </div>
    </div>
  );
};

export default ToastSuccess;
